/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Chart as ChartJS, BarElement, Tooltip } from "chart.js";
import { Chart } from "react-chartjs-2";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import st_colors from "../../Data/st_colors.json";

/*CONST*/
ChartJS.register(BarElement, Tooltip);

class RANGKING_CHART extends Component {
  state = {};

  render() {
    const hbu_alternative_array = this.props.hbu_alternative_array || [];

    // Map colors using the modulo operation
    const backgroundColors = hbu_alternative_array.map(
      (_, index) => st_colors[index % st_colors.length]
    );

    const hoverBackgroundColors = backgroundColors.map(
      (color) => color.replace("1)", "0.8)") // Adjust for transparency if the color is rgba, otherwise leave as-is.
    );

    const chart_data = {
      labels: hbu_alternative_array.map((item) => item.name),
      datasets: [
        {
          label: "Result Scores",
          data: hbu_alternative_array.map((item) => item.result_score),
          backgroundColor: backgroundColors,
          hoverBackgroundColor: hoverBackgroundColors,
        },
      ],
    };

    const chart_options = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              return `${tooltipItem.label}: ${tooltipItem.raw}`;
            },
          },
        },
      },
      scales: {
        x: {
          ticks: {
            display: false, // Turn off x-axis labels
          },
        },
      },
    };

    // Generate legend content
    const legend_content = (
      <ul style={{ listStyleType: "none", padding: 0 }}>
        {chart_data.labels.map((label, index) => (
          <li
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "8px",
            }}
          >
            <span
              style={{
                display: "inline-block",
                width: "16px",
                height: "16px",
                backgroundColor: backgroundColors[index],
                marginRight: "8px",
                borderRadius: "50%",
              }}
            ></span>
            <p className="text_small">{label}</p>
          </li>
        ))}
      </ul>
    );

    return (
      <main>
        <section>
          <Chart type="bar" data={chart_data} options={chart_options} />
        </section>
        <section>
          <h4 className="margin_bottom">Legend</h4>
          {legend_content}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(RANGKING_CHART);
