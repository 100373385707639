import React, { Component } from "react";
import DeleteIconFill from "../common_icon/DeleteIconFill";
import EditIcon from "../common_icon/EditIcon";

export default class Header extends Component {
  state = {
    hover: false,
  };

  toggleHover = (value) => {
    this.setState({ hover: value });
  };

  render() {
    return (
      <section
        title={this.props.column.name}
        className="header_table center_perfect"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "500px",
        }}
        onMouseEnter={() => {
          this.toggleHover(true);
        }}
        onMouseLeave={() => {
          this.toggleHover(false);
        }}
      >
        {this.state.hover && (
          <div
            className="center_perfect"
            style={{
              width: 50,
              // backgroundColor: "#808080",
              paddingTop: 3,
              paddingBottom: 3,
              paddingLeft: 5,
              paddingRight: 5,
              borderRadius: 10,
            }}
          >
            <div className="flex gap_5">
              <div className="center_perfect">
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    this.props.toggleDeleteField(this.props.params);
                  }}
                  id="icon_delete"
                  style={{
                    fill: "#cb4747",
                    border: "1px solid #ffffff",
                    borderRadius: "50%",
                    background: "#ffffff",
                    height: "22px",
                    width: "22px",
                  }}
                  className="center_perfect"
                >
                  <DeleteIconFill width={20} height={20} />
                </button>
              </div>
              <div className="center_perfect">
                <button
                  onClick={(e) => {
                    e.stopPropagation()
                    this.props.toggleEditField(this.props.params);
                  }}
                  className="center_perfect"
                >
                  <EditIcon width={22} height={22} />
                </button>
              </div>
            </div>
          </div>
        )}

        <table className="">
          <tbody>
            <tr>
              <td>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    marginLeft: 5,
                    height: "20px",
                  }}
                >
                  <strong className="truncate">{this.props.column.name}</strong>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    marginLeft: 5,
                    height: "20px",
                  }}
                >
                  <p className="truncate">
                    {this.props.column.type.replaceAll("_", " ")}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {/* <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            marginLeft: 5,
            height: "20px",
          }}
        >
          <strong className="truncate">{this.props.column.name}</strong>
        </div> */}
      </section>
    );
  }
}
