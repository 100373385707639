//Library modul
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//Personal Component
import Register from "../../Components/auth/Register";
import NavigationLanding from "../../Components/layout/NavigationLanding";
import LandingAuth from "../../Components/layout/LandingAuth";

//Redux function
import { openModal, setLanguage } from "../../App/actions/authActions";

//General Function
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";

class LandingRegister extends Component {
  state = {
    width: window.innerWidth,
    loginStatus: true,
    specialist_code: "",
    registration_step: {
      step: 1,
      page: 1
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.setState({ specialist_code: this?.props?.match?.params?.code });
    const isJoin = this?.props?.location?.pathname?.includes("join");
    this.setState({ loginStatus: !isJoin });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }
  onSetLanguage = (language) => {
    this.props.setLanguage(language);
  };
  onOpenModal = (name) => {
    this.props.openModal(name);
  };
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth }, () => { });
  };
  toggleLogin = () => {
    this.setState({ loginStatus: !this.state.loginStatus });
  };

  handle_mode = (value) => {
    this.setState({
      mode: value
    })
  }

  set_registration_step = ({ step, page }) => {
    const old_step = this.state.registration_step?.step;
    const old_page = this.state.registration_step?.page;
    this.setState({
      registration_step: {
        step: step === undefined || step === null ? old_step : step,
        page: page === undefined || page === null ? old_page : page
      }
    })
  }

  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const domain = this.props.auth.domain;
    // const { mode_balai } = this.props.auth;
    const { width, registration_step } = this.state;
    const { step } = registration_step;
    const horizontal_line_width = width > 800 ? "w_30" : "w_5"

    // multi_steps_progress
    const registration_step_progress = <main className="flex gap_10 align_center">
      <div
        onClick={() => this.set_registration_step({ step: 1, page: 1 })}
        className={`step_progress ${step === 1 ? "active" : ""}`}
      >
        1
      </div>
      <hr className={`horizontal_line ${horizontal_line_width}`} />
      <div
        onClick={() => this.set_registration_step({ step: 2, page: 1 })}
        className={`step_progress ${step === 2 ? "active" : ""}`}
      >
        2
      </div>
      <hr className={`horizontal_line ${horizontal_line_width}`} />
      <div
        onClick={() => this.set_registration_step({ step: 3, page: 1 })}
        className={`step_progress ${step === 3 || step === 4 ? "active" : ""}`}
      >3</div>
    </main>

    let title = "";
    let desc = "";
    if (step === 1) {
      title = dict["Create a New Organization"][language];
      desc = dict["Create an organization to bring teams together and easily manage projects in one place."][language]
    } else if (step === 2) {
      title = dict["Personalizing Your Organization"][language];
      desc = dict["We will tailor our demos and examples so they're relevant for your industry and use case."][language];
    } else if (step === 3) {
      title = dict["Tell Us About Yourself"][language];
      desc = dict["Complete your profile before creating the organization."][language];
    }

    const itemContent = <div className={`box_content`}>
      <h1> {title}</h1>
      <p>{desc}</p>
      <Register set_registration_step={this.set_registration_step} registration_step={registration_step} kode={this?.props?.match?.params?.kode} handle_mode={this.handle_mode} />
      <div className="box_content_bottom">
        <p className="button_account">
          {dict["Already have an account?"][language]}
        </p>
        <Link to="/login" className="button_register text_black bold">
          {dict["Login instead"][language]}
        </Link>
      </div>
    </div>

    return (
      <>
        <LandingAuth
          nav={<NavigationLanding />}
          title={domain_list?.[domain]?.title}
          subtitle={domain_list?.[domain]?.sub_title}
          content={itemContent}
          registration_step_progress={registration_step_progress}
          from="register"
        />
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { openModal, setLanguage })(
  LandingRegister
);
