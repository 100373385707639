/*LIBRARY*/
/* eslint-disable-next-line no-unused-vars */
import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import { connect } from "react-redux";
import maplibregl from "maplibre-gl";

/*COMPONENT*/

/*REDUX*/
import {
  set_value_sini,
  fly_init_update,
  get_ai_parent_object,
} from "../../App/actions/sini_v2";

/*PICTURE*/
import ICON_MARKER_LOADING from "../../Assets/jsx/ICON_MARKER_LOADING";

/*FUNCTION*/

/*DATA*/

/*CONST*/
const marker_id = "sini_marker_loading";

class M_S_LOADING extends Component {
  componentDidUpdate(prevProps) {
    const coordinates_loading_after = this.props.sini.coordinates_loading;
    const coordinates_loading_before = prevProps.sini.coordinates_loading;
    if (coordinates_loading_after !== coordinates_loading_before) {
      this.on_render_layer();
    }
  }

  on_render_layer = () => {
    const { coordinates_loading } = this.props.sini;
    const { map_object } = this.props.layer;

    if (!map_object) return;

    let marker_element = document.getElementById(marker_id);
    if (coordinates_loading === null) {
      if (marker_element) marker_element.remove();
      return;
    }
    if (!marker_element) {
      marker_element = document.createElement("div");
    }
    marker_element.id = marker_id;
    marker_element.style.position = "absolute";
    marker_element.style.transform = "translate(-50%, -100%)";
    const marker_jsx = (
      <div className="map_marker_loading map_marker_center">
        <ICON_MARKER_LOADING />
      </div>
    );
    const marker_html = ReactDOMServer.renderToString(marker_jsx);
    marker_element.innerHTML = marker_html;
    const lngLat = new maplibregl.LngLat(
      coordinates_loading[0],
      coordinates_loading[1]
    );
    const point = map_object.project(lngLat);
    marker_element.style.left = `${point.x}px`;
    marker_element.style.top = `${point.y}px`;
    map_object.getContainer().appendChild(marker_element);
    map_object.on("move", () => {
      const newPoint = map_object.project(lngLat);
      marker_element.style.left = `${newPoint.x}px`;
      marker_element.style.top = `${newPoint.y}px`;
    });
  };

  render() {
    return <main></main>;
  }
}

const mapStateToProps = (state) => ({
  sini: state.sini,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_sini,
  fly_init_update,
  get_ai_parent_object,
})(M_S_LOADING);
