/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import RIVER_SCHEMA from "../trivial_components/RIVER_SCHEMA";

/*REDUX*/
import {
  set_value_toolbox,
  status_toolbox,
} from "../../App/actions/toolboxActions";
import { set_value_layer } from "../../App/actions/layerActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE*/
import ICON_ARROW_LEFT_LINE from "../../Assets/jsx/ICON_ARROW_LEFT_LINE";

/*FUNCTION*/

/*DATA*/

/*CONST*/

class TOOLBOX_RIVER_SCHEMA extends Component {
  state = {};

  on_back = () => {
    this.props.set_value_toolbox({
      key: "mode_toolbox",
      value: "features_distance",
    });
    this.props.set_value_layer({
      key: "draw_state_platform",
      value: "",
    });
    this.props.set_value_toolbox({
      key: "mode_draw",
      value: "simple_select",
    });
    this.props.status_action();
    const { map_object } = this.props.layer;
    if (map_object) {
      map_object.getCanvas().style.cursor = "";
      map_object._controls[1].changeMode("simple_select");
    }
  };

  render() {
    //local storage

    //local state

    //global props

    //content
    return (
      <main
        className="parent_section_full"
        style={{ height: "calc( 100vh - 110px )" }}
      >
        <section className="section_1 margin_bottom">
          <button
            className="button_pill background_grey_light margin_bottom"
            onClick={this.on_back}
          >
            <span className="badge_circle background_black margin_right">
              <ICON_ARROW_LEFT_LINE fill="#fff" />
            </span>
            <span className="margin_right">Kembali</span>
          </button>
          <h1 className="text_header">Skema Sungai</h1>
        </section>
        <section className="section_2">
          <RIVER_SCHEMA />
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  toolbox: state.toolbox,
  map: state.map,
  layer: state.layer,
});

export default connect(mapStateToProps, {
  set_value_toolbox,
  set_value_layer,
  status_action,
  status_toolbox,
})(TOOLBOX_RIVER_SCHEMA);
