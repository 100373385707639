/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";
import maplibregl from "maplibre-gl";
import centroid from "@turf/centroid";
import along from "@turf/along";
import calculate_length from "@turf/length";

/*COMPONENT*/

/*REDUX*/
import { set_value_layer } from "../../App/actions/layerActions";
import {
  set_value_genangan,
  genangan_update,
} from "../../App/actions/genangan_actions";
import { set_value_properties } from "../../App/actions/propertiesActions";

/*PICTURE*/

/*FUNCTION*/
import generate_color_map from "../../App/validation/generate_color_map";
import { generatePopupContentDOM } from "../libre_popup/popup_geo";

/*DATA*/

/*CONST*/
const convert_multi_point_to_points = (geojson) => {
  const features = geojson.features.flatMap((feature) => {
    if (feature.geometry.type === "MultiPoint") {
      return feature.geometry.coordinates.map((coord) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: coord,
        },
        properties: feature.properties,
      }));
    }
    return feature;
  });
  return {
    type: "FeatureCollection",
    features,
  };
};

class LAYER_GEO extends Component {
  state = {
    popup_handlers: {}, //store the references to the event handlers
  };

  componentDidUpdate(prevProps) {
    //memantau perubahan untuk rerender layer
    const status_action_after = this.props.map.status_action;
    const status_action_before = prevProps.map.status_action;

    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    const { map_object } = this.props.layer;

    if (
      status_action_after !== status_action_before ||
      basemap_used_after !== basemap_used_before
    ) {
      if (map_object && map_object.isStyleLoaded()) {
        this.on_render();
      } else if (map_object) {
        map_object.once("styledata", this.on_render);
      }
    }

    //memantau perubahan untuk aksi fly layer
    const fly_action_after = this.props.map.fly_action;
    const fly_action_before = prevProps.map.fly_action;
    if (fly_action_after !== fly_action_before) {
      this.on_fly();
    }
  }

  componentWillUnmount() {
    this.cleanup_event_listerners();
  }

  on_fly = () => {
    const { map_object, geo_layer_list, layer_id, is_pause_zoom_map } =
      this.props.layer;
    if (!is_pause_zoom_map) {
      const geo_layer = geo_layer_list.find(
        (item) => item?.geo_layer?._id === layer_id
      )?.geo_layer;
      const geojson_filtered = geo_layer?.geojson_filtered;
      const features = geojson_filtered?.features || [];

      if (features.length > 0 && !!map_object) {
        const { sidebar_right_status, sidebar_left_status } =
          this.props.properties;

        let top = 50;
        let bottom = 400;
        let left = 10;
        let right = 10;

        if (this.props.properties.is_mobile) {
          //MOBILE
          bottom = 400;
          left = 10;
          right = 10;
        } else {
          //DESKTOP
          bottom = 200;
          left = 420;
          right = 500;
          if (sidebar_right_status === true) {
            right = 500;
          } else {
            right = 50;
          }
          if (sidebar_left_status === true) {
            left = 500;
          } else {
            left = 50;
          }
        }
        const padding = { top, bottom, left, right };
        const [min_longitude, min_latitude, max_longitude, max_latitude] =
          bbox(geojson_filtered);
        map_object.fitBounds(
          [
            [min_longitude, min_latitude],
            [max_longitude, max_latitude],
          ],
          {
            padding,
            // maxZoom: this.props.layer.max_zoom,
            maxZoom: 18.5,
            duration: 750,
          }
        );
      }
    }
  };

  on_render = () => {
    const {
      map_object,
      geo_layer_list,
      layer_apply_color,
      layer_id,
      layer_id_active,
      geometryStatus,
    } = this.props.layer;
    const { is_preview_on, paint_object_edited } = this.props.properties;

    geo_layer_list.forEach((layer) => {
      const _id = layer?.geo_layer?._id;
      const geo_layer = layer?.geo_layer || {};
      const { type_2, is_use_symbol } = geo_layer;
      const features_filtered =
        layer?.geo_layer?.geojson_filtered?.features || [];
      const type = layer?.geo_layer?.type;
      const default_style_key = layer?.geo_layer?.default_style_key;
      const style_array = layer?.geo_layer?.style_array || [];
      let properties_raw = layer?.geo_layer?.properties;
      const is_cluster = layer?.geo_layer?.is_cluster;
      const fields = layer?.geo_layer?.fields || [];

      let shape_type;
      let color_key;
      let paint_object = {};
      let paint_object_line_polygon = {};

      let visibility = layer_id_active.includes(_id) ? "visible" : "none";
      if (is_use_symbol && (type === "Point" || type === "IoT")) {
        visibility = "none";
      }

      if (geometryStatus && layer_id === _id) visibility = "none"; //hide layer ketika sedang edit geometry,
      //karena belum ketemu caranya untuk tempatkan layer draw di atas layer biasa
      const apply_color_id = layer_apply_color.find(
        (item) => item.layer_id === layer.geo_layer?._id
      );
      const geojson = {
        type: "FeatureCollection",
        features: features_filtered,
      };
      let geojson_point;
      //determine the shape type based on the geometry type
      switch (type) {
        case "Point":
        case "MultiPoint":
          shape_type = "circle";
          color_key = "circle-color";
          paint_object = {
            "circle-color": "#1a649d",
            "circle-opacity": 1,
            "circle-radius": 3,
            "circle-stroke-color": "#fff",
            "circle-stroke-width": 2,
            "circle-stroke-opacity": 1,
            "circle-blur": 0,
            "circle-pitch-alignment": "map",
            "circle-pitch-scale": "map",
            "circle-translate": [0, 0],
            "circle-translate-anchor": "map",
          };
          break;
        case "LineString":
        case "MultiLineString":
          shape_type = "line";
          color_key = "line-color";
          paint_object = {
            "line-color": "#1a649d",
            "line-width": 2,
            "line-opacity": 1,
          };
          break;
        case "Polygon":
        case "MultiPolygon":
          shape_type = "fill";
          color_key = "fill-color";
          paint_object = {
            "fill-color": "#1a649d",
            "fill-opacity": 0.7,
          };
          paint_object_line_polygon = {
            "line-color": "#000",
            "line-width": 1,
            "line-opacity": 1,
          };
          break;
        default:
          shape_type = "circle";
          color_key = "circle-color";
          paint_object = {
            "circle-color": "#1a649d",
            "circle-opacity": 1,
            "circle-radius": 3,
            "circle-stroke-color": "#fff",
            "circle-stroke-width": 2,
            "circle-stroke-opacity": 1,
            "circle-blur": 0,
            "circle-pitch-alignment": "map",
            "circle-pitch-scale": "map",
            "circle-translate": [0, 0],
            "circle-translate-anchor": "map",
          };
          break;
      }

      /*
      CASE-CASE WARNA
      
      CASE 1 : dari apply color (front end pie chart sidebar kanan)
      CASE 2 : dari preview jika aktif (sedang edit layer style dan preview on)
      CASE 3 : dari style_array (dari DB, sudah simpan)
      */

      const is_error = !!properties_raw?.[0]?.["LineString"];
      if (is_error) {
        if (type === "Point" || type === "MultiPoint") {
          properties_raw = properties_raw?.[0]?.["Point"];
        } else if (type === "LineString" || type === "MultiLineString") {
          properties_raw = properties_raw?.[0]?.["LineString"];
        } else if (type === "Polygon" || type === "MultiPolygon") {
          properties_raw = properties_raw?.[0]?.["Polygon"];
        }
      }
      let circle_radius = Number(
        properties_raw?.find((d) => d?.key === "circle_radius")?.defaultValue
      );
      let opacity = Number(
        properties_raw?.find((d) => d?.key === "opacity")?.defaultValue
      );
      let outline;
      let stroke;
      let case_color_style = 0;
      if (apply_color_id) {
        //CASE 1 : dari apply color
        case_color_style = 1;
        let apply_value = apply_color_id?.config?.array_color?.labels || [];
        apply_value = apply_value.map((item) => item);
        let apply_color =
          apply_color_id?.config?.array_color?.datasets?.[0]?.backgroundColor;
        const array_apply_color = generate_color_map(
          apply_value,
          apply_color,
          apply_color_id?.config?.field_key
        );
        if (type === "Point" || type === "MultiPoint") {
          paint_object = {
            "circle-color": array_apply_color,
            "circle-radius": circle_radius ? circle_radius : 7,
            "circle-stroke-width": stroke ? stroke : 2,
            "circle-stroke-color": outline ? outline : "#fff",
          };
        } else if (type === "LineString" || type === "MultiLineString") {
          paint_object = {
            "line-color": array_apply_color,
            "line-width": 3,
            "line-opacity": opacity ? opacity : 0.5,
          };
        } else if (type === "Polygon" || type === "MultiPolygon") {
          paint_object = {
            "fill-color": array_apply_color,
            "fill-opacity": 0.4,
            "fill-outline-color": "transparent",
          };
        }
      } else if (
        is_preview_on &&
        !!paint_object_edited?.[color_key] &&
        layer_id === _id
      ) {
        //CASE 2: preview on, sedang edit
        case_color_style = 2;
        if (shape_type === "fill") {
          paint_object = {
            "fill-color": paint_object_edited?.["fill-color"],
            "fill-opacity": paint_object_edited?.["fill-opacity"],
            "fill-outline-color": "transparent",
          };
          paint_object_line_polygon = {
            "line-color": paint_object_edited?.["line-color"],
            "line-width": paint_object_edited?.["line-width"],
            "line-opacity": paint_object_edited?.["line-opacity"],
          };
        } else if (shape_type === "line") {
          paint_object = {
            "line-color": paint_object_edited?.["line-color"],
            "line-width": paint_object_edited?.["line-width"],
            "line-opacity": paint_object_edited?.["line-opacity"],
          };
        } else {
          paint_object = paint_object_edited;
        }
      } else if (style_array.length > 0) {
        //CASE 3: sudah simpan ke DB
        case_color_style = 3;
        const paint_object_db =
          style_array.find((item) => item?.key === default_style_key)
            ?.paint_object || {};
        if (shape_type === "fill") {
          paint_object = {
            "fill-color": paint_object_db["fill-color"],
            "fill-opacity": paint_object_db["fill-opacity"],
            "fill-outline-color": "transparent",
          };
          paint_object_line_polygon = {
            "line-color": paint_object_db["line-color"],
            "line-width": paint_object_db["line-width"],
            "line-opacity": paint_object_db["line-opacity"],
          };
        } else if (shape_type === "line") {
          paint_object = {
            "line-color": paint_object_db["line-color"],
            "line-width": paint_object_db["line-width"],
            "line-opacity": paint_object_db["line-opacity"],
          };
        } else {
          paint_object = paint_object_db;
        }
      }
      layer.case_color_style = case_color_style; //untuk debugging case tanpa warning
      const cluster_id = _id + "_cluster";

      if (map_object !== null) {
        //SOURCE
        if (!map_object?.getSource(_id)) {
          map_object.addSource(_id, {
            type: "geojson",
            data: geojson,
            cluster: false,
          });
          if (type === "Point") {
            //untuk layer tipe Point perlu source cluster meskipun sedang tidak mode cluster untuk transisi pengeditan yang lebih smooth
            if (!map_object?.getSource(cluster_id)) {
              map_object.addSource(cluster_id, {
                type: "geojson",
                data: geojson,
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50,
              });
            }
          } else if (type === "MultiPoint") {
            geojson_point = convert_multi_point_to_points(geojson);
            if (!map_object?.getSource(cluster_id)) {
              map_object.addSource(cluster_id, {
                type: "geojson",
                data: geojson_point,
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50,
              });
            }
          }
        } else {
          map_object.getSource(_id).setData(geojson); //untuk layer pada umumnya, jika sudah ada sourcenya maka update data geojson
          if (type === "Point") {
            //untuk layer tipe Point dan MultiPoint, edit atau tambah juga layer tipe custernya
            if (!map_object?.getSource(cluster_id)) {
              map_object.addSource(cluster_id, {
                type: "geojson",
                data: geojson,
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50,
              });
            } else {
              map_object.getSource(cluster_id).setData(geojson);
            }
          } else if (type === "MultiPoint") {
            geojson_point = convert_multi_point_to_points(geojson);
            if (!map_object?.getSource(cluster_id)) {
              map_object.addSource(cluster_id, {
                type: "geojson",
                data: geojson_point,
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 50,
              });
            } else {
              map_object.getSource(cluster_id).setData(geojson_point);
            }
          }
        }

        //LAYER
        if (!map_object.getLayer(_id)) {
          if (shape_type === "fill") {
            //untuk kasus polygon,
            //perlu layer line karena poligon maplibre tidak bisa ganti ketebalan garis luar untuk fill
            map_object.addLayer({
              id: _id,
              source: _id,
              type: shape_type,
              paint: paint_object,
              layout: {
                visibility: visibility,
              },
            });
            map_object.addLayer({
              id: _id + "_line_polygon",
              source: _id,
              type: "line",
              paint: paint_object_line_polygon,
              layout: {
                visibility: visibility,
              },
            });
          } else if (is_cluster && shape_type === "circle") {
            //untuk kasus is_cluster true perlu tiga layer:
            //1 --> layer titik yang tidak dicluster (mirip layer normal, dengan tambahan filter)
            //2 --> layer clusters_container (lingkaran)
            //3 --> layer cluster_count_number (angka di dalam lingkaran)
            map_object.addLayer({
              id: _id,
              type: "circle",
              source: _id + "_cluster",
              filter: ["!", ["has", "point_count"]],
              paint: paint_object,
              layout: {
                visibility: visibility,
              },
            });
            map_object.addLayer({
              id: _id + "clusters_container",
              type: "circle",
              source: _id + "_cluster",
              filter: ["has", "point_count"],
              paint: {
                "circle-color": [
                  "step",
                  ["get", "point_count"],
                  "#51bbd6",
                  100,
                  "#f1f075",
                  750,
                  "#f28cb1",
                ],
                "circle-radius": [
                  "step",
                  ["get", "point_count"],
                  20,
                  100,
                  30,
                  750,
                  40,
                ],
              },
              layout: {
                visibility: visibility,
              },
            });
            map_object.addLayer({
              id: _id + "cluster_count_number",
              type: "symbol",
              source: _id + "_cluster",
              filter: ["has", "point_count"],
              layout: {
                "text-field": "{point_count_abbreviated}",
                "text-size": 12,
                visibility: visibility,
              },
            });
          } else {
            //untuk kasus lainnya cukup menambahkan satu layer
            map_object.addLayer({
              id: _id,
              source: _id,
              type: shape_type,
              paint: paint_object,
              layout: {
                visibility: visibility,
              },
            });
          }
        } else {
          //jika sudah ada layer dengan _id (karena ada update)
          //hanya update visibility dan juga paint_object nya

          //VISIBILITY GENERAL
          map_object.setLayoutProperty(_id, "visibility", visibility);

          //VISIBILITY & CLUSTER
          if (is_cluster && shape_type === "circle") {
            //TRANSISI dari UNCLUSTER --> CLUSTER

            //layer utama
            map_object.removeLayer(_id);
            map_object.addLayer({
              id: _id,
              type: "circle",
              source: _id + "_cluster",
              filter: ["!", ["has", "point_count"]],
              paint: paint_object,
              layout: {
                visibility: visibility,
              },
            });

            //layer clusters_container
            if (!map_object.getLayer(_id + "clusters_container")) {
              map_object.addLayer({
                id: _id + "clusters_container",
                type: "circle",
                source: _id + "_cluster",
                filter: ["has", "point_count"],
                paint: {
                  "circle-color": [
                    "step",
                    ["get", "point_count"],
                    "#51bbd6",
                    100,
                    "#f1f075",
                    750,
                    "#f28cb1",
                  ],
                  "circle-radius": [
                    "step",
                    ["get", "point_count"],
                    20,
                    100,
                    30,
                    750,
                    40,
                  ],
                },
                layout: {
                  visibility: visibility,
                },
              });
            } else {
              map_object.setLayoutProperty(
                _id + "clusters_container",
                "visibility",
                visibility
              );
            }

            //layer cluster_count_number
            if (!map_object.getLayer(_id + "cluster_count_number")) {
              map_object.addLayer({
                id: _id + "cluster_count_number",
                type: "symbol",
                source: _id + "_cluster",
                filter: ["has", "point_count"],
                layout: {
                  "text-field": "{point_count_abbreviated}",
                  "text-size": 12,
                  visibility: visibility,
                },
              });
            } else {
              map_object.setLayoutProperty(
                _id + "cluster_count_number",
                "visibility",
                visibility
              );
            }
          } else if (!is_cluster && shape_type === "circle") {
            //TRANSISI dari CLUSTER --> UNCLUSTER
            //layer utama
            map_object.removeLayer(_id);
            map_object.addLayer({
              id: _id,
              type: "circle",
              source: _id,
              paint: paint_object,
              layout: {
                visibility: visibility,
              },
            });
            //layer clusters_contaienr
            if (map_object.getLayer(_id + "clusters_container")) {
              map_object.removeLayer(_id + "clusters_container");
            }
            //layer cluster_count_number
            if (map_object.getLayer(_id + "cluster_count_number")) {
              map_object.removeLayer(_id + "cluster_count_number");
            }
          } else if (shape_type === "fill") {
            //untuk kasus fill (Polygon & MultiPolygon)
            //perlu mengubah visibilty dari layer linenya juga
            //karena fill memiliki satu tambahan layer (layer line) untuk garis luar
            map_object.setLayoutProperty(
              _id + "_line_polygon",
              "visibility",
              visibility
            );
          }

          //PAINT_OBJECT
          for (const property in paint_object) {
            map_object.setPaintProperty(_id, property, paint_object[property]);
          }
          if (shape_type === "fill") {
            //untuk kasus fill (Polygon & MultiPolygon)
            //perlu mengubah paint_object dari layer linenya juga
            //karena fill memiliki satu tambahan layer (layer line) untuk garis luar
            for (const property in paint_object_line_polygon) {
              map_object.setPaintProperty(
                _id + "_line_polygon",
                property,
                paint_object_line_polygon[property]
              );
            }
          }
        }

        //POP UP
        this.generate_pop_up({
          _id,
          fields,
          type_2,
          geo_layer,
        });
      }
    });
  };

  generate_pop_up = ({ _id, fields, type_2, geo_layer }) => {
    const { map_object, geometryStatus } = this.props.layer;
    let popup_handlers = this.props.layer.popup_handlers;
    if (!map_object) return; //jangan run ketika map_object belum ada
    if (geometryStatus) return; //jangan run pop up ketika sedang edit geometry,
    //supaya user nyaman ketika sedang edit geometry

    if (popup_handlers[_id]) {
      //check if the handler is already stored, remove it
      map_object.off("click", _id, popup_handlers[_id]);
      map_object.off("touchstart", _id, popup_handlers[_id]); //remove touchstart handler too
    }

    //create a new handler
    const click_handler = (event) => {
      if (event?.features?.[0]?.properties?.key) {
        const feature_key =
          event?.features?.[0]?.properties?.key || event?.features?.[0]?.id;
        const feature_object_selected = geo_layer?.geojson?.features?.find(
          (item) => item?.key === feature_key
        );
        if (!feature_object_selected) return;
        this.props.set_value_layer({
          key: "feature_object_selected",
          value: feature_object_selected,
        });
        const properties = feature_object_selected?.properties || {};
        const geometry = feature_object_selected?.geometry;
        const type = geometry?.type;
        let longitude, latitude;
        if (type === "Point") {
          longitude = geometry?.coordinates?.[0];
          latitude = geometry?.coordinates?.[1];
        } else if (type === "LineString") {
          const length_km = calculate_length(feature_object_selected, {
            units: "kilometers",
          }).toFixed(2);
          const center_length = length_km / 2;
          let feature_center = along(feature_object_selected, center_length, {
            units: "kilometers",
          });
          longitude = feature_center?.geometry?.coordinates?.[0];
          latitude = feature_center?.geometry?.coordinates?.[1];
        } else {
          const geojson = {
            type: "FeatureCollection",
            features: [feature_object_selected],
          };
          const feature_center = centroid(geojson);
          longitude = feature_center?.geometry?.coordinates?.[0];
          latitude = feature_center?.geometry?.coordinates?.[1];
        }
        const { container, close_button, full_button, banjir_button } =
          generatePopupContentDOM(properties, fields, type_2);
        const popup = new maplibregl.Popup({
          closeButton: false, //disable maplibre's default close button
        })
          .setLngLat([longitude, latitude])
          .setDOMContent(container)
          .addTo(map_object);
        this.props.set_value_layer({
          key: "popup_dom",
          value: popup,
        });
        //add a listener for the custom close button
        close_button.addEventListener("click", () => {
          popup.remove(); //manually close the popup when the button is clicked
          this.props.set_value_layer({
            key: "popup_dom",
            value: null,
          });
          this.props.set_value_layer({
            key: "feature_object_selected",
            value: null,
          });
        });
        full_button.addEventListener("click", () => {
          this.props.set_value_layer({
            key: "modal_edit_feature_properties",
            value: true,
          });
          this.props.set_value_layer({
            key: "latitude_selected",
            value: latitude,
          });
          this.props.set_value_layer({
            key: "longitude_selected",
            value: longitude,
          });
          this.props.set_value_layer({
            key: "fields_selected",
            value: fields,
          });
          this.props.set_value_layer({
            key: "properties_selected",
            value: properties,
          });
          this.props.set_value_layer({
            key: "type_2_selected",
            value: type_2,
          });
          this.props.set_value_layer({
            key: "feature_key_selected",
            value: feature_key,
          });
          this.props.set_value_layer({
            key: "geo_layer_selected",
            value: geo_layer,
          });
        });
        if (banjir_button) {
          banjir_button.addEventListener("click", () => {
            const genangan_cm_selected = parseInt(
              feature_object_selected.properties.Ketinggian_Genangan_cm
            );
            const geojson_contour_empty = {
              type: "FeatureCollection",
              features: [],
            };
            this.props.set_value_genangan({
              key: "contourCreated",
              value: false,
            });
            this.props.set_value_properties({
              key: "modal_pop_up_genangan",
              value: true,
            });
            this.props.set_value_genangan({
              key: "feature_key_params_genangan",
              value: feature_key,
            });
            this.props.set_value_genangan({
              key: "feature_key_selected_genangan",
              value: feature_key,
            });
            this.props.set_value_genangan({
              key: "geojson_contour_notflooded",
              value: geojson_contour_empty,
            });
            this.props.set_value_genangan({
              key: "geojson_contour_flooded",
              value: geojson_contour_empty,
            });
            this.props.genangan_update();

            this.props.set_value_genangan({
              key: "contourCreated",
              value: false,
            });
            this.props.set_value_properties({
              key: "feature_key_params_genangan",
              value: feature_key,
            });
            this.props.set_value_properties({
              key: "latitude_params_genangan",
              value: latitude,
            });
            this.props.set_value_properties({
              key: "longitude_params_genangan",
              value: longitude,
            });

            this.props.set_value_properties({
              key: "genangan_cm_params_genangan",
              value: genangan_cm_selected,
            });
            this.props.set_value_properties({
              key: "success_status",
              value: false,
            });
          });
        }
        const popup_content = container.parentElement; //access the popup content div
        popup_content.style.borderRadius = "15px";
        popup_content.style.margin = "0";
        popup_content.style.padding = "10px";
        popup_content.style.overflow = "hidden";
      }
    };
    map_object.on("click", _id, click_handler); //for desktop
    map_object.on("touchstart", _id, click_handler); //for touchscreen devices
    popup_handlers[_id] = click_handler; //timpa dengan data terbaru
    this.props.set_value_layer({
      key: "popup_handlers",
      value: popup_handlers,
    });
  };

  cleanup_event_listerners = () => {
    const { map_object, popup_handlers } = this.props.layer;
    if (map_object) {
      //remove all event listeners for each layer
      Object.keys(popup_handlers).forEach((layer_id) => {
        map_object.off("click", layer_id, popup_handlers[layer_id]);
        map_object.off("touchstart", layer_id, popup_handlers[layer_id]);
      });
      //reset state
      this.props.set_value_layer({
        key: "popup_handlers",
        value: {},
      });
    }
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  set_value_layer,
  set_value_properties,
  set_value_genangan,
  genangan_update,
})(LAYER_GEO);
