import isEqual from "../validation/is-equal";
import calculate_area from "@turf/area";
import sort_array from "../validation/sort_array";
import is_not_empty from "../validation/is_not_empty";

const cilicis_first_load = 10;

const initialState = {
  //new map & map libre
  popup_handlers: {},
  page_layer_mode: "", //editor || viewer
  map_object: null,
  draw_mode: "",
  draw_state_platform: "", //geometry, toolbox, sini
  draw_active: false,
  current_type: "",
  current_architecture: "v2",
  gps_object: {},
  is_pause_zoom_map: false,
  max_zoom: 17,
  status_layer_update: 0,
  //POP UP PROPERTIES DI PETA
  modal_edit_feature_properties: false,
  latitude_selected: 0,
  longitude_selected: 0,
  fields_selected: [],
  properties_selected: {},
  type_2_selected: "",
  feature_key_selected: "",
  genangan_cm_selected: 0,
  geo_layer_selected: {},
  popup_dom: null,
  feature_object_selected: null,
  //basic
  loadingProcess: false,
  loadingDetail: false,
  loadingList: false,
  loading: false,
  itemLoading: "",
  loading_status: false,
  loading_item: "",
  layer_id_active: [],
  layer_id_heatmap: [],
  layer_apply_color: [],
  feature_key_array: [],
  layer_id: "",
  geojson_edit_geometry: {
    type: "FeatureCollection",
    features: [],
  },
  mode_draw_geometry: "simple_select",
  genangan_geojson: { type: "FeatureCollection", features: [] }, //geojson poligon grid genangan
  genangan_area: 0, //luas genangan dalam satuan meter persegi
  feature: {}, //feature point genangan yang sedang di load gridnya
  key_genangan_success: [], //feature key titik genangan yang gridnya success dijalankan
  key_genangan_error: [], //feature key titik genangan yang gridnya error dijalankan
  luas_genangan_list: [], //array resume luas genangan untuk masing-masing
  geo_layer_list: [],
  form_list: [],
  paid_form_list: [],
  search_result_list: {
    geo_layer_list: [],
    geo_project_list: [],
    group_list: [],
    user_list: [],
    layer_import: {},
    is_fresh: true,
  },
  geo_layer: {},
  geo_layer_wo_geojson: {},
  genangan_id: "",
  genangan_polygon_id: "",
  genangan_col_key: "",
  genangan_unit: "cm",
  genangan_status: false,
  genangan_total: 0,
  genangan_current: 0,
  genangan_fail: 0,
  data_view_status: false,
  geometryStatus: false,
  tableStatus: false,
  realtimeStatus: false,
  flyStatus: false,
  chatStatus: false,
  table_full: false,
  flyFeatures: {},
  features_bulk_length: 1,
  features_bulk_status: 0,
  message: "",
  iot_geojson_list: [],
  prev_geo_layer: {},
  appliedColor: {},
  param_filter: [],
  datasetTable: false,
  mapMode: "street",
  draw_status: "new", // new , updating , edit
  statistic_mode: "sini", //data || sini || toolbox
  viewport: {},
  location_list: [],
  modal_link_edit: false,
  refresh_nested_table: {
    change_var: false, // abaikan valuenya, hanya untuk cek ketika ada perubahan saja,
    component_id: "",
  },
};

export default function layerReducer(state = initialState, action) {
  switch (action.type) {
    case "status_layer_update":
      return {
        ...state,
        status_layer_update: state.status_layer_update + 1,
      };
    case "set_value_layer":
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case "set_inside_geo_layer":
      return {
        ...state,
        geo_layer_list: set_inside_geo_layer({
          list: state.geo_layer_list,
          ...action.payload,
        }),
      };
    case "push_inside_geo_layer":
      return {
        ...state,
        geo_layer_list: push_inside_geo_layer({
          list: state.geo_layer_list,
          ...action.payload,
        }),
      };
    case "set_inside_array_geo_layer":
      return {
        ...state,
        geo_layer_list: set_inside_array_geo_layer({
          list: state.geo_layer_list,
          ...action.payload,
        }),
      };
    case "pull_inside_array_geo_layer":
      return {
        ...state,
        geo_layer_list: pull_inside_array_geo_layer({
          list: state.geo_layer_list,
          ...action.payload,
        }),
      };
    case "set_loading_layer":
      return {
        ...state,
        loading_status: true,
        loading_item: action.payload,
      };
    case "clear_loading_layer":
      return {
        ...state,
        loading_status: false,
        loading_item: "",
      };
    case "set_geojson_filtered":
      return {
        ...state,
        geo_layer_list: set_geojson_filtered(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "delete_chart_list":
      return {
        ...state,
        geo_layer_list: delete_chart_list(state.geo_layer_list, action.payload),
      };
    case "push_chart_list":
      return {
        ...state,
        geo_layer_list: push_chart_list(state.geo_layer_list, action.payload),
      };
    case "edit_chart_list":
      return {
        ...state,
        geo_layer_list: edit_chart_list(state.geo_layer_list, action.payload),
      };
    case "push_resume_kai":
      return {
        ...state,
        geo_layer_list: push_resume_kai(state.geo_layer_list, action.payload),
      };
    case "delete_resume_kai":
      return {
        ...state,
        geo_layer_list: delete_resume_kai(state.geo_layer_list, action.payload),
      };
    case "reset_resume_kai":
      return {
        ...state,
        geo_layer_list: reset_resume_kai(state.geo_layer_list, action.payload),
      };
    case "clear_layer_id":
      return {
        ...state,
        layer_id: "",
      };
    case "replace_features":
      return {
        ...state,
        geo_layer_list: replace_features(state.geo_layer_list, action.payload),
      };
    case "push_field_bulk":
      return {
        ...state,
        geo_layer_list: push_field_bulk(state.geo_layer_list, action.payload),
      };
    case "edit_radius_pembanding":
      return {
        ...state,
        geo_layer_list: edit_radius_pembanding(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "edit_percent_value_field_key":
      return {
        ...state,
        geo_layer_list: edit_percent_value_field_key(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "edit_rev_calculation_field_key":
      return {
        ...state,
        geo_layer_list: edit_rev_calculation_field_key(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "edit_final_calculation_field_key":
      return {
        ...state,
        geo_layer_list: edit_final_calculation_field_key(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "set_data_premium_bulk":
      return {
        ...state,
        geo_layer_list: set_data_premium_bulk(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "undo_data_premium_bulk":
      return {
        ...state,
        geo_layer_list: undo_data_premium_bulk(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "push_apply_color":
      return {
        ...state,
        layer_apply_color: push_apply_color(
          state.layer_apply_color,
          action.payload
        ),
      };
    case "delete_apply_color":
      return {
        ...state,
        layer_apply_color: delete_apply_color(
          state.layer_apply_color,
          action.payload
        ),
      };
    case "reject_feature":
      return {
        ...state,
        geo_layer_list: reject_feature(state.geo_layer_list, action.payload),
      };
    case "accept_feature":
      return {
        ...state,
        geo_layer_list: accept_feature(state.geo_layer_list, action.payload),
      };
    case "invitations_group":
      return {
        ...state,
        geo_layer_list: invitations_group(state.geo_layer_list, action.payload),
      };
    case "invitations":
      return {
        ...state,
        geo_layer_list: invitations(state.geo_layer_list, action.payload),
      };
    case "delete_comparison_data":
      return {
        ...state,
        geo_layer: delete_comparison_data(state.geo_layer),
      };
    case "select_comparison_data":
      return {
        ...state,
        geo_layer: select_comparison_data(state.geo_layer, action.payload),
      };
    case "delete_data_form":
      return {
        ...state,
        geo_layer: delete_data_form(state.geo_layer),
      };
    case "edit_data_form":
      return {
        ...state,
        geo_layer: edit_data_form(state.geo_layer, action.payload),
      };
    case "edit_laporan_pdf":
      return {
        ...state,
        geo_layer: edit_laporan_pdf(state.geo_layer, action.payload),
      };
    case "push_field_form_reorder":
      return {
        ...state,
        geo_layer_wo_geojson: push_field_form_reorder(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "array_key":
      return {
        ...state,
        feature_key_array: array_key(state.feature_key_array, action.payload),
      };
    case "view_heatmap":
      return {
        ...state,
        layer_id_heatmap: view_heatmap(state.layer_id_heatmap, action.payload),
      };

    case "get_location_list":
      return {
        ...state,
        location_list: action.payload,
      };
    case "push_revision_list":
      return {
        ...state,
        geo_layer_list: push_revision_list(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "move_layers_into_any_folder":
      return {
        ...state,
        geo_layer_list: move_layers_into_any_folder(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "delete_layers_bulk":
      return {
        ...state,
        geo_layer_list: delete_layers_bulk(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "update_bank":
      return {
        ...state,
        geo_layer_list: update_bank(state.geo_layer_list, action.payload),
      };
    case "create_payment_form_new":
      return {
        ...state,
        geo_layer_list: create_payment_form_new(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "create_payment_form":
      return {
        ...state,
        geo_layer_list: create_payment_form(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "confirm_payment_form":
      return {
        ...state,
        geo_layer_list: confirm_payment_form(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "clear_tanggal":
      return {
        ...state,
        geo_layer_list: clear_tanggal(state.geo_layer_list, state.layer_id),
      };
    case "filter_tanggal":
      return {
        ...state,
        geo_layer_list: filter_tanggal(
          state.geo_layer_list,
          state.layer_id,
          action.payload
        ),
      };
    case "filter_tanggal_new":
      return {
        ...state,
        geo_layer_list: filter_tanggal_new(
          state.geo_layer_list,
          state.layer_id,
          action.payload
        ),
      };
    case "set_item_on_layer":
      return {
        ...state,
        geo_layer_list: set_item_on_layer(state.geo_layer_list, action.payload),
      };
    case "set_data_array":
      return {
        ...state,
        geo_layer_list: set_data_array(
          state.geo_layer_list,
          state.layer_id,
          action.payload
        ),
      };
    case "push_luas_genangan_list":
      return {
        ...state,
        luas_genangan_list: push_luas_genangan_list(
          state.luas_genangan_list,
          action.payload
        ),
      };
    case "push_key_genangan_success":
      return {
        ...state,
        key_genangan_success: [...state.key_genangan_success, action.payload],
      };
    case "push_key_genangan_error":
      return {
        ...state,
        key_genangan_error: [...state.key_genangan_error, action.payload],
      };
    case "set_layer_id":
      return {
        ...state,
        layer_id: action.payload,
      };
    case "grid_cilicis":
      return {
        ...state,
        genangan_geojson: grid_cilicis(state.genangan_geojson, action.payload)
          .object_new,
        genangan_area: grid_cilicis(state.genangan_geojson, action.payload)
          .genangan_area,
      };
    case "set_feature":
      return {
        ...state,
        feature: action.payload,
      };
    case "undo_filter_isi_layer":
      return {
        ...state,
        geo_layer_list: undo_filter_isi_layer(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "filter_isi_layer":
      return {
        ...state,
        geo_layer_list: filter_isi_layer(state.geo_layer_list, action.payload),
        layer_id_active: view_layer(state.layer_id_active, {
          geo_layer_id: action.payload.geo_layer_id,
          value: true,
        }),
      };
    case "reset_filter":
      return {
        ...state,
        geo_layer_list: reset_filter(state.geo_layer_list, action.payload),
        layer_id_active: view_layer(state.layer_id_active, {
          geo_layer_id: action.payload.geo_layer_id,
          value: true,
        }),
      };
    case "reset_fields":
      return {
        ...state,
        geo_layer_list: reset_fields(state.geo_layer_list, action.payload),
      };
    case "REMOVE_DUPLICATE_FEATURES":
      return {
        ...state,
        geo_layer_list: removeDuplicateFeatures(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "SET_LAYER_BY_ID":
      return {
        ...state,
        geo_layer: setLayerById(state.geo_layer_list, action.payload),
        flyStatus: true,
        statistic_mode: "data",
      };
    case "SET_POLYGON_GENANGAN":
      return {
        ...state,
        genangan_polygon_id: action.payload,
        genangan_total: 0,
        genangan_current: 0,
        genangan_fail: 0,
      };
    case "RESET_GENANGAN":
      return {
        ...state,
        geo_layer_list: resetGenangan(state.geo_layer_list, action.payload),
      };
    case "FILTER_GENANGAN":
      return {
        ...state,
        geo_layer_list: filterGenanganByDate(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "FILTER_GENANGAN_UNIX":
      return {
        ...state,
        geo_layer_list: filterGenanganByUnix(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "INC_GENANGAN_ERROR":
      return {
        ...state,
        genangan_fail: state.genangan_fail + 1,
      };
    case "INC_GENANGAN_CURRENT":
      return {
        ...state,
        genangan_current: state.genangan_current + 1,
      };
    case "SET_GENANGAN_TOTAL":
      return {
        ...state,
        genangan_total: action.payload,
      };
    case "SET_GENANGAN_ID":
      return {
        ...state,
        genangan_id: action.payload,
        genangan_status: true,
      };
    case "SET_GENANGAN_COL":
      return {
        ...state,
        genangan_col_key: action.payload,
      };
    case "SET_GENANGAN_UNIT":
      return {
        ...state,
        genangan_unit: action.payload,
      };
    case "FINISH_GENANGAN":
      return {
        ...state,
        genangan_status: false,
        genangan_total: 0,
        genangan_fail: 0,
        genangan_current: 0,
      };
    case "BACK_GENANGAN":
      return {
        ...state,
        genangan_status: false,
      };
    case "SEARCH_INSIDE":
      return {
        ...state,
        geo_layer_list: searchInside(state.geo_layer_list, action.payload),
        flyStatus: true,
      };
    case "RESET_SEARCH_INSIDE":
      return {
        ...state,
        geo_layer_list: reset_searchInside(
          state.geo_layer_list,
          action.payload
        ),
        flyStatus: true,
      };
    case "VIEW_DATA_STATUS":
      return {
        ...state,
        data_view_status: action.payload,
      };
    case "GET_LAYER_ONLY":
      return {
        ...state,
        geo_layer_list: [{ geo_layer: action.payload }],
        geo_layer: {
          geo_layer: action.payload,
          status: "digitasi",
          folder: "",
        },
        flyStatus: true,
        tableStatus: false,
        statistic_mode: "data",
      };
    case "CREATE_FORM":
      return {
        ...state,
        form_list: [...state.form_list.unshift(action.payload)],
      };
    case "CREATE_LAYER":
      return {
        ...state,
        geo_layer_list: push_layer(state.geo_layer_list, action.payload),
        layer_id: action.payload._id,
        layer_id_active: view_layer(state.layer_id_active, {
          geo_layer_id: action.payload._id,
          value: true,
        }),
      };

    case "PUSH_LAYER":
      return {
        ...state,
        geo_layer_list: push_layer(state.geo_layer_list, action.payload),
        statistic_mode: "data",
      };
    case "UPLOAD_LAYER":
      return {
        ...state,
        geo_layer_list: push_layer(state.geo_layer_list, action.payload),
        flyStatus: false,
        layer_id: !action.is_not_fly_to_batch_geojson ? action.payload._id : "",
        tableStatus: !action.is_not_fly_to_batch_geojson,
        layer_id_active: view_layer(state.layer_id_active, {
          geo_layer_id: action.payload._id,
          value: !action.is_not_fly_to_batch_geojson,
        }),
      };
    case "CREATE_GEOJSON_LAYER":
      return {
        ...state,
        geo_layer_list: push_layer(state.geo_layer_list, action.payload),
        flyStatus: false,
        layer_id: !action.is_not_fly_to_batch_geojson ? action.payload._id : "",
        tableStatus: false,
        layer_id_active: view_layer(state.layer_id_active, {
          geo_layer_id: action.payload._id,
          value: !action.is_not_fly_to_batch_geojson,
        }),
      };
    case "UPLOAD_S3":
      return {
        ...state,
        geo_layer_list: push_layer(state.geo_layer_list, action.payload),
        geo_layer: {
          geo_layer: action.payload,
          status: "digitasi",
          folder: "",
        },
        tableStatus: false,
        flyStatus: true,
        features_bulk_length: action?.payload?.features_bulk_length,
      };
    case "EDIT_LAYER_GENERAL":
      return {
        ...state,
        geo_layer_list: editFunction(state.geo_layer_list, action.payload),
      };
    case "EDIT_LAYER_GENERAL_ATTACHED":
      return {
        ...state,
        prev_geo_layer: Object.assign({}, state.geo_layer),
        geo_layer_list: editAttached(state.geo_layer_list, action.payload),
      };
    case "EDIT_LAYER_FORM":
      return {
        ...state,
        geo_layer_wo_geojson: editLayerForm(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "PROPOSE_FORM":
      return {
        ...state,
        geo_layer_wo_geojson: proposeForm(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "EDIT_PROPOSE_FORM_BEFORE_PAYMENT":
      return {
        ...state,
        geo_layer_wo_geojson: editProposeFormBeforePayment(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "EDIT_PROPOSE_FORM_AFTER_PAYMENT":
      return {
        ...state,
        geo_layer_wo_geojson: editProposeFormAfterPayment(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "EDIT_ALL_FIELDS":
      return {
        ...state,
        geo_layer_wo_geojson: editAllFields(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "UPLOAD_PHOTO_FORM":
      return {
        ...state,
        geo_layer_wo_geojson: uploadBannerLayer(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "UPLOAD_PHOTO_FORM_SQUARE":
      return {
        ...state,
        geo_layer_wo_geojson: uploadSquareLayer(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "EDIT_LAYER_ORDER":
      return {
        ...state,
      };
    case "update_relatime_table":
      return {
        ...state,
        geo_layer_list: update_relatime_table(
          state.geo_layer_list,
          action.payload
        ),
      };

    case "PUSH_FEATURE":
      return {
        ...state,
        geo_layer_list: pushFeatureGeoLayerList(
          state.geo_layer_list,
          action.payload
        ),
        layer_id_active: view_layer(state.layer_id_active, {
          geo_layer_id: action.payload.geo_layer_id,
          value: true,
        }),
      };
    case "push_features":
      return {
        ...state,
        geo_layer_list: pushFeaturesGeoLayerList(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "PUSH_FEATURE_BULK":
      return {
        ...state,
        geo_layer_list: pushFeatureGeoLayerListBulk(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "EDIT_GEOMETRY":
      return {
        ...state,
        geo_layer_list: editGeometry(state.geo_layer_list, action.payload),
      };
    case "form_validation":
      return {
        ...state,
        geo_layer: form_validation(state.geo_layer_list, action.payload),
      };
    case "REPLACE_GEOJSON":
      return {
        ...state,
        geo_layer_list: replaceGeojson(
          state.geo_layer_list,
          action.payload,
          !action.is_not_fly_to_batch_geojson
        ),
        flyStatus: !action.is_not_fly_to_batch_geojson,
      };
    case "REPLACE_FIELDS":
      return {
        ...state,
        geo_layer_list: replaceFields(state.geo_layer_list, action.payload),
      };
    case "EDIT_PROPERTIES":
      return {
        ...state,
        geo_layer_list: editProperties(state.geo_layer_list, action.payload),
      };
    case "DELETE_FEATURE":
      return {
        ...state,
        geo_layer_list: deleteFeatureGeoLayerList(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "HIDE_FEATURE":
      return {
        ...state,
      };
    case "PUSH_FIELD":
      return {
        ...state,
        geo_layer_list: pushFieldGeoLayerList(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "PUSH_FIELD_FORM":
      return {
        ...state,
        geo_layer_wo_geojson: pushFieldForm(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "SET_FIELD":
      return {
        ...state,
        geo_layer_list: setFieldGeoLayerList(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "EDIT_FIELD":
      return {
        ...state,
        geo_layer_list: editField(state.geo_layer_list, action.payload),
      };
    case "EDIT_FIELD_FORM":
      return {
        ...state,
        geo_layer_wo_geojson: editFieldForm(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "DELETE_FIELD":
      return {
        ...state,
        geo_layer_list: deleteField(state.geo_layer_list, action.payload),
      };
    case "DELETE_FIELD_FORM":
      return {
        ...state,
        geo_layer_wo_geojson: deleteFieldForm(
          state.geo_layer_wo_geojson,
          action.payload
        ),
      };
    case "push_child_array":
      return {
        ...state,
        geo_layer_list: push_child_array(state.geo_layer_list, action.payload),
      };
    case "edit_child_array_bulk":
      return {
        ...state,
        geo_layer_list: edit_child_array_bulk(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "push_child_array_bulk":
      return {
        ...state,
        geo_layer_list: push_child_array_bulk(
          state.geo_layer_list,
          action.payload
        ),
      };
    case "edit_child_cell":
      return {
        ...state,
        geo_layer_list: edit_child_cell(state.geo_layer_list, action.payload),
      };
    case "delete_child_cell":
      return {
        ...state,
        geo_layer_list: delete_child_cell(state.geo_layer_list, action.payload),
      };
    case "DELETE_LAYER":
      return {
        ...state,
        geo_layer_list: state.geo_layer_list.filter(
          (l) => l.geo_layer._id !== action.payload
        ),
        loadingProcess: false,
        loadingDetail: false,
        loadingList: false,
        tableStatus: false,
      };
    case "GET_FORM_LIST":
      return {
        ...state,
        form_list: action.payload,
      };
    case "GET_PAID_FORM_LIST":
      return {
        ...state,
        paid_form_list: action.payload,
      };
    case "GET_LAYER_LIST":
      return {
        ...state,
        geo_layer_list: action.payload?.isDeepQuery
          ? action.payload.data
          : action.payload,
        flyStatus: true,
      };
    case "SEARCH_BAR":
      return {
        ...state,
        search_result_list: action.payload,
      };
    case "SEARCH_LAYER":
      return {
        ...state,
        search_result_list: {
          ...state.search_result_list,
          geo_layer_list: action.payload,
          is_fresh: false,
        },
      };
    case "LOAD_MORE_SEARCH_LAYER":
      return {
        ...state,
        search_result_list: {
          ...state.search_result_list,
          geo_layer_list: [
            ...state.search_result_list.geo_layer_list,
            ...action.payload,
          ],
          is_fresh: false,
        },
      };
    case "REFRESH_SEARCH":
      return {
        ...state,
        search_result_list: {
          ...state.search_result_list,
          is_fresh: true,
        },
      };
    case "SEARCH_PROJECTS":
      return {
        ...state,
        search_result_list: {
          ...state.search_result_list,
          geo_project_list: action.payload,
          is_fresh: false,
        },
      };
    case "SEARCH_GROUPS":
      return {
        ...state,
        search_result_list: {
          ...state.search_result_list,
          group_list: action.payload,
          is_fresh: false,
        },
      };
    case "SEARCH_LAYER_IMPORT":
      return {
        ...state,
        search_result_list: {
          ...state.search_result_list,
          layer_import: action.payload,
          is_fresh: false,
        },
      };
    case "SEARCH_PROJECT":
      return {
        ...state,
        search_result_list: {
          ...state.search_result_list,
          geo_project_list: action.payload,
          is_fresh: false,
        },
      };
    case "SET_LAYER_DETAIL":
      return {
        ...state,
        statistic_mode: "data",
        data_view_status: false,
        geo_layer: setLayerItem(
          state.geo_layer_list,
          action.payload.geo_layer_item
        ),
        geo_layer_wo_geojson: setLayerItem(
          state.geo_layer_list,
          action.payload.geo_layer_item
        ),
        geo_layer_list: set_geo_layer(
          state.geo_layer_list,
          action.payload.geo_layer_item
        ),
      };
    case "CLEAR_LAYER":
      return {
        ...state,
        geo_layer: {},
        geo_layer_list: [],
        param_filter: [],
        draw_mode: "simple_select", // simple_select, draw_point, draw_line_string, draw_polygon
        draw_status: "new", // new , updating , edit
      };
    case "CLEAR_FORM_LIST":
      return {
        ...state,
        form_list: [],
      };
    case "SET_LAYER_WO_GEOJSON":
      return {
        ...state,
        statistic_mode: "data",
        geo_layer_wo_geojson: setLayerOnly(action.payload),
      };
    case "SET_LAYER_DETAIL_IMPORT":
      return {
        ...state,
        statistic_mode: "data",
        geo_layer: {
          geo_layer: action.payload,
          status: "digitasi",
          folder: "",
        },
        geo_layer_list: set_geo_layer(state.geo_layer_list, action.payload),
        flyStatus: true,
      };
    case "SET_FLY_FEATURES":
      return {
        ...state,
        statistic_mode: "data",
        data_view_status: false,
        flyFeatures: action.payload,
        flyStatus: true,
      };
    case "SET_GEOMETRY_STATUS":
      return {
        ...state,
        statistic_mode: "data",
        data_view_status: false,
        geometryStatus: action.payload,
      };
    case "SET_TABEL_STATUS":
      return {
        ...state,
        statistic_mode: "data",
        data_view_status: false,
        tableStatus: action.payload,
      };
    case "SET_FLY_STATUS":
      return {
        ...state,
        statistic_mode: "data",
        data_view_status: false,
        flyStatus: action.payload,
      };
    case "SET_REALTIME_STATUS":
      return {
        ...state,
        statistic_mode: "data",
        data_view_status: false,
        realtimeStatus: action.payload,
      };
    case "SET_CHAT_STATUS":
      return {
        ...state,
        statistic_mode: "data",
        chatStatus: action.payload,
      };
    case "SET_MESSAGE":
      return {
        ...state,
        message: action.payload,
      };
    case "GET_LAYER_DETAIL":
      return {
        ...state,
        statistic_mode: "data",
        data_view_status: false,
        geo_layer_list: set_geo_layer(
          state.geo_layer_list,
          action.payload.geo_layer_item
        ),
        layer_id: action.payload.is_ignore_set_layer_id
          ? state.layer_id
          : action.payload.geo_layer_item._id,
        layer_id_active: view_layer(state.layer_id_active, {
          geo_layer_id: action.payload.geo_layer_item._id,
          value: true,
        }),
      };
    case "get_detail_by_link_form":
      return {
        ...state,
        statistic_mode: "data",
        data_view_status: false,
        geo_layer_list: [{ geo_layer: action.payload.geo_layer_item }],
        layer_id: action.payload.geo_layer_item._id,
      };
    case "view_layer":
      return {
        ...state,
        layer_id:
          action.payload.value === true
            ? action.payload.geo_layer_id
            : action.payload.value === false &&
              action.payload.geo_layer_id === state.layer_id &&
              action.payload.mode !== "banjir"
              ? ""
              : state.layer_id,
        layer_id_active: view_layer(state.layer_id_active, action.payload),
      };
    case "PUSH_PROJECT_ATTACH":
      return {
        ...state,
        geo_layer_list: push_layer_attach(state.geo_layer_list, action.payload),
      };
    case "EDIT_GEOMETRY_BULK":
      return {
        ...state,
        features_bulk_status: state.features_bulk_status + 1,
      };
    case "FILTER_GEO_LAYER_LIST":
      return {
        ...state,
        statistic_mode: "data",
        param_filter:
          Array.isArray(action.payload) && action.payload.length === 0
            ? []
            : [...state.param_filter, action.payload],
      };
    case "RESET_GEO_LAYER_LIST":
      return {
        ...state,
        statistic_mode: "data",
      };
    case "SET_LOADING_PROCESS_LAYER":
      return {
        ...state,
        loadingProcess: true,
        itemLoading: action?.payload,
      };
    case "SET_LOADING_DETAIL_LAYER":
      return {
        ...state,
        loadingDetail: true,
      };
    case "SET_LOADING_LIST_LAYER":
      return {
        ...state,
        loadingList: true,
      };
    case "SET_LOADING_LAYER":
      return {
        ...state,
        loading: true,
        itemLoading: action.payload,
      };
    case "CLEAR_LOADING_LAYER":
      return {
        ...state,
        loadingProcess: false,
        loadingDetail: false,
        loadingList: false,
        loading: false,
      };
    case "COMMENT_TO_LAYER":
      return {
        ...state,
        geo_layer_list: commentToLayer(state.geo_layer_list, action.payload)
          .layer_list,
      };
    case "GET_IOT_GEOJSON":
      return {
        ...state,
        iot_geojson_list: action.payload,
      };
    case "SET_FILTERED_FEATURES":
      return {
        ...state,
        data_view_status: false,
        geo_layer: { ...state.geo_layer, filtered_features: action.payload },
      };
    case "filter_layer":
      return {
        ...state,
        data_view_status: false,
        geo_layer: filter_layer(state.geo_layer, action.payload),
      };
    case "CLEAR_LAYER_LIST":
      return {
        ...state,
        geo_layer_list: [],
        search_result_list: {
          geo_layer_list: [],
          geo_project_list: [],
          group_list: [],
          user_list: [],
          layer_import: {},
          is_fresh: false,
        },
        geo_layer: {},
        loadingProcess: false,
        loadingDetail: false,
        loadingList: false,
        itemLoading: "",
        geometryStatus: false,
        tableStatus: false,
        datasetTable: false,
        realtimeStatus: false,
        flyStatus: false,
        chatStatus: false,
        flyFeatures: {},
        features_bulk_length: 1,
        features_bulk_status: 0,
        message: "",
        iot_geojson_list: [],
        prev_geo_layer: {},
        appliedColor: {},
        param_filter: [],
      };
    case "SET_APPLIED_COLOR":
      return {
        ...state,
        appliedColor: action.payload,
      };
    case "SET_DATASET_TABEL":
      return {
        ...state,
        datasetTable: action.payload,
      };
    case "TABLE_FULL":
      return {
        ...state,
        table_full: action.payload,
      };
    case "SET_MAP_MODE":
      return {
        ...state,
        mapMode: action.payload,
      };
    case "SET_DRAW_MODE":
      return {
        ...state,
        draw_mode: action.payload,
      };
    case "SET_DRAW_STATUS":
      return {
        ...state,
        draw_status: action.payload,
      };
    case "SET_STATISTIC_MODE":
      return {
        ...state,
        statistic_mode: action.payload,
      };
    case "SET_MAP_VIEWPORT":
      return {
        ...state,
        viewport: action.payload,
      };
    case "refresh_nested_table":
      return {
        ...state,
        refresh_nested_table: {
          change_var: !state?.refresh_nested_table?.change_var,
          component_id: action.payload || "",
        },
      };
    default:
      return state;
  }
}

//edit_chart_list
const edit_chart_list = (list, body) => {
  const { geo_layer_id, chart_object } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  let chart_list = layer?.geo_layer?.chart_list || [];
  const index = chart_list.findIndex((item) => item._id === chart_object._id);
  chart_list[index] = chart_object;
  layer.geo_layer.chart_list = chart_list;
  return list;
};

//push_chart_list
const push_chart_list = (list, body) => {
  const { geo_layer_id, chart_object } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  let chart_list = layer?.geo_layer?.chart_list || [];
  chart_list.push(chart_object);
  layer.geo_layer.chart_list = chart_list;
  return list;
};

//delete_chart_list
const delete_chart_list = (list, body) => {
  const { geo_layer_id, chart_id } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  let chart_list = layer?.geo_layer?.chart_list || [];
  chart_list = chart_list.filter((item) => item._id !== chart_id);
  layer.geo_layer.chart_list = chart_list;
  return list;
};

//push_resume_kai
const push_resume_kai = (list, body) => {
  const { geo_layer_id, timeseries_object } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  layer.geo_layer.timeseries_object = timeseries_object;
  return list;
};

//delete_resume_kai
const delete_resume_kai = (list, body) => {
  const { geo_layer_id } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  layer.geo_layer.timeseries_object = {};
  return list;
};

//reset_resume_kai
const reset_resume_kai = (list, body) => {
  const { geo_layer_id } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  layer.geo_layer.timeseries_object = {};
  return list;
};

const delete_layers_bulk = (list, body) => {
  const layer_id_list = body;
  list = list.filter((item) => !layer_id_list.includes(item?.geo_layer?._id));
  return list;
};

//replace_features
const replace_features = (list, body) => {
  const { geo_layer_id, features } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);

  layer.geo_layer.geojson.features = features;
  layer.geo_layer.geojson_filtered.features = features;
  return list;
};

//push_field_bulk
const push_field_bulk = (list, body) => {
  const { geo_layer_id, fields } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  let fields_new = layer?.geo_layer?.fields || [];
  fields_new = [...fields_new, ...fields];
  layer.geo_layer.fields = fields_new;
  return list;
};

//edit_radius_pembanding
const edit_radius_pembanding = (list, body) => {
  const { geo_layer_id, radius_pembanding } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  layer.geo_layer.radius_pembanding = radius_pembanding;
  return list;
};

//edit_percent_value_field_key
const edit_percent_value_field_key = (list, body) => {
  const { geo_layer_id, field_key } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  layer.geo_layer.percent_value_field_key = field_key;
  return list;
};

//edit_final_calculation_field_key
const edit_rev_calculation_field_key = (list, body) => {
  const { geo_layer_id, field_key } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  layer.geo_layer.rev_calculation_field_key = field_key;
  return list;
};

//edit_final_calculation_field_key
const edit_final_calculation_field_key = (list, body) => {
  const { geo_layer_id, field_key } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  layer.geo_layer.final_calculation_field_key = field_key;
  return list;
};

const set_data_premium_bulk = (list, body) => {
  const { price, category_id } = body;
  list = list.map((item) => {
    item.geo_layer.isPublic = false;
    item.geo_layer.is_premium = true;
    item.geo_layer.price = price;
    item.geo_layer.category_id = category_id;
    return item;
  });
  return list;
};

const undo_data_premium_bulk = (list, body) => {
  list = list.map((item) => {
    item.geo_layer.is_premium = false;
    return item;
  });
  return list;
};

// delete_apply_color
const delete_apply_color = (data, payload) => {
  let filter_data = data.filter(
    (d) => d.config.field_key !== payload.config.field_key
  );
  return filter_data;
};

//push_apply_color
const push_apply_color = (data, payload) => {
  data = [payload];
  return data;
};

// reject_feature
const reject_feature = (geo_layer_list, payload) => {
  const { geo_layer_id, feature_key, revision_list } = payload;
  const layer = geo_layer_list.find((l) => l.geo_layer._id === geo_layer_id);
  let features = layer?.geo_layer?.geojson?.features
    ? layer?.geo_layer?.geojson?.features
    : [];
  const indexFeature = features.findIndex(
    (feature) => feature.key === feature_key
  );
  let new_feature = features[indexFeature];
  new_feature.formStatus.status = "on_rev";
  new_feature.formStatus.revision_list = revision_list;
  features.splice(indexFeature, 1, new_feature);
  layer.geo_layer.geojson.features = features;
  const index = geo_layer_list.findIndex(
    (l) => l.geo_layer._id === geo_layer_id
  );
  geo_layer_list.splice(index, 1, layer);
  return geo_layer_list;
};

// accept_feature
const accept_feature = (geo_layer_list, payload) => {
  const { geo_layer_id, feature_key } = payload;
  const layer = geo_layer_list.find((l) => l.geo_layer._id === geo_layer_id);
  let features = layer?.geo_layer?.geojson?.features
    ? layer?.geo_layer?.geojson?.features
    : [];
  const indexFeature = features.findIndex(
    (feature) => feature.key === feature_key
  );
  let new_feature = features[indexFeature];
  new_feature.formStatus.status = "accepted";
  features.splice(indexFeature, 1, new_feature);
  layer.geo_layer.geojson.features = features;
  const index = geo_layer_list.findIndex(
    (l) => l.geo_layer._id === geo_layer_id
  );
  geo_layer_list.splice(index, 1, layer);
  return geo_layer_list;
};

// invitations
const invitations = (geo_layer_list, payload) => {
  const { email, layer_id, feature_key } = payload;
  const data_user = {
    email,
  };
  const layer = geo_layer_list.find((l) => l.geo_layer._id === layer_id);
  let features = layer?.geo_layer?.geojson?.features
    ? layer?.geo_layer?.geojson?.features
    : [];
  const index_feature_geojson = features.findIndex(
    (feature) => feature?.key === feature_key
  );
  let new_feature = features[index_feature_geojson];
  new_feature.user = data_user;
  const formStatus = { status: "pending" };
  new_feature.formStatus = formStatus;
  features.splice(index_feature_geojson, 1, new_feature);
  layer.geo_layer.geojson.features = features;
  let features_filtered = layer?.geo_layer?.geojson_filtered?.features || [];
  const index_feature_geojson_filtered = features_filtered.findIndex(
    (feature) => feature?.key === feature_key
  );
  let new_feature_filtered = features_filtered[index_feature_geojson_filtered];
  new_feature_filtered.user = data_user;
  new_feature_filtered.formStatus = formStatus;
  features_filtered.splice(
    index_feature_geojson_filtered,
    1,
    new_feature_filtered
  );
  layer.geo_layer.geojson_filtered.features = features_filtered;
  const index = geo_layer_list.findIndex((l) => l.geo_layer._id === layer_id);
  geo_layer_list.splice(index, 1, layer);
  return geo_layer_list;
};

// invitations_group
const invitations_group = (geo_layer_list, payload) => {
  const { layer_id, key, data_user } = payload;
  const layer = geo_layer_list.find((l) => l.geo_layer._id === layer_id);
  let features = layer?.geo_layer?.geojson?.features
    ? layer?.geo_layer?.geojson?.features
    : [];
  const index_feature_geojson = features.findIndex(
    (feature) => feature?.key === key
  );
  let new_feature = features[index_feature_geojson];
  new_feature.user = data_user?.user;
  const formStatus = { status: "assign" };
  new_feature.formStatus = formStatus;
  features.splice(index_feature_geojson, 1, new_feature);
  layer.geo_layer.geojson.features = features;
  let features_filtered = layer?.geo_layer?.geojson_filtered?.features || [];
  const index_feature_geojson_filtered = features_filtered.findIndex(
    (feature) => feature?.key === key
  );
  let new_feature_filtered = features_filtered[index_feature_geojson_filtered];
  new_feature_filtered.user = data_user?.user;
  new_feature_filtered.formStatus = formStatus;
  features_filtered.splice(
    index_feature_geojson_filtered,
    1,
    new_feature_filtered
  );
  layer.geo_layer.geojson_filtered.features = features_filtered;
  const index = geo_layer_list.findIndex((l) => l.geo_layer._id === layer_id);
  geo_layer_list.splice(index, 1, layer);
  return geo_layer_list;
};

const delete_data_form = (geo_layer) => {
  let layer = { ...geo_layer };
  layer.geo_layer.layer_form_list = [];
  return layer;
};

const edit_data_form = (geo_layer, payload) => {
  let layer = { ...geo_layer };
  layer?.geo_layer?.layer_form_list?.push(payload.layer_data);
  return layer;
};

const edit_laporan_pdf = (geo_layer, payload) => {
  let layer = { ...geo_layer };
  layer.geo_layer.laporan_pdf = payload.laporan_pdf;
  return layer;
};

const delete_comparison_data = (geo_layer) => {
  let layer = { ...geo_layer };
  layer.geo_layer.layer_data_list = [];
  return layer;
};

const select_comparison_data = (geo_layer, payload) => {
  let layer = { ...geo_layer };
  layer?.geo_layer?.layer_data_list?.push(payload.layer_data);
  return layer;
};

//push_field_form_reorder
const push_field_form_reorder = (geo_layer_wo_geojson, payload) => {
  let layer = { ...geo_layer_wo_geojson };
  const { field } = payload;
  layer.geo_layer.fields.push(field);
  return layer;
};

// array_key
const array_key = (list, body) => {
  const { key, value } = body;
  if (value === true) {
    list.push(key);
  } else {
    list = list.filter((e) => e !== key);
  }
  return list;
};

// view_heatmap
const view_heatmap = (list, body) => {
  const { geo_layer_id, value } = body;
  if (value === true) {
    list.push(geo_layer_id);
  } else {
    list = list.filter((e) => e !== geo_layer_id);
  }
  return list;
};

//create_payment_form_new
const update_bank = (list, body) => {
  const { geo_layer_id, payment } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  layer.geo_layer.formPayment.payment = payment;
  return list;
};

//push_revision_list
const push_revision_list = (list, body) => {
  const { geo_layer_id, feature_index, revision_list } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  let features = layer?.geo_layer?.geojson?.features?.length
    ? layer?.geo_layer?.geojson.features
    : [];
  features[feature_index].formStatus.revision_list = revision_list;
  features[feature_index].formStatus.status = "rejected";
  return list;
};

//create_payment_form_new
const create_payment_form_new = (list, body) => {
  const { geo_layer_id, payment, amounts_sum } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  layer.geo_layer.formStatus.status = "user_purchase";
  layer.geo_layer.formPayment.payment = payment;
  layer.geo_layer.formPayment.quota = amounts_sum;
  return list;
};

//Client create payment
const create_payment_form = (list, body) => {
  const { geo_layer_id, payment, amounts_sum } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  layer.geo_layer.formStatus.status = "wait_purchase";
  layer.geo_layer.formPayment.payment = payment;
  layer.geo_layer.formPayment.quota = amounts_sum;
  return list;
};

//Client upload receipt for form
const confirm_payment_form = (list, body) => {
  const { geo_layer_id } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  const { picture_url } = body;
  layer.geo_layer.formStatus.status = "user_purchase";
  layer.geo_layer.formPayment.payment.status = "user_purchase";
  layer.geo_layer.formPayment.payment.picture_url = picture_url;
  return list;
};

//form_validation
const form_validation = (list, body) => {
  const { geo_layer_id, feature_key } = body;
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  let features = layer?.geo_layer?.geojson?.features?.length
    ? layer?.geo_layer?.geojson.features
    : [];
  const feature_index = features.findIndex((f) => f.key === feature_key);
  features[feature_index].formStatus.status = "success";
  return list;
};

//reset_filter
const reset_filter = (list, body) => {
  const { geo_layer_id } = body;
  //STEP 1 Ambil data layer dari list dan layer_id
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  //STEP 2 Ambil data fields dari layer
  let fields = layer?.geo_layer?.fields ? layer?.geo_layer?.fields : [];
  //STEP 3 Reset fields
  fields.forEach((field, idx) => {
    fields[idx].array_value_filter = [];
    fields[idx].start_date = "";
    fields[idx].end_date = "";
  });
  //STEP 4 Reset features
  //features sebelum terfilter
  let features = layer?.geo_layer?.geojson?.features?.length
    ? layer?.geo_layer?.geojson.features
    : [];
  //STEP 5 masukkan features yang sudah difilter ke dalam geojson_filtered
  if (layer?.geo_layer?.geojson_filtered?.features) {
    layer.geo_layer.geojson_filtered.features = features;
  }
  return list;
};

//reset_fields
const reset_fields = (list, body) => {
  const { geo_layer_id } = body;
  //STEP 1 Ambil data layer dari list dan layer_id
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  //STEP 2 Ambil data fields dari layer
  let fields = layer?.geo_layer?.fields ? layer?.geo_layer?.fields : [];
  //STEP 3 Reset fields
  fields.forEach((field, idx) => {
    fields[idx].array_value_filter = [];
    fields[idx].start_date = "";
    fields[idx].end_date = "";
  });
  //STEP 4 Reset features
  return list;
};

//undo_filter_isi_layer
const undo_filter_isi_layer = (list, body) => {
  let { geo_layer_id, field_key } = body;
  //STEP 1 Ambil data layer dari list dan layer_id
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  //STEP 2 Ambil data fields dari layer
  let fields = layer?.geo_layer?.fields ? layer?.geo_layer?.fields : [];
  let field = fields.find((f) => f.key === field_key);
  let features = [];
  let value = body.value;
  value = String(value);
  //STEP 3 Ambil data array value dari fields & push satu value yg akan dimasukan
  let array_value_filter = field?.array_value_filter
    ? field?.array_value_filter
    : [];
  array_value_filter = array_value_filter.filter((item) => item !== value);
  field.array_value_filter = array_value_filter;
  //STEP 4 Manipulasi feature ambil dari geojson original simpan geojson filtered
  //features sebelum terfilter
  features =
    layer?.geo_layer?.geojson?.features?.length > 0
      ? layer?.geo_layer?.geojson.features
      : [];
  //filter lapisan 1 type string
  const fields_filtered = fields.filter(
    (f) => f?.array_value_filter?.length > 0
  );
  if (fields_filtered.length > 0) {
    fields_filtered.forEach((field) => {
      //satu persatu filter features dengan field yang sedang difilter
      features = features.filter((feature) => {
        return field.array_value_filter.includes(
          String(feature?.properties?.[field.key])
        );
      });
    });
  }
  //STEP 5 masukkan features yang sudah difilter ke dalam geojson_filtered
  layer.geo_layer.geojson_filtered.features = features;
  return list;
};

//filter_isi_layer
const filter_isi_layer = (list, body) => {
  let {
    geo_layer_id,
    field_key,
    time_mode, //string, unix
    value,
    // mode,
    // idx,
  } = body;

  //STEP 1 Ambil data layer dari list dan layer_id
  let layer = list.find((e) => e.geo_layer._id === geo_layer_id);
  //STEP 2 Ambil data fields dari layer
  let fields = layer?.geo_layer?.fields ? layer?.geo_layer?.fields : [];
  let field = fields.find((f) => f.key === field_key);
  let features = [];

  if (field.type === "date") {
    let start_date = body.start_date;
    let end_date = body.end_date;
    field.start_date = start_date;
    field.end_date = end_date;
    //filter layer
  } else {
    value = String(value);
    //STEP 3 Ambil data array value dari fields & push satu value yg akan dimasukan
    let array_value_filter = field?.array_value_filter
      ? field?.array_value_filter
      : [];
    if (!array_value_filter.includes(value)) {
      array_value_filter.push(value);
    }
    field.array_value_filter = array_value_filter;
  }

  //STEP 4 Manipulasi feature ambil dari geojson original simpan geojson filtered
  //features sebelum terfilter
  features =
    layer?.geo_layer?.geojson?.features?.length > 0
      ? layer?.geo_layer?.geojson.features
      : [];

  //filter lapisan 1 type string
  const fields_filtered = fields.filter(
    (f) => f?.array_value_filter?.length > 0
  );
  if (fields_filtered.length > 0) {
    fields_filtered.forEach((field) => {
      //satu persatu filter features dengan field yang sedang difilter
      features = features.filter((feature) => {
        return field.array_value_filter.includes(
          String(feature?.properties?.[field.key])
        );
      });
    });
  }
  //filter lapisan 2 type date
  const fields_filtered_date = fields.filter((f) => {
    return f.type === "date" && f.start_date;
  });
  if (fields_filtered_date.length > 0) {
    fields_filtered_date.forEach((field) => {
      let start_date, end_date;
      //satu persatu filter features dengan field yang sedang difilter
      if (time_mode === "unix") {
        start_date = parseInt(field.start_date);
        end_date = parseInt(field.end_date);
      } else {
        start_date = parseInt(new Date(field.start_date).getTime());
        end_date = parseInt(new Date(field.end_date).getTime());
      }
      features = features.filter((feature) => {
        let date_test = `${feature.properties[field.key]}T00:00:00`;
        date_test = parseInt(new Date(date_test).getTime());
        return date_test >= start_date && date_test < end_date;
      });
    });
  }
  //STEP 5 masukkan features yang sudah difilter ke dalam geojson_filtered
  if (layer?.geo_layer?.geojson_filtered?.features) {
    layer.geo_layer.geojson_filtered.features = features;
  }

  return list;
};

//clear_tanggal
const clear_tanggal = (list, layer_id) => {
  let list_new = list.slice();
  let layer = list_new.find((e) => e.geo_layer._id === layer_id);
  layer.geo_layer.geojson_filtered.features = layer.geo_layer.geojson.features
    .slice(layer.geo_layer.geojson.features.length - cilicis_first_load)
    .reverse();
  layer.geo_layer.tanggal_list = [];
  return list_new;
};

//filter_tanggal
const filter_tanggal = (list, layer_id, body) => {
  let list_new = list.slice();
  let { tanggal, mode } = body;
  let layer = list_new.find((e) => e.geo_layer._id === layer_id);
  let tanggal_list = layer?.geo_layer?.tanggal_list
    ? layer?.geo_layer?.tanggal_list
    : [];
  tanggal_list.push(tanggal);
  let date_old, date_new;
  if (tanggal_list.length > 1) {
    //jika lebih dari satu, gunakan filter tanggal awal & akhir
    tanggal_list = sort_array(tanggal_list, false, true);
    date_old = tanggal_list[0];
    date_new = tanggal_list[tanggal_list.length - 1];
    //filter geojson filtered dengan
  } else if (tanggal_list.length === 1) {
    //jika hanya ada satu, gunakan tanggal itu dan minus satu hari atau satu jam (tergantung mode)
    let range = 3_600 * 1_000;
    if (mode === "year" || mode === "all" || mode === "month") {
      range = 86_400 * 1_000;
    }
    date_old = tanggal - range;
    date_new = tanggal;
  }
  layer.geo_layer.geojson_filtered.features =
    layer.geo_layer.geojson.features.filter((f) => {
      let date_test = `${f.properties.Tanggal}T${f.properties.Pukul}:00`;
      date_test = new Date(date_test).getTime();
      return date_test >= date_old && date_test < date_new;
    });
  layer.geo_layer.tanggal_list = tanggal_list;
  return list_new;
};

//filter_tanggal_new
const filter_tanggal_new = (list, layer_id, body) => {
  let { start, end } = body;
  let sehari = 86_400 * 1_000;
  const start_unix = new Date(start).getTime() - sehari;
  const end_unix = new Date(end).getTime();
  let layer = list.find((e) => e.geo_layer._id === layer_id);
  layer.geo_layer.geojson_filtered.features =
    layer.geo_layer.geojson.features.filter((f) => {
      let date_test = `${f.properties.Tanggal}T${f.properties.Pukul}:00`;
      date_test = new Date(date_test).getTime();
      return date_test >= start_unix && date_test < end_unix;
    });
  const range = {
    start,
    end,
    start_unix,
    end_unix,
  };
  layer.geo_layer.range = range;
  return list;
};

//set_item_on_layer
const set_item_on_layer = (list, body) => {
  let list_new = list.slice();
  let { key, value, layer_id } = body;
  let layer = list_new.find((e) => e.geo_layer._id === layer_id);
  layer.geo_layer[key] = value;
  return list_new;
};

//set_data_array
const set_data_array = (list, layer_id, body) => {
  let { data_array, mode, field_key } = body;
  let layer = { ...list.find((e) => e.geo_layer._id === layer_id) };
  //looping untuk semua features
  data_array = data_array.map((d) => {
    const count = layer.geo_layer.geojson.features.filter((f) => {
      let date_test = `${f.properties[field_key]}T00:00:00`;
      date_test = new Date(date_test).getTime();
      let range = 3_600 * 1_000;
      if (
        mode === "6_months" ||
        mode === "year" ||
        mode === "all" ||
        mode === "month"
      ) {
        range = 86_400 * 1_000;
      }
      return date_test >= d.x - range && date_test < d.x;
    }).length;
    d.y = count;
    return d;
  });
  data_array = data_array.filter((d) => d.y > 0);
  layer.geo_layer.data_array = data_array;
  return list;
};

//push_luas_genangan_list
const push_luas_genangan_list = (list, body) => {
  const { feature_key, geojson } = body;
  const genangan_area = calculate_area(geojson);
  const object = { feature_key, genangan_area };
  let list_new = list.slice();
  list_new.push(object);
  return list_new;
};

//view_layer
const view_layer = (list, body) => {
  const { geo_layer_id, value } = body;
  if (value === true) {
    list.push(geo_layer_id);
  } else {
    list = list.filter((e) => e !== geo_layer_id);
  }
  return list;
};

//grid_cilicis
const grid_cilicis = (genangan_geojson, features) => {
  let object_new = { ...genangan_geojson };
  object_new.features = [...genangan_geojson.features, ...features];
  const genangan_area = calculate_area(object_new);
  return { object_new, genangan_area };
};

//REMOVE_DUPLICATE_FEATURES
const removeDuplicateFeatures = (geo_layer_list, payload) => {
  const layer_list = geo_layer_list.slice();
  let { layer_id, features } = payload;
  features = features.map((item) => {
    let feature = { ...item };
    let index_duplicate = [];
    index_duplicate = features.map((i, index) => {
      let id = null;
      if (isEqual(i.geometry, item.geometry)) {
        id = index;
      }
      return id;
    });
    index_duplicate = index_duplicate.filter((t) => t !== null);
    feature.index_duplicate = index_duplicate;
    return feature;
  });
  features = features.filter(
    (f, idx) => f.index_duplicate.length === 0 || f.index_duplicate[0] === idx
  );
  const layer = layer_list.filter((l) => l.geo_layer._id === layer_id)[0];
  if (layer?.geo_layer?.geojson?.features) {
    layer.geo_layer.filtered_features = features;
    layer.geo_layer.geojson.features = features;
  }
  const index = layer_list.findIndex((l) => l.geo_layer._id === layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//RESET GENANGAN
const resetGenangan = (geo_layer_list, payload) => {
  const layer_list = geo_layer_list.slice();
  const { layer_id } = payload;
  const layer = layer_list.filter((l) => l.geo_layer._id === layer_id)[0];
  const filtered_features = layer.geo_layer.geojson.features;
  layer.geo_layer.filtered_features = filtered_features;
  const index = layer_list.findIndex((l) => l.geo_layer._id === layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//FILTER GENANGAN
const filterGenanganByUnix = (geo_layer_list, payload) => {
  let layer_list = geo_layer_list.slice();
  const {
    layer_id,
    genangan_date_start,
    genangan_date_end,
    genangan_date_col,
  } = payload;
  const layer = layer_list.filter((l) => l.geo_layer._id === layer_id)[0];
  const start_unix = genangan_date_start;
  const end_unix = genangan_date_end;
  const filtered_features = layer.geo_layer.geojson.features.filter(
    (f) =>
      parseInt(
        (new Date(f.properties[genangan_date_col]).getTime() / 1000).toFixed(0)
      ) >= start_unix &&
      parseInt(
        (new Date(f.properties[genangan_date_col]).getTime() / 1000).toFixed(0)
      ) <= end_unix
  );
  layer.geo_layer.filtered_features = filtered_features;
  const index = layer_list.findIndex((l) => l.geo_layer._id === layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//FILTER GENANGAN
const filterGenanganByDate = (geo_layer_list, payload) => {
  const layer_list = geo_layer_list.slice();
  const {
    layer_id,
    genangan_date_start,
    genangan_date_end,
    genangan_date_col,
  } = payload;
  const layer = layer_list.filter((l) => l.geo_layer._id === layer_id)[0];
  const start_unix = parseInt(
    (new Date(genangan_date_start).getTime() / 1000).toFixed(0)
  );
  const end_unix = parseInt(
    (new Date(genangan_date_end).getTime() / 1000).toFixed(0)
  );
  const filtered_features = layer.geo_layer.geojson.features.filter(
    (f) =>
      parseInt(
        (new Date(f.properties[genangan_date_col]).getTime() / 1000).toFixed(0)
      ) >= start_unix &&
      parseInt(
        (new Date(f.properties[genangan_date_col]).getTime() / 1000).toFixed(0)
      ) <= end_unix
  );
  layer.geo_layer.filtered_features = filtered_features;
  const index = layer_list.findIndex((l) => l.geo_layer._id === layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//SEARCH_INSIDE
const searchInside = (geo_layer_list, payload) => {
  let layer_list = geo_layer_list.slice();
  const { geo_layer_id, geojson } = payload;
  let layer = layer_list.filter((l) => l.geo_layer._id === geo_layer_id)[0];
  layer.geo_layer.geojson = geojson;
  layer.geo_layer.geojson_filtered = geojson;
  layer.geo_layer.viewStatus = true;
  layer.geo_layer.flyStatus = true;
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//reset_searchInside
const reset_searchInside = (geo_layer_list, payload) => {
  return geo_layer_list;
};

//PUSH LAYER
const push_layer_attach = (geo_layer_list, payload) => {
  const layer_list = geo_layer_list.slice();
  const layer = { status: "attached", geo_layer: payload };
  layer_list.push(layer);
  return layer_list;
};

//PUSH LAYER
const push_layer = (geo_layer_list, payload) => {
  if (!payload?.["geojson_filtered"]) {
    payload["geojson_filtered"] = payload?.["geojson"];
  }
  const layer_list = geo_layer_list.slice();
  const layer = {
    folder: payload.folder,
    geo_layer: payload,
    status: payload.status,
  };
  layer_list.push(layer);
  return layer_list;
};

//EDIT GENERAL
const editFunction = (list, payload) => {
  const {
    geo_layer_id, //1
    folder, //2
    name, //3
    description, //4
    is_form_login_req, //5
    isPublic, //6
    show_only_highlight_field, //7
    isGlobalStyle, //8
    isFormPublic, //9
    isLocationChangeable, //10
    isExpand, //11
    properties, //12
    picture_url, //13
    valueStyleProps, //14
    globalStyleMode, //15
    valueStyleList, //16
    isCustomFormMessage, //17
    customFormMessageHeader, //18
    customFormMessageDesc, //19
    is_survey, //20
  } = payload;
  let layer = list.filter((e) => e.geo_layer._id === geo_layer_id)[0];
  //edit layer
  layer.folder = folder; //2
  layer.geo_layer.folder = folder; //2
  //edit inside layer
  layer.geo_layer.name = name; //3
  layer.geo_layer.description = description; //4
  layer.geo_layer.is_form_login_req = is_form_login_req; //5
  layer.geo_layer.isPublic = isPublic; //6
  layer.geo_layer.show_only_highlight_field = show_only_highlight_field; //7
  layer.geo_layer.isGlobalStyle = isGlobalStyle; //8
  layer.geo_layer.isFormPublic = isFormPublic; //9
  layer.geo_layer.isLocationChangeable = isLocationChangeable; //10
  layer.geo_layer.isExpand = isExpand; //11
  layer.geo_layer.properties = properties; //12
  layer.geo_layer.picture_url = picture_url; //13
  layer.geo_layer.valueStyleProps = valueStyleProps; //14
  layer.geo_layer.globalStyleMode = globalStyleMode; //15
  layer.geo_layer.valueStyleList = valueStyleList; //16
  layer.geo_layer.isCustomFormMessage = isCustomFormMessage; //17
  layer.geo_layer.customFormMessageHeader = customFormMessageHeader; //18
  layer.geo_layer.customFormMessageDesc = customFormMessageDesc; //19
  layer.geo_layer.is_survey = is_survey; //20
  return list;
};

//3. Edit Layer General (name, pindah folder) layer attached
const editAttached = (geo_layer_list, payload) => {
  const layer_list = geo_layer_list.slice();
  const { geo_layer_id, folder } = payload;
  let layer = {
    ...layer_list.filter((l) => l.geo_layer._id === geo_layer_id)[0],
  };
  layer.folder = folder;
  const index = layer_list.findIndex((l) => l?.geo_layer?._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//EDIT LAYER FORM
const editLayerForm = (geo_layer, payload) => {
  const layer = { ...geo_layer };
  const {
    name,
    description,
    isLocationChangeable,
    is_form_login_req,
    isFormPublic,
    isCustomFormMessage,
    customFormMessageHeader,
    customFormMessageDesc,
  } = payload;
  layer.geo_layer.name = name;
  layer.geo_layer.description = description;
  layer.geo_layer.isLocationChangeable = isLocationChangeable;
  layer.geo_layer.is_form_login_req = is_form_login_req;
  layer.geo_layer.isFormPublic = isFormPublic;
  layer.geo_layer.isCustomFormMessage = isCustomFormMessage;
  layer.geo_layer.customFormMessageHeader = customFormMessageHeader;
  layer.geo_layer.customFormMessageDesc = customFormMessageDesc;
  return layer;
};

//PROPOSE FORM
const proposeForm = (geo_layer, payload) => {
  const layer = { ...geo_layer };
  const { formPayment } = payload;
  layer.geo_layer.formPayment = formPayment;
  layer.geo_layer.formStatus = { status: "propose" };
  return layer;
};

//EDIT PROPOSE FORM [BEFORE PAYMENT]
const editProposeFormBeforePayment = (geo_layer, payload) => {
  const layer = { ...geo_layer };
  const {
    per_insentif,
    quota,
    isDateRestrict,
    date_start,
    date_end,
    maxResponden,
    isProvince,
    isCity,
    provinces,
    cities,
  } = payload;
  layer.geo_layer.formPayment.per_insentif = per_insentif;
  layer.geo_layer.formPayment.quota = quota;
  layer.geo_layer.formPayment.isDateRestrict = isDateRestrict;
  layer.geo_layer.formPayment.date_start = date_start;
  layer.geo_layer.formPayment.date_end = date_end;
  layer.geo_layer.formPayment.maxResponden = maxResponden;
  layer.geo_layer.formPayment.isProvince = isProvince;
  layer.geo_layer.formPayment.isCity = isCity;
  layer.geo_layer.formPayment.provinces = provinces;
  layer.geo_layer.formPayment.cities = cities;
  return layer;
};

//EDIT PROPOSE FORM [AFTER PAYMENT]
const editProposeFormAfterPayment = (geo_layer, payload) => {
  const layer = { ...geo_layer };
  const {
    isDateRestrict,
    date_start,
    date_end,
    maxResponden,
    isProvince,
    isCity,
    provinces,
    cities,
  } = payload;
  layer.geo_layer.formPayment.isDateRestrict = isDateRestrict;
  layer.geo_layer.formPayment.date_start = date_start;
  layer.geo_layer.formPayment.date_end = date_end;
  layer.geo_layer.formPayment.maxResponden = maxResponden;
  layer.geo_layer.formPayment.isProvince = isProvince;
  layer.geo_layer.formPayment.isCity = isCity;
  layer.geo_layer.formPayment.provinces = provinces;
  layer.geo_layer.formPayment.cities = cities;
  return layer;
};

//EDIT REORDER FIELDS
const editAllFields = (geo_layer, payload) => {
  const layer = { ...geo_layer };
  const { fields } = payload;
  layer.geo_layer.fields = fields;
  return layer;
};

//UPLOAD BANNER LAYER
const uploadBannerLayer = (geo_layer, payload) => {
  const layer = { ...geo_layer };
  const url = payload;
  layer.geo_layer.picture_url = url;
  return layer;
};

//UPLOAD BANNER LAYER SQUARE
const uploadSquareLayer = (geo_layer, payload) => {
  const layer = { ...geo_layer };
  const url = payload;
  layer.geo_layer.picture_url_square = [{ url: url }];
  return layer;
};

//TIMPA GEOJSON
const replaceGeojson = (geo_layer_list, payload, viewStatus) => {
  const layer_list = geo_layer_list.slice();
  const { geo_layer_id, geojson } = payload;
  const layer = layer_list.find((l) => l.geo_layer._id === geo_layer_id);
  layer.geo_layer.geojson = geojson;
  layer.geo_layer.geojson_filtered = { ...geojson };
  layer.geo_layer.viewStatus = viewStatus === false ? false : true;
  layer.geo_layer.flyStatus = viewStatus === false ? false : true;
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//TIMPA FIELDS
const replaceFields = (geo_layer_list, payload) => {
  const layer_list = geo_layer_list.slice();
  const { geo_layer_id, fields } = payload;
  const layer = layer_list.find((l) => l.geo_layer._id === geo_layer_id);
  layer.geo_layer.fields = structuredClone(fields);
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//SET LAYER
const set_geo_layer = (list, payload) => {
  let layer = payload;
  const index = list.findIndex((l) => l?.geo_layer?._id === layer?._id);
  if (index !== -1) {
    list[index].geo_layer = layer;
  }
  return list;
};

const setLayerItem = (geo_layer_list, payload) => {
  //untuk mendapatkan data folder dari layer itu, terpaksa memanggil geo_layer_list
  let layer_list = geo_layer_list.slice();
  let layer = payload;
  const index = layer_list.findIndex((l) => l?.geo_layer?._id === layer?._id);
  const layer_new = { ...layer_list[index], geo_layer: layer };
  return layer_new;
};

const setLayerOnly = (payload) => {
  const layer_new = { geo_layer: payload, folder: payload.folder };
  return layer_new;
};

const update_relatime_table = (geo_layer_list, payload) => {
  return geo_layer_list;
};

//PUSH FEATURE
const pushFeatureGeoLayerList = (geo_layer_list, payload) => {
  const layer_list = geo_layer_list.slice();
  const { geo_layer_id, feature } = payload;
  const feature_key = feature.key;
  const layer = layer_list.filter((l) => l.geo_layer._id === geo_layer_id)[0];
  let features = layer?.geo_layer?.geojson?.features || [];
  let features_filtered = layer?.geo_layer?.geojson_filtered?.features || [];
  features = [...features];
  features_filtered = [...features_filtered];
  const features_key = features.map((item) => item.key);
  const idx_length = features.length
  const features_filtered_key = features_filtered.map((item) => item.key);
  const idx_length_filtered = features_filtered.length
  if (!features_key.includes(feature_key)) {
    feature.idx = idx_length
    feature.properties.id = idx_length;
    features.push(feature)
  };
  if (!features_filtered_key.includes(feature_key)) {
    feature.idx = idx_length_filtered
    feature.properties.id = idx_length_filtered;
    features_filtered.push(feature);
  }
  layer.geo_layer.geojson.features = features;
  layer.geo_layer.geojson_filtered.features = features_filtered;
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//PUSH FEATURES
const pushFeaturesGeoLayerList = (geo_layer_list, payload) => {
  let layer_list = geo_layer_list.slice();
  const { geo_layer_id } = payload;
  const new_features = payload?.features;
  let layer = layer_list.find((l) => l.geo_layer._id === geo_layer_id);
  let geojson = layer?.geo_layer?.geojson || {
    type: "FeatureCollection",
    features: [],
  };
  let features = geojson?.features || [];
  features = [...features, ...new_features];
  geojson.features = features;
  layer.geo_layer.geojson = geojson;
  let geojson_filtered = layer?.geo_layer?.geojson_filtered || {
    type: "FeatureCollection",
    features: [],
  };
  features = geojson_filtered?.features || [];
  features = [...features, ...new_features];
  geojson_filtered.features = features;
  layer.geo_layer.geojson_filtered = geojson_filtered;
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//SET LAYER
const setLayerById = (geo_layer_list, layer_id) => {
  const layer_list = geo_layer_list.slice();
  let layer = layer_list.filter((l) => l.geo_layer._id === layer_id)[0];
  layer.geo_layer.viewStatus = true;
  return layer;
};

//PUSH FEATURE BULK
const pushFeatureGeoLayerListBulk = (geo_layer_list, payload) => {
  const layer_list = geo_layer_list.slice();
  const { geo_layer_id, feature } = payload;
  const layer = layer_list.filter((l) => l.geo_layer._id === geo_layer_id)[0];
  const features = layer.geo_layer.geojson.features;
  const features_concat = features.concat(feature);
  layer.geo_layer.geojson.features = features_concat;
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//DELETE FEATURE
const deleteFeatureGeoLayerList = (geo_layer_list, payload) => {
  try {
    const layer_list = geo_layer_list.slice();
    const { geo_layer_id, feature_key } = payload;
    let layer = layer_list.find((l) => l.geo_layer._id === geo_layer_id);
    const index_that_will_be_deleted =
      layer.geo_layer?.geojson?.features?.findIndex(
        (feature_element) => feature_element.key === feature_key
      );
    if (index_that_will_be_deleted !== -1) {
      layer.geo_layer.geojson.features.splice(index_that_will_be_deleted, 1);
    }
    const index_that_will_be_deleted_from_filtered =
      layer.geo_layer?.geojson_filtered?.features?.findIndex(
        (feature_element) => feature_element.key === feature_key
      );
    if (index_that_will_be_deleted_from_filtered !== -1) {
      layer.geo_layer.geojson_filtered.features.splice(
        index_that_will_be_deleted_from_filtered,
        1
      );
    }
    const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
    layer_list.splice(index, 1, layer);
    return layer_list;
  } catch (e) {
    return geo_layer_list;
  }
};

//Delete FIELD
const deleteField = (geo_layer_list, payload) => {
  let layer_list = geo_layer_list.slice();
  const { geo_layer_id, field_key } = payload;
  layer_list.forEach((layer) => {
    if (layer.geo_layer._id === geo_layer_id) {
      layer.geo_layer.fields = layer.geo_layer.fields.filter(
        (field) => field.key !== field_key
      );
    }
  });
  return layer_list;
};

//PUSH FIELD
const pushFieldGeoLayerList = (geo_layer_list, payload) => {
  const layer_list = geo_layer_list.slice();
  const { geo_layer_id, field } = payload;
  const layer = layer_list.filter((l) => l.geo_layer._id === geo_layer_id)[0];
  layer.geo_layer.fields.push(field);
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//SET FIELD
const setFieldGeoLayerList = (geo_layer_list, payload) => {
  const layer_list = geo_layer_list.slice();
  const { geo_layer_id, field } = payload;
  const layer = layer_list.filter((l) => l.geo_layer._id === geo_layer_id)[0];
  layer.geo_layer.fields = field;
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//EDIT PROPERTIES
const editProperties = (geo_layer_list, payload) => {
  const layer_list = geo_layer_list.slice();
  const { geo_layer_id, feature_key, properties_key, properties_value } =
    payload;
  let layer = layer_list.find((l) => l.geo_layer._id === geo_layer_id);
  const features = layer?.geo_layer?.geojson?.features;
  const features_filtered = layer?.geo_layer?.geojson_filtered?.features;
  // geojson
  const index_feature = features?.findIndex(
    (feature) => feature.key === feature_key
  );
  if (index_feature !== -1) {
    let new_feature = features[index_feature];
    new_feature.properties[properties_key] = properties_value;
    layer.geo_layer.geojson.features[index_feature] = new_feature;
    const index_feature_filtered = features_filtered.findIndex(
      (feature) => feature.key === feature_key
    );
    if (index_feature_filtered !== -1) {
      layer.geo_layer.geojson_filtered.features[index_feature_filtered] =
        new_feature;
    }
  }
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//EDIT FIELD
const editField = (geo_layer_list, payload) => {
  const layer_list = geo_layer_list.slice();
  const { geo_layer_id, field } = payload;
  const layer = layer_list.filter((l) => l.geo_layer._id === geo_layer_id)[0];
  let fields = layer?.geo_layer?.fields ? layer?.geo_layer?.fields : [];
  const indexField = fields.findIndex(
    (field_element) => field_element.key === field.key
  );
  fields[indexField] = field;
  layer.geo_layer.fields = fields;
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//EDIT FIELD FORM
const editFieldForm = (geo_layer_wo_geojson, payload) => {
  const layer = { ...geo_layer_wo_geojson };
  const { field } = payload;
  let fields = layer?.geo_layer?.fields ? layer?.geo_layer?.fields : [];
  const indexField = fields.findIndex(
    (field_element) => field_element.key === field.key
  );
  fields[indexField] = field;
  layer.geo_layer.fields = fields;
  return layer;
};

//PUSH FIELD FORM
const pushFieldForm = (geo_layer_wo_geojson, payload) => {
  let layer = { ...geo_layer_wo_geojson };
  const { field } = payload;
  layer.geo_layer.fields.push(field);
  return layer;
};

//PUSH FIELD FORM
const deleteFieldForm = (geo_layer_wo_geojson, payload) => {
  const layer = { ...geo_layer_wo_geojson };
  const { field_key } = payload;
  const fields = layer.geo_layer.fields.filter(
    (field) => field.key !== field_key
  );
  layer.geo_layer.fields = fields;
  return layer;
};

const push_child_array = (geo_layer_list, payload) => {
  const {
    geo_layer_id,
    feature_key,
    child_uuid,
    parent_uuid,
    parent_uuid_temp,
    properties,
  } = payload;
  const layer_list = geo_layer_list.slice();
  const layer = layer_list.find((l) => l.geo_layer._id === geo_layer_id);
  const features = layer?.geo_layer?.geojson?.features;
  const features_filtered = layer?.geo_layer?.geojson_filtered?.features;
  // geojson
  const index_feature = features?.findIndex(
    (feature) => feature.key === feature_key
  );
  if (index_feature !== -1) {
    let new_feature = features[index_feature];
    const child_array = new_feature?.child_array;
    const body = {
      child_uuid,
      parent_uuid,
      properties,
    };
    if (is_not_empty(parent_uuid_temp, [""])) {
      body["parent_uuid_temp"] = parent_uuid_temp;
    }
    child_array.push(body);
    new_feature["child_array"] = child_array;
    layer?.geo_layer?.geojson?.features?.splice(index_feature, 1, new_feature);
    // geojson filtered
    const index_feature_filtered = features_filtered.findIndex(
      (feature) => feature.key === feature_key
    );
    if (index_feature_filtered !== -1) {
      layer?.geo_layer?.geojson_filtered?.features.splice(
        index_feature_filtered,
        1,
        new_feature
      );
    }
  }
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

const edit_child_array_bulk = (geo_layer_list, body) => {
  const {
    geo_layer_id,
    feature_key,
    child_object_array,
    parent_uuid,
    field_key,
  } = body;
  const layer_list = geo_layer_list.slice();
  const layer = layer_list.find((l) => l.geo_layer._id === geo_layer_id);
  const features = layer?.geo_layer?.geojson?.features;
  const features_filtered = layer?.geo_layer?.geojson_filtered?.features;
  // geojson
  const index_feature = features?.findIndex(
    (feature) => feature.key === feature_key
  );
  if (index_feature !== -1) {
    let new_feature = features[index_feature];
    let child_array = new_feature?.child_array || [];
    child_array.forEach((child, idx) => {
      if (child.parent_uuid === parent_uuid) {
        if (child_array?.[idx]) {
          const value = child_object_array.find(
            (item) => item.child_uuid === child.child_uuid
          )?.properties?.[field_key];
          child_array[idx].properties[field_key] = value;
        }
      }
    });
    new_feature["child_array"] = child_array;
    layer?.geo_layer?.geojson?.features?.splice(index_feature, 1, new_feature);
    // geojson filtered
    const index_feature_filtered = features_filtered.findIndex(
      (feature) => feature.key === feature_key
    );
    if (index_feature_filtered !== -1) {
      layer?.geo_layer?.geojson_filtered?.features.splice(
        index_feature_filtered,
        1,
        new_feature
      );
    }
  }
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

const push_child_array_bulk = (geo_layer_list, body) => {
  const { geo_layer_id, feature_key, child_object_array } = body;
  const layer_list = geo_layer_list.slice();
  const layer = layer_list.find((l) => l.geo_layer._id === geo_layer_id);
  const features = layer?.geo_layer?.geojson?.features;
  const features_filtered = layer?.geo_layer?.geojson_filtered?.features;
  // geojson
  const index_feature = features?.findIndex(
    (feature) => feature.key === feature_key
  );
  if (index_feature !== -1) {
    let new_feature = features[index_feature];
    let child_array = new_feature?.child_array || [];
    child_array = [...child_array, ...child_object_array];
    new_feature["child_array"] = child_array;
    layer?.geo_layer?.geojson?.features?.splice(index_feature, 1, new_feature);
    // geojson filtered
    const index_feature_filtered = features_filtered.findIndex(
      (feature) => feature.key === feature_key
    );
    if (index_feature_filtered !== -1) {
      layer?.geo_layer?.geojson_filtered?.features.splice(
        index_feature_filtered,
        1,
        new_feature
      );
    }
  }
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

const edit_child_cell = (geo_layer_list, payload) => {
  const {
    geo_layer_id,
    feature_key,
    child_uuid,
    properties_key,
    properties_value,
  } = payload;
  const layer_list = geo_layer_list.slice();
  const layer = layer_list.find((l) => l.geo_layer._id === geo_layer_id);
  const features = layer?.geo_layer?.geojson?.features;
  const features_filtered = layer?.geo_layer?.geojson_filtered?.features;
  // geojson
  const index_feature = features?.findIndex(
    (feature) => feature.key === feature_key
  );
  if (index_feature !== -1) {
    let new_feature = features[index_feature];
    const index_child = new_feature?.child_array?.findIndex(
      (item) => item?.child_uuid === child_uuid
    );
    if (index_child !== -1) {
      if (!new_feature?.["child_array"]?.[index_child]?.["properties"]) {
        new_feature["child_array"][index_child]["properties"] = {};
      }
      new_feature["child_array"][index_child]["properties"][properties_key] =
        properties_value;
    }
    layer?.geo_layer?.geojson?.features?.splice(index_feature, 1, new_feature);
    // geojson filtered
    const index_feature_filtered = features_filtered.findIndex(
      (feature) => feature.key === feature_key
    );
    if (index_feature_filtered !== -1) {
      layer?.geo_layer?.geojson_filtered?.features.splice(
        index_feature_filtered,
        1,
        new_feature
      );
    }
  }
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

const delete_child_cell = (geo_layer_list, payload) => {
  const { geo_layer_id, feature_key, child_uuid } = payload;
  const layer_list = geo_layer_list.slice();
  const layer = layer_list.find((l) => l.geo_layer._id === geo_layer_id);
  const features = layer?.geo_layer?.geojson?.features;
  const features_filtered = layer?.geo_layer?.geojson_filtered?.features;
  // geojson
  const index_feature = features?.findIndex(
    (feature) => feature.key === feature_key
  );
  if (index_feature !== -1) {
    let new_feature = features[index_feature];
    new_feature["child_array"] = new_feature?.child_array?.filter(
      (item) => item?.child_uuid !== child_uuid
    );
    layer?.geo_layer?.geojson?.features?.splice(index_feature, 1, new_feature);
    // geojson filtered
    const index_feature_filtered = features_filtered.findIndex(
      (feature) => feature.key === feature_key
    );
    if (index_feature_filtered !== -1) {
      layer?.geo_layer?.geojson_filtered?.features.splice(
        index_feature_filtered,
        1,
        new_feature
      );
    }
  }
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//EDIT GEOMETRY
const editGeometry = (geo_layer_list, payload) => {
  const layer_list = geo_layer_list.slice();
  const { geo_layer_id, feature_key, geometry } = payload;
  const layer = layer_list.filter((l) => l.geo_layer._id === geo_layer_id)[0];
  let { features } = layer.geo_layer.geojson;
  const indexFeature = features.findIndex(
    (feature) => feature.key === feature_key
  );
  let new_feature = features[indexFeature];
  new_feature.geometry = geometry;
  features.splice(indexFeature, 1, new_feature);
  layer.geo_layer.geojson.features = features;
  layer.geo_layer.geojson_filtered.features = features;
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return layer_list;
};

//Push comment to layer [NOTIF]
const commentToLayer = (geo_layer_list, payload) => {
  const layer_list = geo_layer_list.slice();
  const { geo_layer_id, comment } = payload;
  const layer = layer_list.filter((l) => l.geo_layer._id === geo_layer_id)[0];
  layer.geo_layer.comments.push(comment);
  const index = layer_list.findIndex((l) => l.geo_layer._id === geo_layer_id);
  layer_list.splice(index, 1, layer);
  return { layer_list, layer };
};

const filter_layer = (geo_layer, features) => {
  geo_layer.geo_layer.geojson.features = features;
  return geo_layer;
};

const move_layers_into_any_folder = (geo_layer_list, payload) => {
  if (
    payload?.data?.layers?.length > 0 &&
    payload?.data?.layers?.length === payload.data?.layers?.length
  ) {
    for (const [index, layer] of payload?.data?.layers?.entries()) {
      const idx = geo_layer_list.findIndex(
        (geo_layer) => geo_layer._id === layer?._id
      );
      if (idx !== -1) {
        geo_layer_list[idx]["folder"] = payload.data?.layers[index]["folder"];
      }
    }
  }
  return geo_layer_list;
};

const set_geojson_filtered = (list, body) => {
  const { geo_layer_id, features_filtered } = body;
  const index = list.findIndex((item) => item?.geo_layer?._id === geo_layer_id);
  list[index].geo_layer.geojson_filtered.features = features_filtered;
  return list;
};

const set_inside_geo_layer = ({ list, key, value, geo_layer_id }) => {
  const index = list.findIndex((item) => item?.geo_layer?._id === geo_layer_id);
  list[index].geo_layer[key] = value;
  return list;
};

const push_inside_geo_layer = ({ list, key, value, geo_layer_id }) => {
  const index = list.findIndex((item) => item?.geo_layer?._id === geo_layer_id);
  let array_updated = list?.[index]?.geo_layer?.[key] || [];
  array_updated.push(value);
  list[index].geo_layer[key] = array_updated;
  return list;
};

const set_inside_array_geo_layer = ({
  list,
  geo_layer_id,
  key,
  key_id_child,
  value_id_child,
  value,
}) => {
  const index = list.findIndex((item) => item?.geo_layer?._id === geo_layer_id);
  let array_updated = list?.[index]?.geo_layer?.[key] || [];
  const index_child = array_updated.findIndex(
    (item) => item?.[key_id_child] === value_id_child
  );
  if (index_child > -1) {
    array_updated[index_child] = value;
  }
  list[index].geo_layer[key] = array_updated;
  return list;
};

const pull_inside_array_geo_layer = ({
  list,
  geo_layer_id,
  key,
  key_id_child,
  value_id_child,
}) => {
  const index = list.findIndex((item) => item?.geo_layer?._id === geo_layer_id);
  let array_updated = list?.[index]?.geo_layer?.[key] || [];
  array_updated = array_updated.filter(
    (item) => item?.[key_id_child] !== value_id_child
  );
  list[index].geo_layer[key] = array_updated;
  return list;
};
