import { Button, MenuItem } from "@mui/material";
import Menu from "@mui/material/Menu";
import { Component } from "react";
import { connect } from "react-redux";

class PANGGIL_MENU extends Component {
  state = {
    anchor: null,
    modal_menu_card: false,
  };

  toggle_menu_card = (e) => {
    this.setState({
      modal_menu_card: !this.state.modal_menu_card,
      anchor: e.currentTarget,
    });
  };

  render() {
    const { handleRefCol, focused } = this.props;
    const { anchor, modal_menu_card } = this.state;

    const modal_menu_card_content = modal_menu_card && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_menu_card}
        onClose={this.toggle_menu_card}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ borderRadius: "15px" }}
      >
        {
          <MenuItem disabled={focused ? false : true} onClick={handleRefCol}>
            Ref
          </MenuItem>
        }
        {/* <MenuItem disabled={true} onClick={handleRefCol}>Generate KLB</MenuItem>
        <MenuItem disabled={true} onClick={handleRefCol}>Generate KDB</MenuItem>   */}
      </Menu>
    );

    return (
      <main>
        <Button
          id="ref_button"
          onClick={this.toggle_menu_card}
          style={{ height: "40px", fontSize: "20px", width: "80px" }}
          variant="outlined"
        >
          {"</>"}
        </Button>
        {modal_menu_card_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(PANGGIL_MENU);
