const get_browser_info = function () {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;

  // Detect browser name
  let browserName = "Unknown";
  if (/Chrome|CriOS/i.test(userAgent)) {
    browserName = "Chrome";
  } else if (/Firefox/i.test(userAgent)) {
    browserName = "Firefox";
  } else if (/Safari/i.test(userAgent) && !/Chrome|CriOS/i.test(userAgent)) {
    browserName = "Safari";
  } else if (/Edg/i.test(userAgent)) {
    browserName = "Edge";
  } else if (/Opera|OPR/i.test(userAgent)) {
    browserName = "Opera";
  } else if (/MSIE|Trident/i.test(userAgent)) {
    browserName = "Internet Explorer";
  }

  // Detect browser engine
  let browserEngine = "Unknown";
  if (/WebKit/i.test(userAgent)) {
    browserEngine = "WebKit";
  } else if (/Gecko/i.test(userAgent) && !/WebKit/i.test(userAgent)) {
    browserEngine = "Gecko";
  } else if (/Trident/i.test(userAgent)) {
    browserEngine = "Trident";
  } else if (/Presto/i.test(userAgent)) {
    browserEngine = "Presto";
  }

  // Detect OS name and version
  let osName = "Unknown";
  let osVersion = "Unknown";

  if (/Windows NT 10.0/i.test(userAgent)) {
    osName = "Windows";
    osVersion = "10";
  } else if (/Windows NT 6.2/i.test(userAgent)) {
    osName = "Windows";
    osVersion = "8";
  } else if (/Windows NT 6.1/i.test(userAgent)) {
    osName = "Windows";
    osVersion = "7";
  } else if (/Mac OS X (\d+([._]\d+)+)/i.test(userAgent)) {
    osName = "Mac OS";
    osVersion = userAgent
      .match(/Mac OS X (\d+([._]\d+)+)/i)[1]
      .replace(/_/g, ".");
  } else if (/Android (\d+([._]\d+)+)/i.test(userAgent)) {
    osName = "Android";
    osVersion = userAgent.match(/Android (\d+([._]\d+)+)/i)[1];
  } else if (/iPhone OS (\d+_\d+)/i.test(userAgent)) {
    osName = "iOS";
    osVersion = userAgent.match(/iPhone OS (\d+_\d+)/i)[1].replace("_", ".");
  } else if (/Linux/i.test(platform)) {
    osName = "Linux";
    osVersion = "Unknown";
  }

  return {
    browser_name: browserName,
    browser_engine: browserEngine,
    os_name: osName,
    os_version: osVersion,
  };
};

export default get_browser_info;
