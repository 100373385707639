/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Reorder } from "framer-motion";
import { Resizable } from "re-resizable";

/*PERSONAL COMPONENT*/
import Modal from "../../Components/common_modal/Modal";
import HomeButton from "../../Components/form/HomeButton";
import UploadBanner from "../../Components/form/UploadBanner";
import UploadSquare from "../../Components/form/UploadSquare";
import PushFieldForm from "../../Components/form/PushFieldForm";
import SidebarForm from "../../Components/form/SidebarForm";
import EditGeneral from "../../Components/form/EditGeneral";
import EditTerm from "../../Components/form/EditTerm";
import FieldCard from "../../Components/form/FieldCard";

/*REDUX FUNCTION*/
import {
  getDetailLayerByLinkWOgeojson,
  editFieldForm,
  deleteFieldForm,
  editAllFields,
} from "../../App/actions/layerActions";
import { createFormDoc, getBlogDetailForm } from "../../App/actions/blogAction";

/*PICTURE ASSET*/
import icon_edit_text from "../../Assets/svg/icon_edit_text.svg";
import icon_404 from "../../Assets/svg/icon_404.svg";

/*GENERAL FUNCTION & DATA*/
import isEmpty from "../../App/validation/is-empty";
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";

/*NON IMPORT*/

class CreateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal_sidebar: false,
      modal_push_field: false,
      modal_delete_field: false,
      modal_reorder: false,
      modal_edit_general: false,
      modalPaidForm: false,
      modalBillingForm: false,
      modalTerm: false,
      uuid_inside_term: "",
      field: {},
      field_list: [],
      field_inside_term: {},
      from: 0,
      to: 0,
      from_uuid: "",
      to_uuid: "",
      modal_limit_digit: false,
      operation_digit: "===",
      number_digit: 10,
      mode_middle: false,
      sidebar_width: 0,
      is_hold: false,
      geo_layer: {},
    };
  }

  componentDidMount() {
    this.timer = null;
    if (document.getElementById("container_document")) {
      document.getElementById("container_document").style.width = "100%";
    }
    if (document.getElementById("container_document")) {
      document.getElementById("container_document").style.marginLeft = "0px";
    }
    const { geo_layer_link } = this.props.match.params;
    this.props.getDetailLayerByLinkWOgeojson(geo_layer_link);
  }

  componentDidUpdate = (prevProps, prevState) => {
    const status_layer_update_before = prevProps.layer.status_layer_update;
    const status_layer_update_after = this.props.layer.status_layer_update;
    if (status_layer_update_before !== status_layer_update_after) {
      const fields =
        this.props.layer?.geo_layer_wo_geojson?.geo_layer?.fields || [];
      const field_list = fields.filter(
        (item) => !item.parent_uuid && !item.isStyle
      );
      this.setState({ field_list });
    }
    const fields =
      this.props.layer?.geo_layer_wo_geojson?.geo_layer?.fields || [];
    const prev_fields =
      prevProps?.layer?.geo_layer_wo_geojson?.geo_layer?.fields || [];
    if (
      this?.props?.layer?.geo_layer_wo_geojson?.geo_layer?._id !==
      prevProps?.layer?.geo_layer_wo_geojson?.geo_layer?._id
    ) {
      this.setState({
        geo_layer: this.props.layer.geo_layer_wo_geojson,
      });
      if (fields.length > prev_fields.length) {
        if (this.state.modal_sidebar) {
          this.close_nav();
        }
      }
    }
    // ketika sidebar di resize
    const new_width = this.state.sidebar_width;
    const old_width = prevState.sidebar_width;
    if (
      new_width !== old_width &&
      document.getElementById("container_document")
    ) {
      document.getElementById(
        "container_document"
      ).style.width = `calc(( 100% + ${this.state.sidebar_width * 1.3}px ))`;
    }
  };

  setSizebarWidth = (value) => {
    this.setState({
      sidebarWidth: value,
    });
  };

  setFieldList = (value) => {
    this.setState({
      field_list: value,
    });
  };

  setFromUuid = (value) => {
    this.setState({
      from_uuid: value,
    });
  };

  submit_delete_picture_field = (field) => {
    let { geo_layer } = this.state;
    geo_layer = geo_layer.geo_layer;
    delete field.picture_url;
    const body = {
      geo_layer_id: geo_layer._id,
      field,
    };
    this.props.editFieldForm(body);
  };

  submit_delete_limit_digit = (uuid) => {
    let { geo_layer, field } = this.state;
    geo_layer = geo_layer.geo_layer;
    let digit_term = field?.digit_term ? field.digit_term : [];
    digit_term = digit_term.filter((d) => d.uuid !== uuid);
    field.digit_term = digit_term;
    const body = {
      geo_layer_id: geo_layer._id,
      field,
    };
    this.props.editFieldForm(body);
  };

  toggle_is_hold = (value) => {
    this.setState({ is_hold: value });
  };

  toggle_term = (field) => {
    this.setState({
      modalTerm: !this.state.modalTerm,
    });
    if (!isEmpty(field)) {
      const { array_templates } = field;
      const min = field.min !== undefined ? field.min : 0;
      const max = field.max !== undefined ? field.max : 10;
      const step = field.step !== undefined ? field.step : 1;
      const tagArray = array_templates.map((value, idx) => {
        return value.name;
      });
      const tag = tagArray.join("\n");
      field.tag = tag;
      field.min = min;
      field.max = max;
      field.step = step;
      this.setState({ field });
    }
  };

  toggle_billing_form = () => {
    this.setState({ modalBillingForm: !this.state.modalBillingForm });
  };

  toggle_paid_form = () => {
    this.setState({ modalPaidForm: !this.state.modalPaidForm });
    const geo_layer_id = this.props.layer?.geo_layer_wo_geojson?.geo_layer?._id;
    const body = {
      geo_layer_id,
    };
    this.props.getBlogDetailForm(body);
  };

  handle_create_doc = () => {
    const geo_layer_id = this.props.layer?.geo_layer_wo_geojson?.geo_layer?._id;
    const geo_layer_name =
      this.props.layer?.geo_layer_wo_geojson?.geo_layer?.name;
    const { domain } = this.props.auth;
    const body = {
      geo_layer_id,
      title: geo_layer_name,
      layers: {
        geo_layer: geo_layer_id,
        date: Date.now(),
      },
      domain: { domain },
    };
    this.props.createFormDoc(body);
  };

  toggle_edit_general = () => {
    this.setState({ modal_edit_general: !this.state.modal_edit_general });
  };

  toggle_reorder = (from_uuid, modal_reorder_value) => {
    this.setState({
      modal_reorder: modal_reorder_value || !this.state.modal_reorder,
      from_uuid,
    });
  };

  run_submit_reorder_when_mouse_release = () => {
    // ketika reorder terjadi (untuk update ke database)
    const new_state_fields = this?.state?.field_list || [];
    if (new_state_fields.length > 0) {
      const fields_ori =
        this.props.layer?.geo_layer_wo_geojson?.geo_layer?.fields;
      const old_state_fields = fields_ori?.filter(
        (f) => !f.parent_uuid && !f.isStyle
      );
      const index = new_state_fields.findIndex(
        (field) => field.uuid === this.state.from_uuid
      );
      const to_uuid = old_state_fields[index].uuid;
      this.submit_reorder(to_uuid);
      this.setState({ modal_reorder: false });
    }
  };

  submit_reorder = (to_uuid) => {
    this.setState({ modal_reorder: !this.state.modal_reorder, to_uuid }, () => {
      let { from_uuid, to_uuid } = this.state;
      let geo_layer = this.props.layer.geo_layer_wo_geojson;
      let fields = geo_layer?.geo_layer?.fields || [];
      if (fields.length > 0) {
        const from = fields.findIndex((f) => f?.uuid === from_uuid);
        const to = fields.findIndex((f) => f?.uuid === to_uuid);
        fields.splice(to, 0, fields.splice(from, 1)[0]);
        geo_layer.geo_layer.fields = fields;
        this.setState({ geo_layer, to_uuid: "" }, () => {
          const body = {
            geo_layer_id: geo_layer.geo_layer._id,
            fields: fields,
          };
          this.props.editAllFields(body);
        });
      }
    });
  };

  toggle_push_in_the_middle = (uuid) => {
    this.setState({
      mode_middle: true,
      to_uuid: uuid,
      modal_push_field: !this.state.modal_push_field,
    });
  };

  toggle_delete_field = (field) => {
    this.setState(
      { modal_delete_field: !this.state.modal_delete_field },
      () => {
        if (!isEmpty(field)) {
          this.setState({ field });
        }
      }
    );
  };

  toggle_push_field = () => {
    this.setState({
      mode_middle: false,
      modal_push_field: !this.state.modal_push_field,
    });
  };

  //SideBar action
  toggle_sidebar = (field) => {
    if (!isEmpty(field) && field?.key !== this.state.field?.key) {
      const { array_templates } = field;
      const min = field.min !== undefined ? field.min : 0;
      const max = field.max !== undefined ? field.max : 10;
      const step = field.step !== undefined ? field.step : 1;
      const tagArray = array_templates.map((value, idx) => {
        return value.name;
      });
      const tag = tagArray.join("\n");
      field.tag = tag;
      field.min = min;
      field.max = max;
      field.step = step;
      this.open_nav(field);
    } else {
      this.setState(
        {
          modal_sidebar: !this.state.modal_sidebar,
        },
        () => {
          this.state.modal_sidebar ? this.open_nav(field) : this.close_nav();
        }
      );
    }
  };

  //Open sidebar
  open_nav = (field) => {
    this.setState({ modal_sidebar: true, field }, () => {
      if (document.getElementById("container_document")) {
        document.getElementById(
          "container_document"
        ).style.width = `calc(( 100% - ${this.state.sidebar_width}px ))`;
      }
      if (document.getElementById("container_document")) {
        document.getElementById(
          "container_document"
        ).style.marginLeft = `${this.state.sidebar_width}px`;
      }
    });
  };

  //Close sidebar
  close_nav = () => {
    this.setState({ modal_sidebar: false, field: {} }, () => {
      if (document.getElementById("container_document")) {
        document.getElementById("container_document").style.width = "100%";
      }
      if (document.getElementById("container_document")) {
        document.getElementById("container_document").style.marginLeft = "0px";
      }
    });
  };

  handle_delete_field = () => {
    this.setState({ modal_delete_field: false }, async () => {
      const { geo_layer_wo_geojson } = this.props.layer;
      const { field } = this.state;
      const field_key = field.key;
      const body = {
        geo_layer_id: geo_layer_wo_geojson?.geo_layer?._id,
        field_key,
      };
      await this.props.deleteFieldForm(body);
      this.setFieldList(
        this.props.layer?.geo_layer_wo_geojson?.geo_layer?.fields?.filter(
          (f) => !f.parent_uuid && !f.isStyle
        )
      );
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    let {
      modal_sidebar,
      field,
      modal_push_field,
      modal_delete_field,
      modal_reorder,
      modal_edit_general,
      modalTerm,
      from_uuid,
      field_list,
      is_hold,
    } = this.state;

    //global props
    const { domain } = this.props.auth;
    const { loadingProcess, itemLoading } = this.props.layer;

    let geo_layer = this.props.layer.geo_layer_wo_geojson;
    geo_layer = geo_layer?.geo_layer;
    // let ori_field_list = geo_layer?.fields || [];

    // perlu revisi [sementara semua fields diperlihatkan]
    // harusnya:
    //    child, read header/fieldnya child
    //    parent, read header/fieldnya parent
    // field_list = field_list.filter((item) => !item.isStyle);

    // originalnya
    // field_list = field_list.filter(
    //   (item) => !item.parent_uuid && !item.isStyle
    // );

    // const original_fields = (field_list = field_list.filter(
    //   (item) => !item.isStyle
    // ));

    //content
    let modal_push_field_content,
      modal_delete_fieldContent,
      modal_edit_generalContent,
      modalPaidFormContent,
      modalBillingFormContent,
      modalTermContent,
      sidebar_content;

    const domain_temp = domain_list?.[domain]?.form_link;

    // let fields =
    //   this?.props?.layer?.geo_layer_wo_geojson?.geo_layer?.fields || [];
    // fields = fields.filter((f) => !f.parent_uuid && !f.isStyle)
    // .filter((item) => !item.isStyle && !item.parent_uuid);

    // const fields_parent = fields.filter((f) => !f.parent_uuid);

    let final_content = (
      <main style={{ textAlign: "center", marginTop: "100px" }}>
        <section>
          <img
            src={icon_404}
            alt="Viewer"
            style={{ width: "200px", maxWidth: "70%", marginBottom: "20px" }}
          />
        </section>
        <section style={{ fontSize: "15px" }}>
          {dict?.["Loading GIS data"]?.[language]}...
        </section>
      </main>
    );

    if (loadingProcess && itemLoading === "loading_layer_wo_geojson") {
      final_content = (
        <main style={{ textAlign: "center", marginTop: "100px" }}>
          <section>
            <img
              src={icon_404}
              alt="Viewer"
              style={{ width: "200px", maxWidth: "70%", marginBottom: "20px" }}
            />
          </section>
          <section style={{ fontSize: "15px" }}>
            {dict?.["Loading GIS data"]?.[language]}...
          </section>
        </main>
      );
    } else {
      let item_content;

      if (!isEmpty(geo_layer)) {
        // const style_length = field_list.length;
        item_content = (
          <Reorder.Group
            axis="y"
            values={field_list}
            onReorder={this.setFieldList}
          >
            {field_list.map((field_element, idx) => {
              return (
                // <div
                //   key={idx}
                //   value={field_element}
                //   style={{
                //     listStyleType: "none",
                //   }}
                // >
                //   <AddButtonBetweenList
                //     idx={idx}
                //     field_element={field_element}
                //     toggle_push_in_the_middle={this.toggle_push_in_the_middle}
                //   />
                <FieldCard
                  key={field_element.uuid}
                  idx={idx}
                  field_element={field_element}
                  fields={field_list}
                  field={field}
                  from_uuid={from_uuid}
                  modal_reorder={modal_reorder}
                  language={language}
                  // style_length={style_length}
                  toggle_delete_field={this.toggle_delete_field}
                  toggle_reorder={this.toggle_reorder}
                  submit_reorder={this.submit_reorder}
                  toggle_term={this.toggle_term}
                  toggle_sidebar={this.toggle_sidebar}
                  submit_delete_picture_field={this.submit_delete_picture_field}
                  setFromUuid={this.setFromUuid}
                  toggle_push_in_the_middle={this.toggle_push_in_the_middle}
                  run_submit_reorder_when_mouse_release={
                    this.run_submit_reorder_when_mouse_release
                  }
                  toggle_is_hold={this.toggle_is_hold}
                  is_hold={is_hold}
                />
                // </div>
              );
            })}
          </Reorder.Group>
        );
      }

      sidebar_content = (
        <SidebarForm headers={field_list} field={{ ...field }} />
      );

      let loadingText;
      switch (itemLoading) {
        case "editField":
          loadingText = dict["Saving changes on a question"][language];
          break;
        case "upload_photo_form":
          loadingText = dict["Uploading banner"][language];
          break;
        case "loading_layer_wo_geojson":
          loadingText = dict["Load the data"][language];
          break;
        case "deleteFieldForm":
          loadingText = "Deleting question";
          break;
        default:
          loadingText = dict["Saving changes on a question"][language];
          break;
      }

      modalTermContent = modalTerm && (
        <Modal
          modalSize="medium"
          id="modal"
          isOpen={modalTerm}
          onClose={this.toggle_term}
        >
          <main className="box-body" style={{ minHeight: "75vh" }}>
            <EditTerm field={{ ...field }} fields={[...field_list]} />
          </main>
        </Modal>
      );

      modal_push_field_content = modal_push_field && (
        <Modal
          modalSize="medium"
          id="modal"
          isOpen={modal_push_field}
          onClose={this.toggle_push_field}
        >
          <div className="box-body">
            <PushFieldForm
              mode_middle={this.state.mode_middle}
              to_uuid={this.state.to_uuid}
              setFieldList={this.setFieldList}
              fields_parent={field_list}
            />
          </div>
        </Modal>
      );

      modal_delete_fieldContent = modal_delete_field && (
        <Modal
          modalSize="small"
          id="modal"
          isOpen={modal_delete_field}
          onClose={this.toggle_delete_field}
        >
          <div className="box-body" style={{ textAlign: "center" }}>
            <div className="text_bold">
              {dict["Are you sure to delete"][language]} {field.name}
            </div>
            <div
              onClick={this.handle_delete_field}
              className="button_standard"
              id="deleteBright"
            >
              {dict["Delete"][language]}
            </div>
          </div>
        </Modal>
      );

      modal_edit_generalContent = modal_edit_general && (
        <Modal
          modalSize="large"
          id="modal"
          isOpen={modal_edit_general}
          onClose={this.toggle_edit_general}
        >
          <main className="box-body" style={{ textAlign: "center" }}>
            <EditGeneral
              geo_layer={geo_layer}
              toggle_edit_general={this.toggle_edit_general}
            />
          </main>
        </Modal>
      );

      const loading_content = loadingProcess && (
        <section
          style={{
            position: "fixed",
            top: "0px",
            width: "100%",
            height: "20px",
            zIndex: "9",
            backgroundColor: "#2196f3",
            color: "#fff",
            textAlign: "center",
          }}
        >
          {loadingText}
        </section>
      );

      const add_button = (
        <main
          onClick={this.toggle_push_field}
          id="blue"
          style={{
            position: "sticky",
            width: "85px",
            bottom: "25px",
            margin: "auto",
            borderRadius: "10px",
            cursor: "pointer",
            textAlign: "center",
            padding: "7px",
          }}
        >
          <section
            style={{
              fontSize: "30px",
            }}
          >
            +
          </section>
          <section className="text_inferior" id="blue">
            {dict["Add Question"][language]}
          </section>
        </main>
      );

      final_content = (
        <main>
          <HomeButton />
          <main
            id="container_document"
            style={{
              transition: "all 0.15s ease-in-out",
              marginTop: "55px",
            }}
          >
            <main className="container_document">
              <UploadBanner />
              <div
                className="container_flat"
                style={{
                  backgroundColor: "#fff",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <div className="text_header">
                  {geo_layer?.name
                    ? geo_layer?.name
                    : dict["Loading"][language]}{" "}
                  <img
                    onClick={this.toggle_edit_general}
                    style={{ cursor: "pointer" }}
                    src={icon_edit_text}
                    alt="Edit general"
                    width="15px"
                    height="auto"
                  />
                </div>
                <div style={{ whiteSpace: "pre-wrap" }}>
                  {geo_layer?.description
                    ? geo_layer?.description
                    : "Form Description"}
                </div>
                <div
                  onClick={this.handle_create_doc}
                  className="button_standard"
                  style={{ marginRight: "10px", marginTop: "10px" }}
                >
                  {dict["Add survey documentation"][language]}
                </div>
                {/* {paidFormStatus === "not_propose" ||
                paidFormStatus === undefined ? (
                  <div
                    onClick={this.toggle_paid_form}
                    className="button_standard"
                    style={{ marginRight: "10px", marginTop: "10px" }}
                  >
                    {dict["Apply for paid survey"][language]}
                  </div>
                ) : (
                  <Link
                    to={`/paid_form/${geo_layer.link}`}
                    className="button_standard"
                    style={{ marginRight: "10px", marginTop: "10px" }}
                  >
                    {dict["Paid Form Dashboard"][language]}
                  </Link>
                )} */}
                <a
                  href={`${domain_temp}${geo_layer?.link}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button_standard"
                  style={{ marginRight: "10px", marginTop: "10px" }}
                >
                  {dict["Link"][language]}
                </a>
              </div>
              <div
                className="container_flat"
                style={{
                  backgroundColor: "#fff",
                  marginTop: "10px",
                  marginBottom: "10px",
                  padding: "30px",
                }}
              >
                <UploadSquare />
              </div>
              <div style={{ paddingBottom: "200px" }}>{item_content}</div>
              {add_button}
            </main>
          </main>
          {loading_content}
        </main>
      );
    }

    return (
      <main
        onMouseUp={() => {
          if (is_hold) {
            this.run_submit_reorder_when_mouse_release();
            this.toggle_is_hold(false);
          }
        }}
      >
        {modal_push_field_content}
        {modal_delete_fieldContent}
        {modal_edit_generalContent}
        {modalPaidFormContent}
        {modalBillingFormContent}
        {modalTermContent}
        {modal_sidebar && (
          <Resizable
            style={{
              zIndex: 1,
              position: "fixed",
              left: 0,
              top: 0,
              padding: "20px 0 20px 20px",
              backgroundColor: "#fff",
              border: "1px solid",
            }}
            defaultSize={{
              width: 350,
            }}
            onResizeStop={(e, direction, ref, d) => {
              this.setState({
                sidebar_width: this.state.sidebar_width + d.width,
              });
            }}
            maxHeight="100%"
            minHeight="100%"
            minWidth="280px"
            maxWidth="100vw"
          >
            <div
              onClick={() => this.toggle_sidebar()}
              className="floating_close_button_sidebar_form_edit noselect pointer hover_bigger"
            >
              x
            </div>
            <div className="scroll_container_sidebar paddingRight_10">
              {sidebar_content}
            </div>
          </Resizable>
        )}
        {final_content}
        <Helmet>
          <title>
            {geo_layer?.name ? geo_layer?.name : dict["Loading"][language]}
          </title>
          <meta
            name="description"
            content={
              geo_layer?.name ? geo_layer?.name : dict["Loading"][language]
            }
          />
        </Helmet>
        <style>
          {`
        body{
          padding-top: 0px;
          overflow-y: auto;
        }
        .navbarDefault{ visibility: hidden;}
        #top_nav{ visibility: hidden;}
        #sidebar_home{ visibility: hidden;}
        .nav_bottom{ visibility: hidden;}
        `}
        </style>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  blog: state.blog,
});

export default connect(mapStateToProps, {
  getDetailLayerByLinkWOgeojson,
  editFieldForm,
  deleteFieldForm,
  editAllFields,
  createFormDoc,
  getBlogDetailForm,
})(CreateForm);
