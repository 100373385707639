/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*COMPONENT*/

/*REDUX*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE*/

/*FUNCTION*/
import { generate_paint } from "../../Functions/map_style/generate_paint";
import { extract_value_array } from "../../Functions/map_style/extract_value_array";

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class RANGE_TEXT extends Component {
  state = {
    anchor: null,
    modal_add_item: false,
    item: "",
    color: "#000000",
    index_edit: 0,
    color_edit: "#000000",
    modal_color_edit: false,
  };

  on_change = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  toggle_color_edit = (e) => {
    this.setState({ modal_color_edit: !this.state.modal_color_edit });
    if (e) {
      this.setState({ anchor: e?.currentTarget });
    }
    const index_edit = e?.target?.id;
    const color_edit = e?.target?.dataset?.color;
    if (color_edit) {
      this.setState({ index_edit, color_edit });
    }
  };

  on_color_edit = () => {
    this.setState({ modal_color_edit: false });
    const { paint_object_edited, style_parameter } = this.props.properties;
    const { color_logic, style_type } = style_parameter;
    if (color_logic === "by_text") {
      let color_key;
      if (style_type === "circle") {
        color_key = "circle-color";
      } else if (style_type === "line") {
        color_key = "line-color";
      } else if (style_type === "fill") {
        color_key = "fill-color";
      }
      let array = paint_object_edited?.[color_key] || [];
      if (
        array.length >= 2 &&
        array[0] === "match" &&
        Array.isArray(array[1])
      ) {
        const { index_edit, color_edit } = this.state;
        array[Number(index_edit) + 1] = color_edit;
        paint_object_edited[color_key] = array;
        this.props.set_value_properties({
          key: "paint_object_edited",
          value: paint_object_edited,
        });
        this.props.set_value_properties({
          key: "style_parameter",
          value: {
            ...style_parameter,
            paint_object_edited,
          },
        });
        this.props.status_action();
      }
    }
  };

  toggle_add_item = (e) => {
    this.setState({ modal_add_item: !this.state.modal_add_item });
    if (e) {
      this.setState({ anchor: e?.currentTarget });
    }
  };

  on_add_item = () => {
    this.setState({ modal_add_item: false });
    const { item, color } = this.state;
    const { paint_object_edited, style_parameter } = this.props.properties;
    const { color_logic, style_type } = style_parameter;
    if (color_logic === "by_text") {
      let color_key;
      if (style_type === "circle") {
        color_key = "circle-color";
      } else if (style_type === "line") {
        color_key = "line-color";
      } else if (style_type === "fill") {
        color_key = "fill-color";
      }
      let array = paint_object_edited?.[color_key] || [];
      if (
        array.length >= 2 &&
        array[0] === "match" &&
        Array.isArray(array[1])
      ) {
        const item_index = array.indexOf(item);
        if (item_index > -1 && item_index < array.length - 1) return;
        const default_color = array[array.length - 1];
        array.pop();
        array.push(item, color);
        array.push(default_color);
        paint_object_edited[color_key] = array;
        this.props.set_value_properties({
          key: "paint_object_edited",
          value: paint_object_edited,
        });
        this.props.set_value_properties({
          key: "style_parameter",
          value: {
            ...style_parameter,
            paint_object_edited,
          },
        });
        this.props.status_action();
      }
    }
  };

  on_move_up = (item) => {
    const { paint_object_edited, style_parameter } = this.props.properties;
    const { color_logic, style_type } = style_parameter;
    if (color_logic === "by_text") {
      let color_key;
      if (style_type === "circle") {
        color_key = "circle-color";
      } else if (style_type === "line") {
        color_key = "line-color";
      } else if (style_type === "fill") {
        color_key = "fill-color";
      }
      const array = paint_object_edited?.[color_key] || [];
      const index = array.indexOf(item);
      if (index > 2 && index % 2 === 0) {
        const temp = array[index - 2];
        array[index - 2] = array[index];
        array[index] = temp;
        paint_object_edited[color_key] = array;
        const value_array = extract_value_array(
          paint_object_edited,
          style_type
        );
        const style_parameter_new = {
          ...style_parameter,
          paint_object_edited,
          value_array,
        };
        const paint_object_new = generate_paint(style_parameter_new);
        this.props.set_value_properties({
          key: "paint_object_edited",
          value: paint_object_new,
        });
        this.props.set_value_properties({
          key: "style_parameter",
          value: style_parameter_new,
        });
        this.props.status_action();
      }
    }
  };

  on_move_down = (item) => {
    const { paint_object_edited, style_parameter } = this.props.properties;
    const { color_logic, style_type } = style_parameter;
    if (color_logic === "by_text") {
      let color_key;
      if (style_type === "circle") {
        color_key = "circle-color";
      } else if (style_type === "line") {
        color_key = "line-color";
      } else if (style_type === "fill") {
        color_key = "fill-color";
      }
      const array = paint_object_edited?.[color_key] || [];
      const index = array.indexOf(item);
      if (index >= 2 && index % 2 === 0 && index < array.length - 3) {
        const temp = array[index + 2];
        array[index + 2] = array[index];
        array[index] = temp;
        paint_object_edited[color_key] = array;
        const value_array = extract_value_array(
          paint_object_edited,
          style_type
        );
        const style_parameter_new = {
          ...style_parameter,
          value_array,
          paint_object_edited,
        };
        const paint_object_new = generate_paint(style_parameter_new);
        this.props.set_value_properties({
          key: "paint_object_edited",
          value: paint_object_new,
        });
        this.props.set_value_properties({
          key: "style_parameter",
          value: style_parameter_new,
        });
        this.props.status_action();
      }
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const {
      modal_add_item,
      modal_color_edit,
      anchor,
      item,
      color,
      color_edit,
    } = this.state;

    //global props
    const { style_parameter, paint_object_edited } = this.props.properties;

    //content
    const { style_type } = style_parameter;
    let color_key;
    if (style_type === "circle") {
      color_key = "circle-color";
    } else if (style_type === "line") {
      color_key = "line-color";
    } else if (style_type === "fill") {
      color_key = "fill-color";
    }

    let array = paint_object_edited?.[color_key] || [];
    const content = (
      <>
        <main style={{ maxHeight: "250px", overflowY: "scroll" }}>
          <table className="table full_width">
            <tbody>
              {array.map((item, idx) => {
                // Skip the first two elements (["get", field_key])
                if (idx < 2) return null;
                const is_first = idx === 2; // First valid text element position
                const is_last = idx >= array.length - 4; // Last valid text element position
                if (idx % 2 === 0 && idx < array.length - 1) {
                  return (
                    <tr key={idx}>
                      <td
                        style={{ width: "25px", verticalAlign: "middle" }}
                        className="text_center"
                      >
                        <div>
                          {!is_first && (
                            <button
                              className={`button_icon background_black ${
                                !is_last && "margin_bottom"
                              }`}
                              onClick={this.on_move_up.bind(this, item)}
                            >
                              ↑
                            </button>
                          )}
                          {!is_last && (
                            <button
                              className="button_icon background_black"
                              onClick={this.on_move_down.bind(this, item)}
                            >
                              ↓
                            </button>
                          )}
                        </div>
                      </td>
                      <td>{item}</td>
                      <td style={{ width: "20px", verticalAlign: "middle" }}>
                        <div
                          className="cursor_pointer"
                          id={idx}
                          data-color={array[idx + 1]}
                          onClick={this.toggle_color_edit}
                          style={{
                            backgroundColor: array[idx + 1],
                            width: "20px",
                            height: "20px",
                            border: "1px solid black",
                          }}
                        />
                      </td>
                    </tr>
                  );
                } else {
                  return null;
                }
              })}
            </tbody>
          </table>
        </main>
        <button
          className="button margin_top background_black"
          onClick={this.toggle_add_item}
        >
          {dict?.["Add"]?.[language]}
        </button>
      </>
    );

    const modal_add_item_content = modal_add_item && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_add_item}
        onClose={this.toggle_add_item}
      >
        <div
          style={{
            padding: "0px 10px 0px 10px",
          }}
        >
          <p className="text_small">Nama</p>
          <input
            placeholder="Ketik nama"
            type="text"
            value={item}
            name="item"
            onChange={this.on_change}
          />
          <br />
          <p className="text_small">Warna</p>
          <input
            type="color"
            value={color}
            name="color"
            onChange={this.on_change}
          />
          <br />
          <p className="text_small">Warna (manual)</p>
          <input
            type="text"
            value={color}
            name="color"
            onChange={this.on_change}
          />
          <br />
          <button
            className="button margin_top background_black"
            onClick={this.on_add_item}
          >
            {dict?.["Add"]?.[language]}
          </button>
        </div>
      </Menu>
    );

    const modal_color_edit_content = modal_color_edit && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_color_edit}
        onClose={this.toggle_color_edit}
      >
        <div
          style={{
            padding: "0px 10px 0px 10px",
          }}
        >
          <p className="text_small">Warna</p>
          <input
            type="color"
            value={color_edit}
            name="color_edit"
            onChange={this.on_change}
          />
          <br />
          <p className="text_small">Warna (manual)</p>
          <input
            type="text"
            value={color_edit}
            name="color_edit"
            onChange={this.on_change}
          />
          <br />
          <button
            className="button margin_top background_black"
            onClick={this.on_color_edit}
          >
            Simpan perubahan
          </button>
        </div>
      </Menu>
    );

    return (
      <main>
        {content}
        {modal_add_item_content}
        {modal_color_edit_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
})(RANGE_TEXT);
