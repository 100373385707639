/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
// import {
//   get_list_kota_by_search,
//   get_list_kecamatan_by_search,
//   get_list_kelurahan_by_search,
//   get_list_all_provinsi,
//   get_list_all_kota_by_provinsi,
//   get_list_all_kecamatan_by_kota,
//   get_list_all_kelurahan_by_kecamatan,
// } from "../../App/actions/insightActions";

import {
  set_value_insight,
  insight_search_admin,
  insight_hirarki_admin,
} from "../../App/actions/insight_v2";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/
const WAIT_INTERVAL = 1000;
const ENTER_KEY = 13;

class InputAreaSingle extends Component {
  state = {
    text_input: "",
    typing_status: false,
    kota_list_filtered: [],
    kecamatan_list_filtered: [],
    kelurahan_list_filtered: [],
    level: "",
  };

  on_click_adm = (object_adm, level) => {
    this.setState({ typing_status: false, level });

    const PROVINSI = object_adm?.["PROVINSI"];
    const KABKOT = object_adm?.["KABKOT"];
    const KECAMATAN = object_adm?.["KECAMATAN"];
    const DESA = object_adm?.["DESA"];

    if (level === "kota") {
      this.props.set_value_insight({
        key: "provinsi",
        value: PROVINSI,
      });
      this.props.set_value_insight({
        key: "kota",
        value: KABKOT,
      });
      this.props.set_value_insight({
        key: "kecamatan",
        value: "",
      });
      this.props.set_value_insight({
        key: "kelurahan",
        value: "",
      });

      //get hirarki provinsi
      this.props.insight_hirarki_admin({
        ...object_adm,
        level: "provinsi",
      });
      //get hirarki kota
      this.props.insight_hirarki_admin({
        ...object_adm,
        level: "kota",
      });
      //get hirarki kecamatan
      this.props.insight_hirarki_admin({
        ...object_adm,
        level: "kecamatan",
      });
    } else if (level === "kecamatan") {
      this.props.set_value_insight({
        key: "provinsi",
        value: PROVINSI,
      });
      this.props.set_value_insight({
        key: "kota",
        value: KABKOT,
      });
      this.props.set_value_insight({
        key: "kecamatan",
        value: KECAMATAN,
      });
      this.props.set_value_insight({
        key: "kelurahan",
        value: "",
      });

      //get hirarki provinsi
      this.props.insight_hirarki_admin({
        ...object_adm,
        level: "provinsi",
      });
      //get hirarki kota
      this.props.insight_hirarki_admin({
        ...object_adm,
        level: "kota",
      });
      //get hirarki kecamatan
      this.props.insight_hirarki_admin({
        ...object_adm,
        level: "kecamatan",
      });
      //get hirarki kelurahan
      this.props.insight_hirarki_admin({
        ...object_adm,
        level: "kelurahan",
      });
    } else if (level === "kelurahan") {
      this.props.set_value_insight({
        key: "provinsi",
        value: PROVINSI,
      });
      this.props.set_value_insight({
        key: "kota",
        value: KABKOT,
      });
      this.props.set_value_insight({
        key: "kecamatan",
        value: KECAMATAN,
      });
      this.props.set_value_insight({
        key: "kelurahan",
        value: DESA,
      });

      //get hirarki provinsi
      this.props.insight_hirarki_admin({
        ...object_adm,
        level: "provinsi",
      });
      //get hirarki kota
      this.props.insight_hirarki_admin({
        ...object_adm,
        level: "kota",
      });
      //get hirarki kecamatan
      this.props.insight_hirarki_admin({
        ...object_adm,
        level: "kecamatan",
      });
      //get hirarki kelurahan
      this.props.insight_hirarki_admin({
        ...object_adm,
        level: "kelurahan",
      });
    }
  };

  on_change_single_input(e) {
    e.preventDefault();
    clearTimeout(this.timer);
    this.setState({
      [e.target.name]: e.target.value,
      typing_status: true,
    });
    if (!e.target.value) {
      this.setState({
        kota_list_filtered: [],
        kecamatan_list_filtered: [],
        kelurahan_list_filtered: [],
        typing_status: false,
      });
    }
    this.timer = setTimeout(this.trigger_action.bind(this), WAIT_INTERVAL);
  }

  handle_key_down(e) {
    if (e.keyCode === ENTER_KEY) {
      this.trigger_action.bind(this);
    }
  }

  trigger_action() {
    try {
      const { text_input } = this.state;
      if (text_input.length > 3) {
        const params = {
          search: text_input,
        };
        this.props.insight_search_admin(params);
      }
    } catch (error) {}
  }

  render() {
    //local storage

    //local state
    const { level, text_input, typing_status } = this.state;

    //global props
    const {
      kelurahan_list_by_search,
      kecamatan_list_by_search,
      kota_list_by_search,
      provinsi,
      kota,
      kecamatan,
      kelurahan,
    } = this.props.insight_reducer;

    //content
    return (
      <main>
        <p className="text_bold">Cari wilayah administrasi</p>
        <input
          type="text"
          placeholder="Misal: Bandung, Tebet, Sukamenak"
          autoComplete="off"
          name="text_input"
          value={text_input}
          onChange={this.on_change_single_input.bind(this)}
          onKeyDown={this.handle_key_down.bind(this)}
          data-mapid="insertWilayah"
        />
        {typing_status && (
          <section
            className="container background_white"
            style={{ overflowY: "scroll", maxHeight: "300px" }}
          >
            {kota_list_by_search.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="cursor_pointer"
                  onClick={this.on_click_adm.bind(this, item, "kota")}
                >
                  {item?.["KABKOT"]}{" "}
                  <span className="badge_small background_teal">
                    {item?.["KABKOT"]?.includes("KOTA ") ? "KOTA" : "KAB"}
                  </span>
                  <hr />
                </div>
              );
            })}
            {kecamatan_list_by_search.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="cursor_pointer"
                  onClick={this.on_click_adm.bind(this, item, "kecamatan")}
                  data-mapid={`clickWilayah-${idx}`}
                >
                  {item?.["KECAMATAN"]}, {item?.["KABKOT"]}{" "}
                  <span className="badge_small background_teal">KEC</span>
                  <hr />
                </div>
              );
            })}
            {kelurahan_list_by_search.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="cursor_pointer"
                  onClick={this.on_click_adm.bind(this, item, "kelurahan")}
                >
                  {item?.["DESA"]}, {item?.["KECAMATAN"]}, {item?.["KABKOT"]}{" "}
                  <span className="badge_small background_teal">DESA</span>{" "}
                  <hr />
                </div>
              );
            })}
          </section>
        )}
        {level && (
          <section className="container background_white margin_top">
            {level === "provinsi" ? (
              <>
                {provinsi}{" "}
                <span className="badge_small background_teal">PROV</span>
              </>
            ) : level === "kota" ? (
              <>
                {kota}{" "}
                <span className="badge_small background_teal">
                  {kota?.includes("KOTA ") ? "KOTA" : "KAB"}
                </span>
                ,<br />
                {provinsi}{" "}
                <span className="badge_small background_teal">PROV</span>
              </>
            ) : level === "kecamatan" ? (
              <>
                {kecamatan}{" "}
                <span className="badge_small background_teal">KEC</span>,<br />
                {kota}{" "}
                <span className="badge_small background_teal">
                  {kota?.includes("KOTA ") ? "KOTA" : "KAB"}
                </span>
                ,<br />
                {provinsi}{" "}
                <span className="badge_small background_teal">PROV</span>
              </>
            ) : level === "kelurahan" ? (
              <>
                {kelurahan}{" "}
                <span className="badge_small background_teal">KEL</span>,<br />
                {kecamatan}{" "}
                <span className="badge_small background_teal">KEC</span>,<br />
                {kota}{" "}
                <span className="badge_small background_teal">
                  {kota?.includes("KOTA ") ? "KOTA" : "KAB"}
                </span>
                ,<br />
                {provinsi}{" "}
                <span className="badge_small background_teal">PROV</span>
              </>
            ) : (
              <></>
            )}
          </section>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  map: state.map,
  properties: state.properties,
  insight_reducer: state.insight_reducer,
});

export default connect(mapStateToProps, {
  // get_list_kota_by_search,
  // get_list_kecamatan_by_search,
  // get_list_kelurahan_by_search,
  // get_list_all_provinsi,
  // get_list_all_kota_by_provinsi,
  // get_list_all_kecamatan_by_kota,
  // get_list_all_kelurahan_by_kecamatan,
  //new
  set_value_insight,
  insight_search_admin,
  insight_hirarki_admin,
})(InputAreaSingle);
