import React from "react";

const ICON_ARROW_DOWN = (props) => {
  return (
    <svg
      className="center_child"
      xmlns="http://www.w3.org/2000/svg"
      width={7.5}
      height={9.5}
      viewBox="0 0 1.984 2.514"
    >
      <path
        d="M.992 2.315V.198M.198 1.528l.794.787.794-.787"
        style={{
          fill: "none",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 0.39688,
          stroke: props.line_color || "#000",
        }}
      />
    </svg>
  );
};

export default ICON_ARROW_DOWN;
