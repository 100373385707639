import React from "react";

const ICON_ARROW_LEFT_LINE = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={11.138}
      height={9}
      viewBox="0 0 2.947 2.381"
    >
      <path
        d="M2.688 1.191H.27m.948-.926-.944.926.944.926"
        style={{
          fill: "none",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 0.52917,
          stroke: props.line_color || "#fff",
        }}
      />
    </svg>
  );
};

export default ICON_ARROW_LEFT_LINE;
