/* Library */
import React, { Component, createRef } from "react";
import * as ExcelJS from "exceljs";
import * as XLSX from "xlsx";
import { connect } from "react-redux";

/* Component */
import Modal from "../common_modal/Modal";
import RefSelection from "./RefSelection";
import SelectTableContent from "./SelectTableContent";

/* Action */
import {
  create_sheet,
  delete_cells,
  edit_cell,
  get_sheets_by_cell,
} from "../../App/actions/sheetAction";
import { snackbar } from "../../App/actions/snackbarActions";
import { set_value_layer } from "../../App/actions/layerActions";

/* Helper function */
import { getGeoLayer } from "../../App/reduxHelper/layer";
import { get_file } from "../../App/validation/handle_file";
import { applyTint } from "../../App/validation/colorHex";
import { numberToColumn } from "../../App/validation/calculateExcelFormula";
import { CircularProgress } from "@mui/material";

class ExcelTable extends Component {
  constructor(props) {
    super(props);
    this.hotTableRef = createRef(null);
    this.state = {
      modal_upload: false,
      uploaded_file: {},
      worksheet: null,
      sheetnames: [],
      filename: "",
      selectedSheet: "",
      selectedCell: null,
      selectedCells: null,
      isSingleSelected: true,
      focused: null,
      modal_ref_selection: false,
      is_loading: false,
      isDeleting: false,
    };
  }

  toggle_modal = () => {
    const { modal_upload } = this.state;
    this.setState(
      {
        is_loading: true,
        modal_upload: !modal_upload,
      },
      async () => {
        const { geo_layer_id, feature_key, field_key, feature } = this.props;
        const body = {
          geo_layer_id,
          feature_key,
          field_key,
        };

        this.props.set_value_layer({
          key: "feature_object_selected",
          value: feature,
        });

        if (!modal_upload) {
          const data = await this.props.get_sheets_by_cell(body);
          const sheetnames = data
            ?.map((item) => ({ _id: item?._id, ...item?.metadata }))
            ?.sort((item) => item?.order);
          this.setState({
            worksheet: data?.length > 0 ? data : null,
            sheetnames: sheetnames,
            selectedSheet: sheetnames?.[0]?.id_ori || "",
            is_loading: false,
          });
        } else {
          this.setState({
            worksheet: null,
            sheetnames: [],
            selectedSheet: "",
            uploaded_file: {},
            filename: "",
            selectedCell: null,
            is_loading: false,
          });
        }
      }
    );
  };

  toggle_modal_ref_selection = () => {
    const { modal_ref_selection } = this.state;
    this.setState({
      modal_ref_selection: !modal_ref_selection,
    });
  };

  // Fungsi untuk mengambil data dan gaya dari ExcelJS
  getExcelDataWithStyles = async (buffer) => {
    const { feature_key, field_key, geo_layer_id } = this.props;
    const rawWorkbook = XLSX.read(buffer, { type: "array" });
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(buffer);

    const sheetObjects = [];
    const sheetnames = [];

    workbook.eachSheet((sheet, idx) => {
      const rawSheet = rawWorkbook.Sheets[sheet.name];
      sheetnames.push(sheet.name);

      const sheetData = {
        feature_key,
        field_key,
        geo_layer_id,
        metadata: {
          name: sheet.name,
          id_ori: sheet.name,
          order: idx - 1,
        },
        value: [],
      };

      sheet.eachRow((row) => {
        row.eachCell((cell) => {
          const position = cell.address;
          const rawCell = rawSheet[position] || {};
          const style = cell.style || {};

          // Extract fill color
          let fillColor = null;
          const fgColor = style.fill?.fgColor;
          if (fgColor?.theme !== undefined) {
            fillColor = applyTint(fgColor.theme, fgColor.tint);
          } else if (fgColor?.argb) {
            fillColor = `#${fgColor.argb.slice(
              fgColor.argb.length > 6 ? 2 : 0
            )}`;
          }

          // Decoration object
          const decoration = {
            fontColor: style.font?.color
              ? applyTint(style.font.color.theme, style.font.color.tint)
              : "#000",
            fontSize: style.font?.size || null,
            fontName: style.font?.name || null,
            bold: !!style.font?.bold,
            italic: !!style.font?.italic,
            underline: !!style.font?.underline,
            fillColor,
            border: {
              top: style.border?.top?.style || null,
              right: style.border?.right?.style || null,
              bottom: style.border?.bottom?.style || null,
              left: style.border?.left?.style || null,
            },
            decimal: style.numFmt || null,
          };

          const body = {
            p: position,
            d: decoration,
          };

          // Add dropdown options if available
          if (cell.dataValidation?.type === "list") {
            let dropdownOptions = cell.dataValidation.formulae?.[0];
            const match = dropdownOptions.match(/"(?:[^"\\]|\\.)*"/gm);
            if (match) {
              body.o = dropdownOptions.slice(1, -1).split(",");
            } else if (dropdownOptions) {
              body.o = dropdownOptions;
            }
          }

          // Add raw cell properties
          const rawKeys = [
            "v",
            "w",
            "t",
            "f",
            "F",
            "D",
            "r",
            "h",
            "c",
            "z",
            "l",
            "s",
          ];
          rawKeys.forEach((key) => {
            if (rawCell[key] !== undefined) {
              body[key] = rawCell[key];
            }
          });

          sheetData.value.push(body);
        });
      });

      sheetObjects.push(sheetData);
    });

    // Simpan sheetObjects ke database
    await Promise.all(
      sheetObjects.map(async (sheetData) => {
        const _id = await this.props.create_sheet(sheetData);
        sheetData._id = _id;
      })
    );

    return { sheetObjects, sheetnames };
  };

  define_need_to_show = async (e) => {
    const { feature_key, field_key, geo_layer_id } = this.props;
    const file = get_file(e);
    const buffer = await file.arrayBuffer();

    await this.getExcelDataWithStyles(buffer);

    const body = {
      geo_layer_id,
      feature_key,
      field_key,
    };

    const data = await this.props.get_sheets_by_cell(body);
    const sheetnames = data
      ?.map((item) => ({ _id: item?._id, ...item?.metadata }))
      .sort((item) => item?.order);
    this.setState({
      worksheet: data?.length > 0 ? data : null,
      sheetnames: sheetnames,
      selectedSheet: sheetnames?.[0]?.id_ori || "",
      filename: file.name,
    });
  };

  setSelectedSheet = (value) => {
    this.setState({
      selectedSheet: value,
    });
  };

  setSheetnames = (values) => {
    this.setState({
      sheetnames: values,
    });
  };

  setWorksheet = (value) => {
    this.setState({
      worksheet: value,
    });
  };

  setFocused = (value) => {
    this.setState({
      focused: value,
    });
  };

  on_dowload_json = () => {
    const { worksheet, filename } = this.state;
    const json_text = JSON.stringify(worksheet);
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(json_text)
    );
    element.setAttribute("download", `${filename?.replaceAll(".xlsx")}.json`);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  handle_selected_cell = ({ startRow, startCol, endRow, endCol }) => {
    const { worksheet, selectedSheet } = this.state;
    if (startRow === endRow && startCol === endCol) {
      const row = startRow;
      const col = startCol;
      const column = numberToColumn(col + 1);
      const cellRef = `${column}${row + 1}`;
      const sheet = worksheet?.find(
        (item) => item?.metadata?.id_ori === selectedSheet
      );

      if (sheet) {
        const cell = sheet?.value?.find((item) => item.p === cellRef);
        this.setState({
          selectedCell: cell,
          isSingleSelected: true,
        });
      }

      this.setState({
        focused: cellRef,
      });
    } else {
      const startColumn =
        this.hotTableRef?.current?.hotInstance?.getColHeader(startCol);
      const startCellRef = `${startColumn}${startRow + 1}`;
      const endColumn =
        this.hotTableRef?.current?.hotInstance?.getColHeader(endCol);
      const endCellRef = `${endColumn}${endRow + 1}`;
      const sheet = worksheet?.find(
        (item) => item?.metadata?.id_ori === selectedSheet
      );

      if (sheet) {
        const startCell = sheet?.value?.find((item) => item.p === startCellRef);
        const endCell = sheet?.value?.find((item) => item.p === endCellRef);

        this.setState({
          selectedCells: {
            startCell,
            endCell,
          },
          isSingleSelected: false,
        });
      }
    }
  };

  handle_edit_cell = async (data = []) => {
    if (data?.length > 0) {
      const {
        worksheet,
        selectedSheet,
      } = this.state;

      const sheet =
        worksheet?.find((obj) => obj.metadata.id_ori === selectedSheet);

      for (const cell of data) {

        const cell_ref = `${cell?.[1]}${cell?.[0] + 1}`
        const value = cell?.[3];

        let res = null;
        if (value) {
          if (value?.[0] === "=") {
            res = await this.props.edit_cell({
              sheet_id: sheet?._id,
              cell_ref,
              formula: value?.slice(1),
            })
          } else {
            res = await this.props.edit_cell({
              sheet_id: sheet?._id,
              cell_ref,
              value: value,
            })
          }


          if (res?.status === 200) {

            const {
              geo_layer_id,
              feature_key,
              field_key
            } = this.props;

            const body = {
              geo_layer_id,
              feature_key,
              field_key,
            };

            const data = await this.props.get_sheets_by_cell(body);
            this.setState({
              worksheet: data?.length > 0 ? data : null,
            });

          }

        }

      }
    }
  }

  getBoundingCells = (startRow, startCol, endRow, endCol) => {
    const topLeft = {
      row: Math.min(startRow, endRow),
      col: Math.min(startCol, endCol),
    };

    const bottomRight = {
      row: Math.max(startRow, endRow),
      col: Math.max(startCol, endCol),
    };

    return { topLeft, bottomRight };
  }

  handleKeyDown = async (event) => {


    const hotInstance = this.hotTableRef.current.hotInstance;

    // Periksa apakah sedang dalam mode edit
    const isEditing = hotInstance.getActiveEditor()?.isOpened();

    // Jika tidak sedang dalam mode edit dan tombol Backspace/Delete ditekan
    if (!isEditing && (event.key === 'Backspace' || event.key === 'Delete')) {
      const selected = hotInstance.getSelected();
      if (selected) {

        let {
          worksheet,
          selectedSheet,
        } = this.state;

        const sheet_idx = worksheet?.findIndex((obj) => obj.metadata.id_ori === selectedSheet);
        let sheet = null;
        if (sheet_idx !== -1) {
          sheet = worksheet[sheet_idx]
        }


        this.setState({ isDeleting: true });
        const cell_refs = []
        // `selected` adalah array [startRow, startCol, endRow, endCol]
        const [startRow, startCol, endRow, endCol] = selected?.[0];
        const { topLeft, bottomRight } = this.getBoundingCells(startRow, startCol, endRow, endCol);

        for (let row = topLeft.row; row <= bottomRight.row; row++) {
          for (let col = topLeft.col; col <= bottomRight.col; col++) {
            cell_refs.push(numberToColumn(col + 1) + String(row + 1))
          }
        }

        const avail_cell = sheet?.value?.find(item => cell_refs.includes(item?.p));

        if (avail_cell) {
          const res = await this.props.delete_cells({ sheet_id: sheet?._id, cell_refs })
          if (res?.status === 200) {
            // const new_values = sheet.value.filter(item => !cell_refs.includes(item?._id))
            // console.log(new_values)
            // worksheet[sheet_idx].value = new_values

            const { field_key, geo_layer_id, feature_key } = this.props;

            const body = {
              geo_layer_id,
              feature_key,
              field_key,
            };

            const data = await this.props.get_sheets_by_cell(body);
            this.setState({
              worksheet: data?.length > 0 ? data : null,
            });

          }
        }

        this.setState({ isDeleting: false });

        // Mencegah tindakan default (seperti menghapus teks di browser)
        event.preventDefault();
      }
    }
  };

  handleRefCol = () => {
    const { focused } = this.state;
    if (focused) {
      this.toggle_modal_ref_selection();
    }
  };

  render() {
    const {
      uploaded_file,
      modal_upload,
      worksheet,
      selectedSheet,
      sheetnames,
      selectedCell,
      focused,
      modal_ref_selection,
      is_loading,
      isDeleting
    } = this.state;

    const { fields, field_key, geo_layer_id, feature_key } = this.props;
    const sheet =
      worksheet?.find((obj) => obj.metadata.id_ori === selectedSheet) || {};

    const filtered_fields = fields?.filter(
      (field) => field?.type === "sheet" && field?.key !== field_key
    );

    const modal_ref_selection_content = modal_ref_selection && (
      <Modal
        title="Referrece Selection"
        modalSize="large"
        id="modal_ref_selection"
        isOpen={modal_ref_selection}
        onClose={this.toggle_modal_ref_selection}
      >
        <div
          className="box-body"
          style={{ height: worksheet ? "80vh" : "500px" }}
        >
          <RefSelection
            filtered_fields={filtered_fields}
            geo_layer_id={geo_layer_id}
            feature_key={feature_key}
            field_key={field_key}
            target_sheet_id={sheet?._id}
            target_cell_ref={focused}
            setWorksheet={this.setWorksheet}
            currentWorksheet={worksheet}
          />
        </div>
      </Modal>
    );

    const modal_excel_content = modal_upload && (
      <Modal
        title="Upload Excel"
        id="modal_upload_excel"
        modalSize="full"
        is_hide="show"
        backgroundColor="rgba(255, 255, 255, 1);"
        use_blur={false}
        blur_value="5px"
        borderRadius="5px"
        isOpen={modal_upload}
        onClose={this.toggle_modal}
      >
        <div className="box-body">
          {is_loading ? (
            <div className="center_perfect h_full">
              <CircularProgress size={100} />
            </div>
          ) : (
            <SelectTableContent
              hotTableRef={this.hotTableRef}
              define_need_to_show={this.define_need_to_show}
              worksheet={worksheet}
              uploaded_file={uploaded_file}
              selectedCell={selectedCell}
              focused={focused}
              selectedSheet={selectedSheet}
              sheetnames={sheetnames}
              setSheetnames={this.setSheetnames}
              setSelectedSheet={this.setSelectedSheet}
              setWorksheet={this.setWorksheet}
              handle_selected_cell={this.handle_selected_cell}
              setFocused={this.setFocused}
              handleRefCol={this.handleRefCol}
              geo_layer_id={geo_layer_id}
              feature_key={feature_key}
              field_key={field_key}
              handle_edit_cell={this.handle_edit_cell}
              handleKeyDown={this.handleKeyDown}
              isDeleting={isDeleting}
            />
          )}
        </div>
      </Modal>
    );

    return (
      <div>
        <button
          onClick={this.toggle_modal}
          style={{
            color: "#0166cb",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            textTransform: "uppercase",
          }}
        >
          {"Excel"}
        </button>
        {modal_excel_content}
        {modal_ref_selection_content}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const geo_layer = getGeoLayer(state.layer);
  return {
    geo_layer_id: geo_layer?._id,
    fields: geo_layer?.fields,
  };
};

export default connect(mapStateToProps, {
  get_sheets_by_cell,
  create_sheet,
  snackbar,
  set_value_layer,
  edit_cell,
  delete_cells,
})(ExcelTable);
