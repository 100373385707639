import axios from "axios";
import convex from "@turf/convex";
import grid_functions from "../validation/grid_functions";
import points_within_polygon from "@turf/points-within-polygon";

const { get_grids_in_polygon } = grid_functions;

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

const delay_get_poi_bulk = 1_200; //ms
const grouping_number_poi_bulk = 150; //jumlah grid dalam satu get fetch

export const set_value_insight = ({ key, value }) => {
  return {
    type: "set_value_insight",
    payload: { key, value },
  };
};

export const push_value_insight = ({ key, value }) => {
  return {
    type: "push_value_insight",
    payload: { key, value },
  };
};

export const push_value_many_insight = ({ key, value }) => {
  return {
    type: "push_value_many_insight",
    payload: { key, value },
  };
};

export const insight_update = () => {
  return {
    type: "insight_update",
  };
};

export const insight_fly_update = () => {
  return {
    type: "insight_fly_update",
  };
};

/**
 * @route : list_tipe_1
 * @endpoint : /sini_v2/insight_hirarki_poi/get_list_tipe_1
 * @methode : get
 * @query : -
 */
export const get_list_tipe_1 = () => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_list_tipe_1",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + `/sini_v2/insight_hirarki_poi/get_list_tipe_1`,
      config
    );
    const value = res.data;
    dispatch({
      type: "set_value_insight",
      payload: {
        key: "list_tipe_1",
        value,
      },
    });
    dispatch({
      type: "clear_loading_action",
      payload: "get_list_tipe_1",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_list_tipe_1",
    });
  }
};

/**
 * @route : list_tipe_2
 * @endpoint : /sini_v2/insight_hirarki_poi/get_list_tipe_2
 * @methode : get
 * @query : -
 */
export const get_list_tipe_2 = () => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_list_tipe_2",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + `/sini_v2/insight_hirarki_poi/get_list_tipe_2`,
      config
    );
    const value = res.data;
    dispatch({
      type: "set_value_insight",
      payload: {
        key: "list_tipe_2",
        value,
      },
    });
    dispatch({
      type: "clear_loading_action",
      payload: "get_list_tipe_2",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_list_tipe_2",
    });
  }
};

/**
 * @route : list_tipe_3
 * @endpoint : /sini_v2/insight_hirarki_poi/get_list_tipe_3
 * @methode : get
 * @query : -
 */
export const get_list_tipe_3 = () => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_list_tipe_3",
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const res = await axios.get(
      bun_server + `/sini_v2/insight_hirarki_poi/get_list_tipe_3`,
      config
    );
    const value = res.data;
    dispatch({
      type: "set_value_insight",
      payload: {
        key: "list_tipe_3",
        value,
      },
    });
    dispatch({
      type: "clear_loading_action",
      payload: "get_list_tipe_3",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_list_tipe_3",
    });
  }
};

/**
 * @route : insight_search_admin
 * @endpoint : /sini_v2/insight_search_admin
 * @methode : get
 * @query : -
 */
export const insight_search_admin = (query) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "insight_search_admin",
    });

    //sini_v2/insight_search_admin/kelurahan
    //sini_v2/insight_search_admin/kecamatan
    //sini_v2/insight_search_admin/kota

    const { search } = query;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    const res_kelurahan = await axios.get(
      bun_server + `/sini_v2/insight_search_admin/kelurahan?search=${search}`,
      config
    );
    const kelurahan_list_by_search = res_kelurahan.data;
    dispatch({
      type: "set_value_insight",
      payload: {
        key: "kelurahan_list_by_search",
        value: kelurahan_list_by_search,
      },
    });

    const res_kecamatan = await axios.get(
      bun_server + `/sini_v2/insight_search_admin/kecamatan?search=${search}`,
      config
    );
    const kecamatan_list_by_search = res_kecamatan.data;
    dispatch({
      type: "set_value_insight",
      payload: {
        key: "kecamatan_list_by_search",
        value: kecamatan_list_by_search,
      },
    });

    const res_kota = await axios.get(
      bun_server + `/sini_v2/insight_search_admin/kota?search=${search}`,
      config
    );
    const kota_list_by_search = res_kota.data;
    dispatch({
      type: "set_value_insight",
      payload: {
        key: "kota_list_by_search",
        value: kota_list_by_search,
      },
    });

    dispatch({
      type: "clear_loading_action",
      payload: "insight_search_admin",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "insight_search_admin",
    });
  }
};

/**
 * @route : insight_hirarki_admin
 * @endpoint : /sini_v2/insight_hirarki_admin
 * @methode : get
 * @query : -
 */

export const insight_hirarki_admin = (query) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "insight_hirarki_admin",
    });

    //sini_v2/insight_hirarki_admin/kelurahan
    //sini_v2/insight_hirarki_admin/kecamatan
    //sini_v2/insight_hirarki_admin/kota
    //sini_v2/insight_hirarki_admin/provinsi

    const { PROVINSI, KABKOT, KECAMATAN, level } = query;
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    if (level === "kelurahan") {
      //get DESA by KECAMATAN
      const res = await axios.get(
        bun_server +
          `/sini_v2/insight_hirarki_admin/kelurahan?KECAMATAN=${KECAMATAN}&KABKOT=${KABKOT}&PROVINSI=${PROVINSI}`,
        config
      );
      dispatch({
        type: "set_value_insight",
        payload: {
          key: "kelurahan_hirarki",
          value: res.data,
        },
      });
    } else if (level === "kecamatan") {
      //get KECAMATAN by KABKOT
      const res = await axios.get(
        bun_server +
          `/sini_v2/insight_hirarki_admin/kecamatan?KABKOT=${KABKOT}&PROVINSI=${PROVINSI}`,
        config
      );
      dispatch({
        type: "set_value_insight",
        payload: {
          key: "kecamatan_hirarki",
          value: res.data,
        },
      });
    } else if (level === "kota") {
      //get KABKOT by PROVINSI
      const res = await axios.get(
        bun_server + `/sini_v2/insight_hirarki_admin/kota?PROVINSI=${PROVINSI}`,
        config
      );
      dispatch({
        type: "set_value_insight",
        payload: {
          key: "kota_hirarki",
          value: res.data,
        },
      });
    } else {
      //get all PROVINSI
      const res = await axios.get(
        bun_server + `/sini_v2/insight_hirarki_admin/provinsi`,
        config
      );
      dispatch({
        type: "set_value_insight",
        payload: {
          key: "provinsi_hirarki",
          value: res.data,
        },
      });
    }

    dispatch({
      type: "clear_loading_action",
      payload: "insight_hirarki_admin",
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "insight_hirarki_admin",
    });
  }
};

/**
 * @route : ...
 * @endpoint : /sini_v2/...
 * @methode : get
 * @query : -
 */

export const get_insight_v2 = (query) => async (dispatch) => {
  try {
    dispatch({
      type: "set_loading_action",
      payload: "get_insight_v2",
    });
    dispatch({
      type: "set_value_insight",
      payload: {
        key: "auto_step",
        value: 0,
      },
    });
    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };
    const { PROVINSI, KABKOT, KECAMATAN, DESA } = query;

    //step 1 get data polygon
    const res_features_demography = await axios.get(
      bun_server +
        `/sini_v2/insight_demography?PROVINSI=${PROVINSI}&KABKOT=${KABKOT}&KECAMATAN=${KECAMATAN}&DESA=${DESA}`,
      config
    );
    const features_demography = res_features_demography?.data || [];

    dispatch({
      type: "set_value_insight",
      payload: {
        key: "features_demography",
        value: features_demography,
      },
    });
    dispatch({
      type: "insight_update",
    });
    dispatch({
      type: "insight_fly_update",
    });

    //step 2 convert polygon into bounding box
    const geojson_demography = {
      type: "FeatureCollection",
      features: features_demography,
    };

    const feature_polygon = convex(geojson_demography, { concavity: 1 });
    const polygon = feature_polygon.geometry.coordinates[0];

    const grid_id_list = get_grids_in_polygon({
      polygon,
      grouping_number: grouping_number_poi_bulk,
    });
    const total_poi = grid_id_list.length || 0;
    dispatch({
      type: "set_value_insight",
      payload: {
        key: "total_poi",
        value: total_poi,
      },
    });

    let features_poi = [];

    const delay_promise = () =>
      new Promise((res) => setTimeout(res, delay_get_poi_bulk));

    const parent_function = () => {
      return grid_id_list.reduce(
        (last_promise, object, idx) =>
          last_promise.then((result_sum) =>
            child_function(object, idx).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };

    const child_function = async (item, idx) => {
      return delay_promise().then(() => {
        const core_function = async () => {
          try {
            const body = { array_grid: item, ...query };
            const res_features_poi = await axios.post(
              bun_server + `/sini_v2/insight_poi`,
              body,
              config
            );
            const array = res_features_poi?.data || [];
            const geojson_point = {
              type: "FeatureCollection",
              features: array,
            };
            const geojson_point_filtered = points_within_polygon(
              geojson_point,
              geojson_demography
            );
            const features_poi_final = geojson_point_filtered.features;
            features_poi = [...features_poi, ...features_poi_final];
            dispatch({
              type: "set_value_insight",
              payload: {
                key: "features_poi",
                value: features_poi,
              },
            });
            dispatch({
              type: "set_value_insight",
              payload: {
                key: "total_poi_initial",
                value: features_poi.length,
              },
            });
            dispatch({
              type: "insight_update",
            });
            dispatch({
              type: "set_value_insight",
              payload: {
                key: "current_progress_poi",
                value: idx + 1,
              },
            });
          } catch (error) {}
        };
        return core_function();
      });
    };

    parent_function().then(() => {
      dispatch({
        type: "clear_loading_action",
        payload: "get_insight_v2",
      });
      dispatch({
        type: "set_value_insight",
        payload: {
          key: "auto_step",
          value: 1,
        },
      });
    });
  } catch (error) {
    dispatch({
      type: "clear_loading_action",
      payload: "get_insight_v2",
    });
  }
};
