/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import RANGKING_CHART from "./RANKING_CHART";
import MARKDOWN_RENDERER from "../sini_v2_full/MARKDOWN_RENDERER";
import ProgressBar from "../common_spinner/ProgressBar";
import Modal from "../common_modal/Modal";

/*REDUX*/
import {
  get_ai_hbu_summary,
  get_ai_sheet_chat_list,
  get_ai_sini_sheet,
} from "../../App/actions/ai_excel";
import { push_ai_sheet_chat } from "../../App/actions/push_ai_sheet_chat";
import { get_sini_data } from "../../App/actions/get_sini_data";
import { generate_ai_hbu_summary } from "../../App/actions/generate_ai_hbu_summary";

/*PICTURE*/

/*FUNCTION*/
import { convertExcelDataToHandsontableFormat } from "../../App/validation/convert_data";
import uuid from "../../App/validation/uuid";

/*DATA*/

/*CONST*/

class EXCEL_AI extends Component {
  state = {
    sheet_array: [],
    user_message: "",
    modal_re_hbu: false,
    user_message_hbu: "",
  };

  componentDidMount() {
    //basic
    const { worksheet } = this.props;
    const sheet_array = worksheet.map((sheet) => {
      const selectedSheet = sheet?.metadata?.id_ori;
      const { data } = convertExcelDataToHandsontableFormat({
        sheet,
        selectedSheet,
        rawData: worksheet,
      });
      return data;
    });
    this.setState({
      sheet_array,
    });
    const { geo_layer_id, feature_key, field_key } = this.props;
    const params = {
      geo_layer_id,
      feature_key,
      field_key,
    };
    this.props.get_ai_hbu_summary(params);
    this.props.get_ai_sheet_chat_list(params);
    //advance
    const { feature_object_selected } = this.props.layer;
    const long = feature_object_selected?.geometry?.coordinates?.[0];
    const lat = feature_object_selected?.geometry?.coordinates?.[1];
    const params_sini = {
      geo_layer_id,
      feature_key,
      field_key,
      long,
      lat,
      request_id: uuid(),
      is_from_excel: true,
      sini_data_mode: "sini_general",
      show_survey_1: true,
    };
    this.props.get_ai_sini_sheet(params_sini);
  }

  toggle_re_hbu = () => {
    this.setState({
      modal_re_hbu: !this.state.modal_re_hbu,
    });
  };

  on_change = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [name]: value,
    });
  };

  on_generate_ai_hbu_summary = () => {
    const { sheet_array, user_message_hbu } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const body = {
      worksheet: sheet_array,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
      user_message_hbu,
    };
    this.props.generate_ai_hbu_summary(body);
    this.setState({
      user_message_hbu: "",
      modal_re_hbu: false,
    });
  };

  reset_ai_hbu_summary = () => {
    const { sheet_array } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const { ai_parent_detail_list, sheet_object_selected } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const body = {
      worksheet: sheet_array,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
      user_message_hbu: "",
    };
    this.props.generate_ai_hbu_summary(body);
    this.setState({
      user_message_hbu: "",
      modal_re_hbu: false,
    });
  };

  on_regenerate_ai_hbu_summary = () => {
    const { user_message_hbu } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const {
      ai_parent_detail_list,
      sheet_object_selected,
      ai_hbu_summary_object,
    } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const body = {
      worksheet: ai_hbu_summary_object,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
      user_message_hbu,
    };
    this.props.generate_ai_hbu_summary(body);
    this.setState({
      user_message_hbu: "",
      modal_re_hbu: false,
    });
  };

  on_push_ai_sheet_chat = () => {
    const { user_message } = this.state;
    const { geo_layer_id, feature_key, field_key } = this.props;
    const {
      ai_parent_detail_list,
      sheet_object_selected,
      ai_hbu_summary_object,
    } = this.props.sini;
    const sini_object = ai_parent_detail_list.find(
      (item) => item.request_id === sheet_object_selected.request_id
    );
    const body = {
      ai_hbu_summary_object,
      user_message,
      geo_layer_id,
      feature_key,
      field_key,
      sini_object,
    };
    this.props.push_ai_sheet_chat(body);
    this.setState({
      user_message: "",
    });
  };

  render() {
    //local storage

    //local state
    const { user_message, modal_re_hbu, user_message_hbu } = this.state;

    //global props
    const { is_mobile } = this.props.properties;
    const { loading_item_array } = this.props.loading_reducer;
    const {
      total_step,
      current_step,
      current_topic_loading,
      ai_hbu_summary_object,
      ai_sheet_chat_list,
      ai_sini_sheet_object,
    } = this.props.sini;

    //content
    const modal_re_hbu_content = modal_re_hbu && (
      <Modal
        modalSize="small"
        isOpen={modal_re_hbu}
        onClose={this.toggle_re_hbu}
      >
        <div className="box-body" id="box-body">
          <textarea
            className="margin_bottom"
            rows={6}
            placeholder="Masukkan pesan untuk pembaruan summary"
            value={user_message_hbu}
            name="user_message_hbu"
            onChange={this.on_change}
          />
          <br />
          <button
            className="button_big background_black margin_right margin_bottom"
            onClick={this.on_regenerate_ai_hbu_summary}
          >
            Perbarui Summary
          </button>
          <button
            className="button_big background_black"
            onClick={this.reset_ai_hbu_summary}
          >
            Reset
          </button>
        </div>
      </Modal>
    );

    const summary_result = ai_hbu_summary_object?.summary_result;
    let summary_content;
    if (!summary_result) {
      summary_content = (
        <section className="text_center container_light outline_transparent background_grey_light">
          <p className="text_bold margin_bottom">
            Belum dilakukan perangkuman HBU
          </p>
          <br />
          {loading_item_array.includes("generate_ai_hbu_summary") ? (
            <button className="button_big background_grey">
              AI SEDANG MELAKUKAN SUMMARY...
            </button>
          ) : !ai_sini_sheet_object?.request_id ? (
            <button className="button_big background_grey">Wait</button>
          ) : (
            <button
              className="button_big background_black"
              onClick={this.on_generate_ai_hbu_summary}
            >
              Generate HBU Summary
            </button>
          )}
          <br />
        </section>
      );
    } else {
      const hbu_best_alternative = summary_result?.hbu_best_alternative || "";
      const hbu_best_alternative_reason =
        summary_result?.hbu_best_alternative_reason || "";
      const hbu_best_alternative_notes =
        summary_result?.hbu_best_alternative_notes || "";
      const hbu_alternative_array = summary_result?.hbu_alternative_array || [];
      const additional_data_recommendations =
        summary_result?.additional_data_recommendations || [];
      summary_content = (
        <section style={{ overflowY: "auto", maxHeight: "100%" }}>
          <h1 className="margin_bottom">AI HBU SUMMARY</h1>
          <section className="margin_bottom">
            {loading_item_array.includes("generate_ai_hbu_summary") ? (
              <button className="button_big background_grey">
                AI SEDANG MELAKUKAN SUMMARY...
              </button>
            ) : !ai_sini_sheet_object?.request_id ? (
              <button className="button_big background_grey">WAIT</button>
            ) : (
              <button
                className="button_big background_black"
                onClick={this.toggle_re_hbu}
              >
                Perbarui Ringkasan HBU dengan Prompt
              </button>
            )}
          </section>

          <aside
            className="child_tile container_light background_grey_light outline_transparent"
            style={{ width: "auto" }}
          >
            <h1 className="text_bold margin_bottom">
              Rangkuman finansial setiap alternatif
            </h1>
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th>NPV</th>
                  <th>IRR</th>
                  <th>PI</th>
                  <th>Payback Period</th>
                  <th>Raw score</th>
                  <th>Result score</th>
                  <th>Rangking</th>
                </tr>
              </thead>
              <tbody>
                {hbu_alternative_array.map((item, idx) => {
                  const {
                    name,
                    npv,
                    irr,
                    pi,
                    payback_periode_year,
                    raw_score,
                    result_score,
                    rangking,
                  } = item;
                  return (
                    <tr key={idx}>
                      <td>{name}</td>
                      <td>{npv}</td>
                      <td>{irr}</td>
                      <td>{pi}</td>
                      <td>{payback_periode_year}</td>
                      <td>{raw_score}</td>
                      <td>{result_score}</td>
                      <td>{rangking}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </aside>

          <aside className="parent_tile">
            <div
              className="child_tile container_light background_grey_light outline_transparent"
              style={{ width: "200px" }}
            >
              <h1 className="text_bold margin_bottom">
                Alternatif HBU terbaik
              </h1>
              <p className="badge background_black">{hbu_best_alternative}</p>
            </div>
            <div
              className="child_tile container_light background_grey_light outline_transparent"
              style={{ width: "200px" }}
            >
              <h1 className="text_bold margin_bottom">
                Rangkuman kalkulasi HBU
              </h1>
              <RANGKING_CHART hbu_alternative_array={hbu_alternative_array} />
            </div>
            <div
              className="child_tile container_light background_grey_light outline_transparent"
              style={{ width: "200px" }}
            >
              <h1 className="text_bold margin_bottom">Narasi penguat</h1>
              <p className="text_medium">{hbu_best_alternative_reason}</p>
              <br />
              <br />
              <h1 className="text_bold margin_bottom">Catatan</h1>
              <p className="text_medium">{hbu_best_alternative_notes}</p>
            </div>
            <div
              className="child_tile container_light background_grey_light outline_transparent"
              style={{ width: "200px" }}
            >
              <h1 className="text_bold margin_bottom">
                Rekomendasi data tambahan
              </h1>
              {additional_data_recommendations.map((item, idx) => {
                return (
                  <div key={idx} className="margin_bottom">
                    <h3 className="text_medium margin_bottom">
                      {idx + 1}. {item.data_name}
                    </h3>
                    <p className="text_small">{item.why_importance}</p>
                  </div>
                );
              })}
            </div>
          </aside>
        </section>
      );
    }

    const chat_content = (
      <main className="parent_section_full container_light outline_transparent background_grey_light">
        <section className="section_1">
          <p className="badge background_black">
            AI CHAT ({!ai_sini_sheet_object?.request_id ? "Load..." : "Ready"})
          </p>
        </section>
        <section
          className="section_2"
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              flexGrow: 1,
              overflowY: "auto",
              padding: "10px",
              maxHeight: "calc( 80vh - 150px )",
            }}
          >
            <div>
              {total_step !== current_step && (
                <ProgressBar
                  current_number={current_step}
                  total_number={total_step}
                  name={current_topic_loading}
                />
              )}
            </div>
            {ai_sheet_chat_list.map((chat, idx) => {
              let final_content;
              if (chat.role === "assistant" && chat.content) {
                final_content = (
                  <section key={idx} id={`ai_sini_${idx}`}>
                    <div className="container_light outline_transparent margin_bottom background_dark_blue">
                      <MARKDOWN_RENDERER markdownContent={chat.content} />
                    </div>
                  </section>
                );
              } else {
                final_content = (
                  <section key={idx} id={`ai_sini_${idx}`}>
                    <div
                      className="container_light outline_transparent margin_bottom max_width_300 background_white"
                      style={{ right: 0, whiteSpace: "pre-wrap" }}
                    >
                      {chat.content}
                    </div>
                  </section>
                );
              }
              return final_content;
            })}
          </div>
          <div
            id="chat_dialog"
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <textarea
              name="user_message"
              value={user_message}
              onChange={this.on_change}
              rows={6}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "4px",
                border: "1px solid #ccc",
              }}
            />
            {loading_item_array.includes("push_ai_sheet_chat") ? (
              <button
                className="button background_grey"
                style={{
                  alignSelf: "flex-end",
                }}
              >
                Sending...
              </button>
            ) : !ai_sini_sheet_object?.request_id ? (
              <button
                className="button background_grey"
                style={{
                  alignSelf: "flex-end",
                }}
              >
                Wait
              </button>
            ) : (
              <button
                className="button"
                style={{
                  alignSelf: "flex-end",
                }}
                onClick={this.on_push_ai_sheet_chat}
              >
                Chat
              </button>
            )}
          </div>
        </section>
      </main>
    );
    let content;
    if (!is_mobile) {
      content = (
        <main style={{ display: "flex", height: "calc(100vh - 75px)" }}>
          <section style={{ flexGrow: 1, marginRight: "16px" }}>
            {summary_content}
          </section>
          <section
            style={{
              width: "300px",
              flexShrink: 0,
              flexGrow: 0,
              height: "100%",
            }}
          >
            {chat_content}
          </section>
        </main>
      );
    } else {
      content = <main>Mode mobile not supported yet</main>;
    }
    return (
      <main>
        {modal_re_hbu_content}
        {content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  loading_reducer: state.loading_reducer,
  sini: state.sini,
  properties: state.properties,
  layer: state.layer,
});

export default connect(mapStateToProps, {
  generate_ai_hbu_summary,
  get_ai_hbu_summary,
  push_ai_sheet_chat,
  get_ai_sheet_chat_list,
  get_ai_sini_sheet,
  get_sini_data,
})(EXCEL_AI);
