/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import {
  set_value_insight,
  push_value_insight,
  get_insight_v2,
} from "../../App/actions/insight_v2";

/*PICTURE*/
import ICON_ARROW_RIGHT_LINE from "../../Assets/jsx/ICON_ARROW_RIGHT_LINE";

/*FUNCTION*/
import uuid from "../../App/validation/uuid";

/*DATA*/

/*CONST*/
const array_level_tipe = [
  {
    key: "tipe_1",
    name: "TIPE 1",
    description: "Tipe umum, contoh: FASILITAS PENDIDIKAN",
  },
  {
    key: "tipe_2",
    name: "TIPE 2",
    description: "Sub tipe, contoh: SD, SMP, SMA",
  },
  {
    key: "tipe_3",
    name: "TIPE 3",
    description: "Contoh: Indomaret, Alfamart, Klinik Gigi",
  },
];

class INPUT_TIPE_FULL extends Component {
  state = {
    modal_tipe_2: false,
    tipe_1_opened: "",
    text_typing: "",
    text_filter: "",
    list_tipe_1_filtered_primer: [],
    list_tipe_1_filtered_sekunder: [],
    list_tipe_2_filtered_primer: [],
    list_tipe_2_filtered_sekunder: [],
    list_tipe_3_filtered_primer: [],
    list_tipe_3_filtered_sekunder: [],
  };

  on_get_insight = () => {
    this.props.toggle_choose_poi();
    const { isAuthenticated, quota_access } = this.props.auth;
    const quota_access_sini_ai = this.props.auth.quota_access_sini_ai || 0;
    const { license_user_status, license_group_status } =
      this.props.license_reducer;
    const payment_type = license_user_status?.payment_type;
    const logic_1 = !["license_1", "license_2", "license_3"].includes(
      payment_type
    );
    const logic_2 = !license_group_status?.is_has_license;
    const logic_3 = quota_access <= 0;
    const logic_4 = quota_access_sini_ai <= 0;
    if (!isAuthenticated) {
      this.toggle_login();
    } else if (logic_1 && logic_2 && logic_3 && logic_4) {
      this.toggle_payment_universal();
    } else {
      const mode_input_parent = localStorage?.mode_input_parent || "";
      const { kota } = this.props.insight_reducer;
      if (kota && mode_input_parent === "custom") {
        const {
          provinsi,
          kota,
          kecamatan,
          kelurahan,
          tipe_1_array,
          tipe_2_array,
          tipe_3_array,
          tipe_level,
          tipe_1_all,
        } = this.props.insight_reducer;
        const query = {
          request_id: uuid(),
          PROVINSI: provinsi,
          KABKOT: kota,
          KECAMATAN: kecamatan,
          DESA: kelurahan,
          tipe_1_array,
          tipe_2_array,
          tipe_3_array,
          tipe_level,
          tipe_1_all,
        };
        this.props.get_insight_v2(query);
      }
    }
  };

  on_change = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  on_enter_filter = (event) => {
    const { list_tipe_1, list_tipe_2, list_tipe_3 } =
      this.props.insight_reducer;
    event.preventDefault();
    const { text_typing } = this.state;
    this.setState({ text_filter: text_typing }, () => {
      if (text_typing.length >= 3) {
        const { tipe_level } = this.props.insight_reducer;
        let primary_key, secondary_key, type_key, data_source;
        if (tipe_level === "tipe_3") {
          primary_key = "list_tipe_3_filtered_primer";
          secondary_key = "list_tipe_3_filtered_sekunder";
          type_key = "TIPE_3";
          data_source = list_tipe_3;
        } else if (tipe_level === "tipe_2") {
          primary_key = "list_tipe_2_filtered_primer";
          secondary_key = "list_tipe_2_filtered_sekunder";
          type_key = "TIPE_2";
          data_source = list_tipe_2;
        } else {
          primary_key = "list_tipe_1_filtered_primer";
          secondary_key = "list_tipe_1_filtered_sekunder";
          type_key = "TIPE_1";
          data_source = list_tipe_1;
        }
        const words = text_typing
          .split(" ")
          .filter((word) => word.length >= 3)
          .slice(0, 5);
        const strict_regex = new RegExp(
          words.map((word) => `\\b${word}`).join("|"),
          "i"
        );
        const broad_patterns = words.flatMap((word) => {
          const chunks = [];
          for (let i = 0; i <= word.length - 2; i++) {
            chunks.push(word.substring(i, i + 2));
            if (i <= word.length - 3) chunks.push(word.substring(i, i + 3));
          }
          return chunks;
        });
        const broad_regex = new RegExp(broad_patterns.join("|"), "i");
        const list_filtered_primary = data_source.filter((item) =>
          strict_regex.test(item[type_key])
        );
        const list_filtered_secondary = data_source.filter(
          (item) =>
            broad_regex.test(item[type_key]) &&
            !list_filtered_primary.includes(item)
        );
        this.setState(
          {
            [primary_key]: list_filtered_primary,
            [secondary_key]: list_filtered_secondary,
            text_typing: "",
          },
          () => {
            const container = document.getElementById("section_2_insight_poi");
            if (container) container.scrollTo({ top: 0, behavior: "smooth" });
          }
        );
      } else {
        this.setState({
          list_tipe_1_filtered_primer: [],
          list_tipe_1_filtered_sekunder: [],
          list_tipe_2_filtered_primer: [],
          list_tipe_2_filtered_sekunder: [],
          list_tipe_3_filtered_primer: [],
          list_tipe_3_filtered_sekunder: [],
          text_typing: "",
        });
        const container = document.getElementById("section_2_insight_poi");
        if (container) container.scrollTo({ top: 0, behavior: "smooth" });
      }
    });
  };

  on_reset_filter = () => {
    this.setState({ text_filter: "" });
    const container = document.getElementById("section_2_insight_poi");
    if (container) container.scrollTo({ top: 0, behavior: "smooth" });
  };

  on_toggle_tipe_1 = (item_1) => {
    const { list_tipe_2, list_tipe_3 } = this.props.insight_reducer;

    this.props.set_value_insight({ key: "tipe_1_all", value: false });
    const { tipe_1_array, tipe_2_array, tipe_3_array } =
      this.props.insight_reducer;
    const is_active = tipe_1_array.some(
      (element) => element.TIPE_1 === item_1.TIPE_1
    );
    if (!is_active) {
      this.props.push_value_insight({ key: "tipe_1_array", value: item_1 });
      const tipe_2_array_new = [
        ...tipe_2_array,
        ...list_tipe_2.filter((item_2) => item_2.TIPE_1 === item_1.TIPE_1),
      ];
      this.props.set_value_insight({
        key: "tipe_2_array",
        value: tipe_2_array_new,
      });
      const tipe_3_array_new = [
        ...tipe_3_array,
        ...list_tipe_3.filter((item_3) => item_3.TIPE_1 === item_1.TIPE_1),
      ];
      this.props.set_value_insight({
        key: "tipe_3_array",
        value: tipe_3_array_new,
      });
    } else {
      this.props.set_value_insight({
        key: "tipe_1_array",
        value: tipe_1_array.filter(
          (element) => element.TIPE_1 !== item_1.TIPE_1
        ),
      });
      this.props.set_value_insight({
        key: "tipe_2_array",
        value: tipe_2_array.filter((item_2) => item_2.TIPE_1 !== item_1.TIPE_1),
      });
      this.props.set_value_insight({
        key: "tipe_3_array",
        value: tipe_3_array.filter((item_3) => item_3.TIPE_1 !== item_1.TIPE_1),
      });
    }
  };

  on_toggle_tipe_2 = (item_2) => {
    const { list_tipe_3 } = this.props.insight_reducer;

    const { tipe_3_array, tipe_2_array, tipe_1_array } =
      this.props.insight_reducer;
    this.props.set_value_insight({ key: "tipe_1_all", value: false });
    const is_active = tipe_2_array.some(
      (element) =>
        element.TIPE_1 === item_2.TIPE_1 && element.TIPE_2 === item_2.TIPE_2
    );
    if (!is_active) {
      //push tipe 2
      this.props.push_value_insight({ key: "tipe_2_array", value: item_2 });
      //push tipe 3
      const tipe_3_array_new = [
        ...tipe_3_array,
        ...list_tipe_3.filter(
          (item_3) =>
            item_3.TIPE_1 === item_2.TIPE_1 && item_3.TIPE_2 === item_2.TIPE_2
        ),
      ];
      this.props.set_value_insight({
        key: "tipe_3_array",
        value: tipe_3_array_new,
      });
    } else {
      //pull tipe 2
      this.props.set_value_insight({
        key: "tipe_2_array",
        value: tipe_2_array.filter(
          (element) =>
            !(
              element.TIPE_1 === item_2.TIPE_1 &&
              element.TIPE_2 === item_2.TIPE_2
            )
        ),
      });
      //pull tipe 3
      this.props.set_value_insight({
        key: "tipe_3_array",
        value: tipe_3_array.filter(
          (item_3) =>
            item_3.TIPE_1 !== item_2.TIPE_1 && item_3.TIPE_2 !== item_2.TIPE_2
        ),
      });
    }
    const TIPE_1 = item_2.TIPE_1;
    this.props.set_value_insight({
      key: "tipe_1_array",
      value: tipe_1_array.filter((element) => element.TIPE_1 !== TIPE_1),
    });
  };

  on_toggle_tipe_3 = (item_3) => {
    const { tipe_3_array, tipe_1_array } = this.props.insight_reducer;
    this.props.set_value_insight({ key: "tipe_1_all", value: false });
    const is_active = tipe_3_array.some(
      (element) =>
        element.TIPE_1 === item_3.TIPE_1 &&
        element.TIPE_2 === item_3.TIPE_2 &&
        element.TIPE_3 === item_3.TIPE_3
    );
    if (!is_active) {
      this.props.push_value_insight({ key: "tipe_3_array", value: item_3 });
    } else {
      this.props.set_value_insight({
        key: "tipe_3_array",
        value: tipe_3_array.filter(
          (element) =>
            !(
              element.TIPE_1 === item_3.TIPE_1 &&
              element.TIPE_2 === item_3.TIPE_2 &&
              element.TIPE_3 === item_3.TIPE_3
            )
        ),
      });
    }
    const TIPE_1 = item_3.TIPE_1;
    this.props.set_value_insight({
      key: "tipe_1_array",
      value: tipe_1_array.filter((element) => element.TIPE_1 !== TIPE_1),
    });
  };

  toggle_tipe_1_all = () => {
    const { list_tipe_1, list_tipe_2, list_tipe_3 } =
      this.props.insight_reducer;
    const { tipe_1_all } = this.props.insight_reducer;
    this.props.set_value_insight({ key: "tipe_1_all", value: !tipe_1_all });
    this.props.set_value_insight({
      key: "tipe_1_array",
      value: tipe_1_all ? [] : list_tipe_1,
    });
    this.props.set_value_insight({
      key: "tipe_2_array",
      value: tipe_1_all ? [] : list_tipe_2,
    });
    this.props.set_value_insight({
      key: "tipe_3_array",
      value: tipe_1_all ? [] : list_tipe_3,
    });
  };

  on_set_tipe_level = (tipe_level) => {
    this.props.set_value_insight({ key: "tipe_level", value: tipe_level });
    const container = document.getElementById("section_2_insight_poi");
    if (container) container.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {
    //local storage

    //local state
    const {
      text_typing,
      text_filter,
      list_tipe_2_filtered_primer,
      list_tipe_2_filtered_sekunder,
      list_tipe_3_filtered_primer,
      list_tipe_3_filtered_sekunder,
    } = this.state;

    //global props
    const { tipe_1_array, tipe_2_array, tipe_3_array, tipe_level, tipe_1_all } =
      this.props.insight_reducer;
    const { list_tipe_1, list_tipe_2, list_tipe_3 } =
      this.props.insight_reducer;

    //content
    const tipe_3_content = text_filter ? (
      list_tipe_3_filtered_primer.length === 0 &&
      list_tipe_3_filtered_sekunder.length === 0 ? (
        <p>No result</p>
      ) : (
        <div>
          {list_tipe_3_filtered_primer.map((item_3, idx) => {
            const is_active = tipe_3_array.some(
              (element) =>
                element.TIPE_1 === item_3.TIPE_1 &&
                element.TIPE_2 === item_3.TIPE_2 &&
                element.TIPE_3 === item_3.TIPE_3
            );
            return (
              <div
                key={idx}
                className="container_light margin_right margin_bottom column_size_small outline_transparent background_grey"
              >
                <div className="two_container_list">
                  <button
                    className={`margin_right ${
                      is_active ? "checklist_active" : "checklist_not"
                    }`}
                    onClick={this.on_toggle_tipe_3.bind(this, item_3)}
                  />
                  <p className="text_bold">{item_3.TIPE_3}</p>
                </div>
                <p className="text_small margin_top">({item_3.TIPE_2})</p>
              </div>
            );
          })}
          {list_tipe_3_filtered_sekunder.length > 0 && (
            <>
              <br />
              <br />
              <br />
              <br />
              {list_tipe_3_filtered_sekunder.map((item_3, idx) => {
                const is_active = tipe_3_array.some(
                  (element) =>
                    element.TIPE_1 === item_3.TIPE_1 &&
                    element.TIPE_2 === item_3.TIPE_2 &&
                    element.TIPE_3 === item_3.TIPE_3
                );
                return (
                  <div
                    key={idx}
                    className="container_light margin_right margin_bottom column_size_small outline_transparent background_grey"
                  >
                    <div className="two_container_list">
                      <button
                        className={`margin_right ${
                          is_active ? "checklist_active" : "checklist_not"
                        }`}
                        onClick={this.on_toggle_tipe_3.bind(this, item_3)}
                      />
                      <p className="text_bold">{item_3.TIPE_3}</p>
                    </div>
                    <p className="text_small margin_top">({item_3.TIPE_2})</p>
                  </div>
                );
              })}
            </>
          )}
        </div>
      )
    ) : (
      <div>
        {list_tipe_1.map((item_1, idx) => {
          const is_active = tipe_1_array.some(
            (element) => element.TIPE_1 === item_1.TIPE_1
          );
          const list_tipe_3_filtered = list_tipe_3.filter(
            (item_tipe_3) => item_tipe_3.TIPE_1 === item_1.TIPE_1
          );
          return (
            <div key={idx}>
              <section className="two_container_list margin_bottom">
                <button
                  className={`margin_right ${
                    is_active ? "checklist_active" : "checklist_not"
                  }`}
                  onClick={this.on_toggle_tipe_1.bind(this, item_1)}
                />
                <p className="text_header">{item_1.TIPE_1}</p>
              </section>
              <br />
              {list_tipe_3_filtered.map((item_3, idx) => {
                const is_active = tipe_3_array.some(
                  (element) =>
                    element.TIPE_1 === item_3.TIPE_1 &&
                    element.TIPE_2 === item_3.TIPE_2 &&
                    element.TIPE_3 === item_3.TIPE_3
                );
                return (
                  <div
                    key={idx}
                    className="container_light margin_right margin_bottom column_size_small outline_transparent background_grey"
                  >
                    <div className="two_container_list">
                      <button
                        className={`margin_right ${
                          is_active ? "checklist_active" : "checklist_not"
                        }`}
                        onClick={this.on_toggle_tipe_3.bind(this, item_3)}
                      />
                      <p className="text_bold">{item_3.TIPE_3}</p>
                    </div>
                    <p className="text_small margin_top">({item_3.TIPE_2})</p>
                  </div>
                );
              })}
              <hr />
            </div>
          );
        })}
      </div>
    );

    const tipe_2_content = text_filter ? (
      list_tipe_2_filtered_primer.length === 0 &&
      list_tipe_2_filtered_sekunder.length === 0 ? (
        <p>No result</p>
      ) : (
        <div>
          {list_tipe_2_filtered_primer.map((item_2, idx) => {
            const is_active = tipe_2_array.some(
              (element) =>
                element.TIPE_1 === item_2.TIPE_1 &&
                element.TIPE_2 === item_2.TIPE_2
            );
            return (
              <div
                key={idx}
                className="container_light margin_right margin_bottom column_size_small outline_transparent background_grey"
              >
                <div className="two_container_list">
                  <button
                    className={`margin_right ${
                      is_active ? "checklist_active" : "checklist_not"
                    }`}
                    onClick={this.on_toggle_tipe_2.bind(this, item_2)}
                  />
                  <p className="text_small">{item_2.TIPE_2}</p>
                </div>
              </div>
            );
          })}
          {list_tipe_2_filtered_sekunder.length > 0 && (
            <>
              <br />
              <br />
              <br />
              <br />
              {list_tipe_3_filtered_sekunder.map((item_2, idx) => {
                const is_active = tipe_2_array.some(
                  (element) =>
                    element.TIPE_1 === item_2.TIPE_1 &&
                    element.TIPE_2 === item_2.TIPE_2
                );
                return (
                  <div
                    key={idx}
                    className="container_light margin_right margin_bottom column_size_small outline_transparent background_grey"
                  >
                    <div className="two_container_list">
                      <button
                        className={`margin_right ${
                          is_active ? "checklist_active" : "checklist_not"
                        }`}
                        onClick={this.on_toggle_tipe_2.bind(this, item_2)}
                      />
                      <p className="text_small">{item_2.TIPE_2}</p>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      )
    ) : (
      <div>
        {list_tipe_1.map((item_1, idx) => {
          const is_active = tipe_1_array.some(
            (element) => element.TIPE_1 === item_1.TIPE_1
          );
          const list_tipe_2_filtered = list_tipe_2.filter(
            (item_tipe_2) => item_tipe_2.TIPE_1 === item_1.TIPE_1
          );
          return (
            <div key={idx}>
              <section className="two_container_list margin_bottom">
                <button
                  className={`margin_right ${
                    is_active ? "checklist_active" : "checklist_not"
                  }`}
                  onClick={this.on_toggle_tipe_1.bind(this, item_1)}
                />
                <p className="text_bold">{item_1.TIPE_1}</p>
              </section>
              {list_tipe_2_filtered.map((item_2, idx) => {
                const is_active = tipe_2_array.some(
                  (element) =>
                    element.TIPE_1 === item_2.TIPE_1 &&
                    element.TIPE_2 === item_2.TIPE_2
                );
                return (
                  <div
                    key={idx}
                    className="container_light margin_right margin_bottom column_size_small outline_transparent background_grey"
                  >
                    <div className="two_container_list">
                      <button
                        className={`margin_right ${
                          is_active ? "checklist_active" : "checklist_not"
                        }`}
                        onClick={this.on_toggle_tipe_2.bind(this, item_2)}
                      />
                      <p className="text_small">{item_2.TIPE_2}</p>
                    </div>
                  </div>
                );
              })}
              <hr />
            </div>
          );
        })}
      </div>
    );

    return (
      <main
        className="parent_section"
        // style={{ height: "calc( 100vh - 70px)" }}
        style={{ height: "100%" }}
      >
        <section className="section_1">
          <h1 className="text_bold margin_bottom">
            Pilih tema POI (Point of Interest)
          </h1>
          {array_level_tipe.map((item, idx) => (
            <section
              className="container_light margin_right margin_bottom outline_transparent background_blue_light column_size_small"
              key={idx}
              onClick={this.on_set_tipe_level.bind(this, item.key)}
              style={{ width: "250px" }}
            >
              <button
                className={`button outline_black ${
                  tipe_level === item.key ? "background_black" : "button_white"
                }`}
              >
                {item.name}
              </button>
              <br />
              <p className="text_small">{item.description}</p>
            </section>
          ))}
          <section
            className="container_light margin_right margin_bottom outline_transparent background_blue_light column_size_small"
            style={{ width: "250px" }}
          >
            <button
              className="button_pill background_white margin_bottom margin_right"
              onClick={this.on_get_insight}
            >
              <span className="badge_circle background_black">
                <ICON_ARROW_RIGHT_LINE fill="#fff" />
              </span>{" "}
              Selanjutnya
            </button>
          </section>
          {(tipe_level === "tipe_2" || tipe_level === "tipe_3") && (
            <div>
              <form onSubmit={this.on_enter_filter} className="inline">
                <input
                  name="text_typing"
                  value={text_typing}
                  onChange={this.on_change}
                  className="column_size_small margin_bottom margin_right"
                  placeholder={
                    tipe_level === "tipe_1"
                      ? "Cari tipe 1"
                      : tipe_level === "tipe_2"
                      ? "Cari tipe 2"
                      : "Cari tipe 3"
                  }
                />
                <button
                  type="submit"
                  className="button_big background_black column_size_small margin_bottom margin_right"
                >
                  Filter
                </button>
              </form>
              {text_filter && (
                <button className="badge_pill_big background_grey inline">
                  <span className="text_small margin_right">{text_filter}</span>
                  <span
                    className="button_small background_black"
                    onClick={this.on_reset_filter}
                  >
                    X
                  </span>
                </button>
              )}
            </div>
          )}
        </section>
        <section className="section_2" id="section_2_insight_poi">
          <div className="container_light margin_right margin_bottom outline_grey background_white column_size_small">
            <section className="two_container_list">
              <button
                className={`margin_right ${
                  tipe_1_all ? "checklist_active" : "checklist_not"
                }`}
                onClick={this.toggle_tipe_1_all}
              />
              <p className="text_bold">ALL</p>
            </section>
          </div>
          {tipe_level === "tipe_1" ? (
            <div>
              {list_tipe_1.map((item, idx) => {
                const is_active = tipe_1_array.some(
                  (element) => element.TIPE_1 === item.TIPE_1
                );
                return (
                  <div key={idx}>
                    <div className="container_light margin_right margin_bottom column_size_medium background_grey outline_transparent">
                      <section className="two_container_list">
                        <button
                          className={`margin_right ${
                            is_active ? "checklist_active" : "checklist_not"
                          }`}
                          onClick={this.on_toggle_tipe_1.bind(this, item)}
                        />
                        <p className="text_bold">{item.TIPE_1}</p>
                      </section>
                    </div>
                    <br />
                  </div>
                );
              })}
            </div>
          ) : tipe_level === "tipe_2" ? (
            tipe_2_content
          ) : (
            tipe_3_content
          )}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  payment: state.payment,
  map: state.map,
  properties: state.properties,
  insight_reducer: state.insight_reducer,
  license_reducer: state.license_reducer,
});

export default connect(mapStateToProps, {
  set_value_insight,
  push_value_insight,
  get_insight_v2,
})(INPUT_TIPE_FULL);
