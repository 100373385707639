/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class TOOLBOX_DETAIL_DISTANCE extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props

    //content
    return (
      <section className="container_light margin_bottom">
        <p className="text_bold">Elevation</p>
        <p className="text_small">
          Measure elevation samples of the cross section along the line
        </p>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(TOOLBOX_DETAIL_DISTANCE);
