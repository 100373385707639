/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/

/*PICTURE*/
import ICON_ARROW_DOWN from "../../Assets/jsx/ICON_ARROW_DOWN";
import ICON_ARROW_UP from "../../Assets/jsx/ICON_ARROW_UP";

/*FUNCTION*/

/*DATA*/

/*CONST*/

class FORMULA_VIEW extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props
    const { array_group_insight, array_formula_insight } = this.props;

    //content

    const poi_content = (
      <section>
        <section id="tree_diagram">
          <div className="branch_tree_diagram">
            <article className="entry_tree_diagram">
              <span className="button_small background_black">Skor akhir</span>
              <div className="branch_tree_diagram">
                {array_group_insight.map((parent) => {
                  const child_array = array_formula_insight.filter(
                    (child) => child.group === parent.key
                  );
                  return (
                    <article className="entry_tree_diagram" key={parent.key}>
                      <span className="button_small background_black">
                        {parent.key}
                      </span>
                      <div className="branch_tree_diagram">
                        {child_array.map((child) => {
                          const { is_directly_proportional, weight_score } =
                            child;
                          return (
                            <article
                              className="entry_tree_diagram"
                              key={child.key}
                            >
                              <span className="badge background_grey_light outline_grey">
                                <section
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: "20px 30px auto",
                                    gridGap: "10px",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className={`badge_circle ${
                                      is_directly_proportional
                                        ? "background_green"
                                        : "background_red"
                                    }`}
                                  >
                                    {is_directly_proportional ? (
                                      <ICON_ARROW_UP line_color="#fff" />
                                    ) : (
                                      <ICON_ARROW_DOWN line_color="#fff" />
                                    )}
                                  </div>
                                  <div
                                    className="badge_small background_white outline_grey"
                                    id={child.key}
                                  >
                                    {weight_score}
                                  </div>
                                  <p className="text_small text_left">
                                    {child.key}
                                  </p>
                                </section>
                              </span>
                            </article>
                          );
                        })}
                      </div>
                    </article>
                  );
                })}
              </div>
            </article>
          </div>
        </section>
        <table className="table">
          <tbody>
            <tr>
              <td>Simbol</td>
              <td>Arti</td>
              <td>Keterangan</td>
            </tr>
            <tr>
              <td>
                <div className="badge_circle background_green">
                  <ICON_ARROW_UP line_color="#fff" />
                </div>
              </td>
              <td>Berbanding lurus</td>
              <td>
                Jika nilai dari parameter besar maka akan memperbesar skor
                akhir, gunakan ini jika ingin mencari area yang paling banyak
                mengandung nilai ini di dalamnya.
              </td>
            </tr>
            <tr>
              <td>
                <div className="badge_circle background_red">
                  <ICON_ARROW_DOWN line_color="#fff" />
                </div>
              </td>
              <td>Berbanding berbalik</td>
              <td>
                Jika nilai dari parameter kecil maka akan memperbesar skor
                akhir, gunakan ini jika ingin mencari area yang paling sedikit
                mengandung nilai ini di dalamnya.
              </td>
            </tr>
            <tr>
              <td>
                <button className="button button_white outline_grey">1</button>
              </td>
              <td>Bobot parameter</td>
              <td>
                Jika angka yang dimasukkan melebihi dari parameter lainnya maka
                pengaruhnya akan lebih besar dalam perhitungan skor akhir
                (begitu juga sebaliknya).
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    );

    return (
      <main className="max_height parent_section">
        <section className="section_1">
          <div className="two_container_auto">
            <aside>
              <h1 className="text_header">Detail formula tersimpan</h1>
            </aside>
          </div>
        </section>
        <section className="section_2">{poi_content}</section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(FORMULA_VIEW);
