const run_scoring = function ({
  features_grid,
  array_formula_insight,
  array_group_insight,
  config_final_score,
  set_value_insight,
  insight_update,
}) {
  function get_textual_score(score) {
    if (score >= 80) return "Sangat Sesuai";
    if (score >= 60) return "Sesuai";
    if (score >= 40) return "Cukup Sesuai";
    if (score >= 20) return "Tidak Sesuai";
    return "Sangat Tidak Sesuai";
  }

  array_formula_insight = array_formula_insight.map((formula) => {
    let array_value = [];
    features_grid.forEach((feature) => {
      const value = feature?.properties?.[formula.key] || 0;
      array_value.push(value);
    });
    return {
      ...formula,
      min_raw: Math.min(...array_value),
      max_raw: Math.max(...array_value),
    };
  });

  array_formula_insight.forEach((formula) => {
    const { min_raw, max_raw, key, is_directly_proportional } = formula;
    features_grid.forEach((feature) => {
      const value = feature.properties[key] || 0;
      let normalized_value;
      if (is_directly_proportional) {
        normalized_value = (value - min_raw) / (max_raw - min_raw);
      } else {
        normalized_value = 1 - (value - min_raw) / (max_raw - min_raw);
      }
      feature.properties[key + "_NORMAL"] = normalized_value;
    });
  });

  array_group_insight = array_group_insight.map((formula_group) => {
    const { key } = formula_group;
    const array_child = array_formula_insight.filter(
      (child) => child.group === key
    );
    const sum_array = array_child.map((item) => item.weight_score);
    const total_weight_score = sum_array.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return { ...formula_group, total_weight_score };
  });

  let array_value_raw = [];
  features_grid.forEach((feature) => {
    array_group_insight.forEach((formula_group) => {
      const {
        key: group_key,
        min,
        max,
        total_weight_score,
        is_directly_proportional,
      } = formula_group;

      // Get all formulas belonging to the group
      const array_child = array_formula_insight.filter(
        (child) => child.group === group_key
      );
      let group_score = 0;

      // Calculate the group score
      array_child.forEach((formula_child) => {
        const { key, weight_score } = formula_child;
        const normalized_value = feature.properties[`${key}_NORMAL`] || 0;

        // Apply proportionality
        const adjusted_value = is_directly_proportional
          ? normalized_value
          : 1 - normalized_value;
        group_score += adjusted_value * weight_score;
      });

      // Normalize group score by total weight
      const normalized_group_score = total_weight_score
        ? group_score / total_weight_score
        : 0;

      // Map the normalized group score to the group's range
      const mapped_group_score = normalized_group_score * (max - min) + min;

      // Save the group score in the feature's properties
      feature.properties[`${group_key}_score`] = mapped_group_score;
    });

    // Calculate the weighted raw score across all groups
    let raw_score = 0;
    let total_group_weights = 0;

    array_group_insight.forEach((formula_group) => {
      const { key: group_key, total_weight_score } = formula_group;
      const group_score = feature.properties[`${group_key}_score`] || 0;

      raw_score += group_score * total_weight_score;
      total_group_weights += total_weight_score;
    });

    // Normalize the raw score by total group weights
    const weighted_score = total_group_weights
      ? raw_score / total_group_weights
      : 0;

    // Save the weighted score in the feature's properties
    feature.properties["weighted_score"] = weighted_score;

    // Collect the raw scores for final normalization
    array_value_raw.push(weighted_score);
  });

  const { min, max } = config_final_score;
  const min_raw = Math.min(...array_value_raw);
  const max_raw = Math.max(...array_value_raw);
  features_grid.forEach((feature) => {
    const weighted_score = feature.properties["weighted_score"] || 0;
    const normalized_final_score =
      max_raw !== min_raw
        ? (weighted_score - min_raw) / (max_raw - min_raw)
        : 0;
    const mapped_final_score = normalized_final_score * (max - min) + min;
    const FINAL_STATUS_SCORE = get_textual_score(mapped_final_score);
    feature.properties["FINAL_NUMBER_SCORE"] = mapped_final_score;
    feature.properties["FINAL_STATUS_SCORE"] = FINAL_STATUS_SCORE;
  });
  set_value_insight({
    key: "features_grid",
    value: features_grid,
  });
  insight_update();
};

export default run_scoring;
