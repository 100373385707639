/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { set_value_toolbox } from "../../App/actions/toolboxActions";

/*PICTURE*/
import ICON_CHECK from "../../Assets/jsx/ICON_CHECK";

/*FUNCTION*/

/*DATA*/

/*CONST*/
const delay_close = 200; //milisecond

class GRID_SELECT_LAYER extends Component {
  state = {};

  on_click_layer = (layer_id) => {
    const key =
      this.props.toolbox.select_mode === "point"
        ? "layer_point_id"
        : "layer_polygon_id";

    if (layer_id) {
      const { toolbox_grid_object } = this.props.toolbox;
      toolbox_grid_object[key] = layer_id;

      this.props.set_value_toolbox({
        key: "toolbox_grid_object",
        value: toolbox_grid_object,
      });
    }
    setTimeout(() => {
      this.props.toggle_select();
    }, delay_close);
  };

  render() {
    //local storage

    //local state

    //global props
    const {
      layer_list_point_filtered,
      layer_list_polygon_filtered,
      toolbox_grid_object,
      select_mode,
    } = this.props.toolbox;

    //content
    const { layer_point_id, layer_polygon_id } = toolbox_grid_object;
    let array = [];
    let layer_id;
    if (select_mode === "point") {
      array = layer_list_point_filtered;
      layer_id = layer_point_id;
    } else {
      array = layer_list_polygon_filtered;
      layer_id = layer_polygon_id;
    }

    return (
      <main>
        <section className="max_height">
          <h1 className="margin_bottom">Pilih layer</h1>
          {array.map((item, idx) => {
            return (
              <div key={idx}>
                <button
                  className="button_pill background_grey_light margin_bottom"
                  onClick={this.on_click_layer.bind(this, item?._id)}
                >
                  <span
                    className={`badge_circle margin_right ${
                      layer_id === item._id
                        ? "background_black"
                        : "background_white"
                    }`}
                  >
                    {layer_id === item._id && <ICON_CHECK fill="#fff" />}
                  </span>
                  <span className="margin_right">{item?.name}</span>
                </button>
              </div>
            );
          })}
        </section>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  toolbox: state.toolbox,
  map: state.map,
  layer: state.layer,
});

export default connect(mapStateToProps, { set_value_toolbox })(
  GRID_SELECT_LAYER
);
