/*LIBRARY MODULE*/
import React, { Component } from "react";
import ReactDOMServer from "react-dom/server";
import { connect } from "react-redux";
import maplibregl from "maplibre-gl";
import centroid from "@turf/centroid";
import along from "@turf/along";
import calculate_length from "@turf/length";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/

/*NON IMPORT*/

class MARKER_GEO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      markers: [],
    };
  }

  componentDidUpdate(prevProps) {
    const status_action_after = this.props.map.status_action;
    const status_action_before = prevProps.map.status_action;

    const basemap_used_after = this.props.properties.basemap_used;
    const basemap_used_before = prevProps.properties.basemap_used;

    const { map_object } = this.props.layer;

    if (
      status_action_after !== status_action_before ||
      basemap_used_after !== basemap_used_before
    ) {
      if (map_object && map_object.isStyleLoaded()) {
        this.on_render();
      } else if (map_object) {
        map_object.once("styledata", this.on_render);
      }
    }
  }

  on_render = () => {
    let { geo_layer_list, layer_id_active } = this.props.layer;
    const { map_object } = this.props.map;
    if (!map_object) return;
    this.cleanup_markers();
    geo_layer_list.forEach((data) => {
      const type = data.geo_layer?.type;
      const features_filtered =
        data.geo_layer?.geojson_filtered?.features || [];
      const label_config = data.geo_layer?.label_config || {};
      const visibility = layer_id_active.includes(data.geo_layer?._id)
        ? "visible"
        : "none";
      if (
        (label_config?.is_use_label_text || label_config?.is_use_label_image) &&
        visibility === "visible"
      ) {
        let features_center_marker = this.calculate_marker_centers(
          type,
          features_filtered
        );

        this.render_label_markers({
          features_center_marker,
          label_config,
          fields: data.geo_layer.fields,
          map_object,
          type,
          visibility,
        });
      }
    });
  };

  calculate_marker_centers = (type, features_filtered) => {
    let features_center_marker = [];
    // Calculate marker centers based on geometry type
    if (
      ["Polygon", "MultiPolygon", "MultiLineString", "MultiPoint"].includes(
        type
      )
    ) {
      features_center_marker = features_filtered.map((feature) => {
        let feature_final = {};
        const geojson = {
          type: "FeatureCollection",
          features: [feature],
        };
        feature_final = centroid(geojson, { properties: feature.properties });
        return feature_final;
      });
    } else if (type === "Point") {
      features_center_marker = features_filtered;
    } else if (type === "LineString") {
      features_center_marker = features_filtered.map((feature) => {
        const length_km = calculate_length(feature, {
          units: "kilometers",
        }).toFixed(2);
        const center_length = length_km / 2;
        let point = along(feature, center_length, { units: "kilometers" });
        point.properties = feature.properties;
        return point;
      });
    }
    return features_center_marker;
  };

  render_label_markers = ({
    features_center_marker,
    label_config,
    fields,
    map_object,
    type,
    visibility,
  }) => {
    features_center_marker.forEach((feature) => {
      let label_final = this.create_label_content(
        feature,
        label_config,
        fields,
        type
      );
      if (visibility === "visible") {
        const markerEl = document.createElement("div");
        markerEl.innerHTML = ReactDOMServer.renderToString(label_final);
        const marker = new maplibregl.Marker({ element: markerEl })
          .setLngLat([
            feature.geometry.coordinates[0],
            feature.geometry.coordinates[1],
          ])
          .addTo(map_object);

        this.setState((prevState) => ({
          markers: [...prevState.markers, marker],
        }));
      } else {
        // Remove markers when not visible
        if (this.state && this.state.markers) {
          this.state.markers.forEach((marker) => marker.remove());
          this.setState({ markers: [] });
        }
      }
    });
  };

  create_label_content = (feature, label_config, fields, type) => {
    const label_text_field_key_array =
      label_config?.label_text_field_key_array || [];
    const label_image_field_key_array =
      label_config?.label_image_field_key_array || [];
    const style_mode = label_config?.style_mode || "style_1";
    const background_color_primary =
      label_config?.background_color_primary || "#292571";
    const outline_color_primary =
      label_config?.outline_color_primary || "#ffffff";
    const text_color_primary = label_config?.text_color_primary || "#ffffff";
    const background_color_secondary =
      label_config?.background_color_secondary || "#ee6b1e";
    const outline_color_secondary =
      label_config?.outline_color_secondary || "#ffffff";
    const text_color_secondary =
      label_config?.text_color_secondary || "#ffffff";

    let label_image;
    let label_text;
    if (label_config?.is_use_label_image) {
      label_image = (
        <div>
          {label_image_field_key_array.map((field_key, idx) => {
            let content;
            if (feature?.properties?.[field_key]) {
              content = (
                <div
                  key={idx}
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "5px",
                    backgroundImage: `url(${feature?.properties?.[field_key]})`,
                    backgroundSize: "cover",
                  }}
                  className="margin_bottom_minor margin_right_minor"
                />
              );
            } else {
              content = <div key={idx} />;
            }
            return content;
          })}
        </div>
      );
    }

    if (label_config?.is_use_label_text) {
      label_text = (
        <div>
          {label_text_field_key_array.map((field_key, idx) => {
            const field = fields.find((feature) => feature.key === field_key);
            const field_type = field?.type;
            let text = feature?.properties?.[field_key];
            if (["number"].includes(field_type)) {
              text = new Intl.NumberFormat("id-ID", {
                style: "decimal",
              }).format(text);
            } else if (field_type === "currency") {
              text =
                "Rp." +
                new Intl.NumberFormat("id-ID", { style: "decimal" }).format(
                  text
                );
            }
            let content;
            if (feature?.properties?.[field_key]) {
              content = (
                <div key={idx}>
                  <p
                    className={`margin_bottom_minor badge_pill_small text_small`}
                    style={{
                      color:
                        idx === 0 ? text_color_primary : text_color_secondary,
                      backgroundColor:
                        idx === 0
                          ? background_color_primary
                          : background_color_secondary,
                      borderColor:
                        idx === 0
                          ? outline_color_primary
                          : outline_color_secondary,
                      borderWidth: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    {text}
                  </p>
                </div>
              );
            } else {
              content = <div key={idx}></div>;
            }
            return content;
          })}
        </div>
      );
    }

    let label_final;
    if (type === "Point" || type === "MultiPoint") {
      label_final = (
        <div
          style={{ position: "absolute", transform: "translate(-50%, -100%)" }}
        >
          <main
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {label_image}
            {label_text}
            <div
              style={{ width: "2px", height: "25px", backgroundColor: "black" }}
            />
          </main>
        </div>
      );
    } else if (style_mode === "style_1") {
      label_final = (
        <>
          {label_image}
          {label_text}
        </>
      );
    } else {
      label_final = (
        <div>
          <div
            className="button_pill margin_bottom_minor"
            style={{
              color: text_color_primary,
              backgroundColor: background_color_primary,
              borderColor: outline_color_primary,
              borderWidth: "1px",
              borderStyle: "solid",
            }}
          >
            {label_image}
            {label_text}
          </div>
        </div>
      );
    }
    return label_final;
  };

  cleanup_markers = () => {
    this.state.markers.forEach((marker) => marker.remove());
    this.setState({ markers: [] });
  };

  render() {
    return <main></main>;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  map: state.map,
  properties: state.properties,
});

export default connect(mapStateToProps, {})(MARKER_GEO);
