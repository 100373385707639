/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";
import Menu from "@mui/material/Menu";

/*PERSONAL COMPONENT*/
import Modal from "../common_modal/Modal";
import EDIT_FOLDER_PROJECT from "./EDIT_FOLDER_PROJECT";
import DELETE_FOLDER_PROJECT from "./DELETE_FOLDER_PROJECT";
import MOVE_FOLDER_TO_FOLDER from "./MOVE_FOLDER_TO_FOLDER";

/*REDUX FUNCTION*/
import {
  push_value_project,
  set_value_project,
} from "../../App/actions/projectActions";
import {
  get_folder_list_by_parent_folder_id,
  get_project_list_by_folder_id,
} from "../../App/actions/folderProjectAction";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class FOLDER_PROJECT_MODE_BOX extends Component {
  state = {
    modal_menu: false,
    anchor: null,
    folder_id: "",
    folder: {},
    modal_edit_folder: false,
    modal_delete_folder: false,
    modal_move_folder: false,
  };

  on_click_folder = (folder) => {
    this.props.set_value_project({
      key: "folder_project_child_list",
      value: [],
    });
    this.props.set_value_project({
      key: "geo_project_list_child",
      value: [],
    });

    const folder_id = folder?._id;
    this.props.set_value_project({
      key: "folder_id_selected",
      value: folder_id,
    });
    this.props.set_value_project({
      key: "folder_object_selected",
      value: folder,
    });
    this.props.push_value_project({
      key: "folder_object_selected_list",
      value: folder,
    });
    this.props.push_value_project({
      key: "folder_id_selected_list",
      value: folder_id,
    });
    this.props.get_folder_list_by_parent_folder_id({
      folder_parent_id: folder_id,
    });
    this.props.get_project_list_by_folder_id({
      folder_id: folder_id,
    });
  };

  toggle_move_folder = () => {
    this.setState({
      modal_move_folder: !this.state.modal_move_folder,
      modal_menu: false,
    });
  };

  toggle_edit_folder = () => {
    this.setState({
      modal_edit_folder: !this.state.modal_edit_folder,
      modal_menu: false,
    });
  };

  toggle_delete_folder = () => {
    this.setState({
      modal_delete_folder: !this.state.modal_delete_folder,
      modal_menu: false,
    });
  };

  toggle_menu = (e) => {
    this.setState({ modal_menu: !this.state.modal_menu });
    if (e) {
      const {
        folder_project_list,
        folder_object_selected_list,
        folder_project_child_list,
      } = this.props.project;
      let folder_final_list =
        folder_object_selected_list.length > 0
          ? folder_project_child_list
          : folder_project_list;

      const folder = folder_final_list.find((item) => item._id === e.target.id);

      this.setState({
        anchor: e.currentTarget,
        folder_id: e.target.id,
        folder,
      });
    }
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state
    const {
      modal_menu,
      anchor,
      modal_edit_folder,
      modal_delete_folder,
      modal_move_folder,
      folder_id,
      folder,
    } = this.state;

    //global props
    const {
      folder_project_list,
      folder_project_child_list,
      folder_id_selected_list,
      loading_item_array,
    } = this.props.project;

    //content
    let folder_list_final = [];
    if (folder_id_selected_list.length === 0) {
      folder_list_final = folder_project_list;
    } else {
      folder_list_final = folder_project_child_list;
    }

    const modal_menu_content = modal_menu && (
      <Menu
        id="basic-menu"
        anchorEl={anchor}
        open={modal_menu}
        onClose={this.toggle_menu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        style={{ borderRadius: "15px" }}
      >
        <div style={{ padding: "7px 15px 0px 15px" }}>
          <button
            className="button margin_bottom button_white"
            onClick={this.toggle_move_folder}
          >
            {dict?.["Move to folder"]?.[language]}
          </button>
          <br />
          <button
            className="button margin_bottom button_white"
            onClick={this.toggle_edit_folder}
          >
            {dict?.["Edit"]?.[language]}
          </button>
          <br />
          <button
            className="button margin_bottom button_white"
            onClick={this.toggle_delete_folder}
          >
            {dict?.["Delete"]?.[language]}
          </button>
        </div>
      </Menu>
    );

    const modal_edit_folder_content = modal_edit_folder && (
      <Modal
        modalSize="small"
        isOpen={modal_edit_folder}
        onClose={this.toggle_edit_folder}
      >
        <div className="box-body" id="box-body">
          <EDIT_FOLDER_PROJECT
            folder={folder}
            toggle_edit_folder={this.toggle_edit_folder}
          />
        </div>
      </Modal>
    );

    const modal_delete_folder_content = modal_delete_folder && (
      <Modal
        modalSize="small"
        isOpen={modal_delete_folder}
        onClose={this.toggle_delete_folder}
      >
        <div className="box-body" id="box-body">
          <DELETE_FOLDER_PROJECT
            folder={folder}
            toggle_delete_folder={this.toggle_delete_folder}
          />
        </div>
      </Modal>
    );

    const modal_move_folder_content = modal_move_folder && (
      <Modal
        modalSize="medium"
        isOpen={modal_move_folder}
        onClose={this.toggle_move_folder}
      >
        <div className="box-body" id="box-body">
          <MOVE_FOLDER_TO_FOLDER
            folder={folder}
            toggle_move_folder={this.toggle_move_folder}
          />
        </div>
      </Modal>
    );

    let list_content;

    if (loading_item_array.includes("get_folder_list_by_parent_folder_id")) {
      list_content = <div className="container_light">Memuat...</div>;
    } else if (folder_list_final.length === 0) {
      list_content = (
        <section
          className="container_folder outline_red"
          style={{ height: "37px", position: "relative" }}
        >
          <p className="center_child text_medium">
            {dict?.["There are no folders"]?.[language]}
          </p>
        </section>
      );
    } else {
      list_content = (
        <section>
          {folder_list_final.map((folder, idx) => {
            return (
              <div
                className={`container_folder ${
                  folder._id === folder_id ? "outline_blue" : "outline_grey"
                }`}
                key={idx}
                data-mapid={`clickFolder-${idx}`}
              >
                <table className="full_width">
                  <tbody>
                    <tr>
                      <td
                        className="text_left cursor_pointer"
                        onClick={this.on_click_folder.bind(this, folder)}
                      >
                        <p
                          className="text_medium max_one_lines"
                          style={{
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                          title={folder?.name}
                        >
                          {folder?.name}
                        </p>
                      </td>
                      <td className="text_right" style={{ width: "30px" }}>
                        <button
                          onClick={this.toggle_menu}
                          id={folder._id}
                          className="button almost_white"
                        >
                          ⫶
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })}
        </section>
      );
    }

    return (
      <main className="margin_bottom">
        {modal_move_folder_content}
        {modal_delete_folder_content}
        {modal_edit_folder_content}
        {modal_menu_content}
        <h3 className="text_big margin_bottom">
          {dict?.["Folders"]?.[language]}
        </h3>
        {list_content}
        <hr
          style={{
            width: "100%",
            height: "4px",
            backgroundColor: "#a5a5a5",
            border: "none",
            borderRadius: "4px",
          }}
        />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.project,
});

export default connect(mapStateToProps, {
  push_value_project,
  set_value_project,
  get_folder_list_by_parent_folder_id,
  get_project_list_by_folder_id,
})(FOLDER_PROJECT_MODE_BOX);
