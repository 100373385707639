/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import ChartTemplate from "../viewer_chart/ChartTemplate";
import LEGEND_TEXT from "../map_legend/LEGEND_TEXT";
import FORMULA_VIEW from "./FORMULA_VIEW";

/*REDUX*/
import {
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
} from "../../App/actions/dataActions";

/*PICTURE*/
import close from "../../Assets/svg/close.svg";

/*FUNCTION*/

/*DATA*/

/*CONST*/

class RESULT_INSIGHT extends Component {
  state = {
    modal_view_formula: false,
  };

  toggle_view_formula = () => {
    this.setState({
      modal_view_formula: !this.state.modal_view_formula,
    });
  };

  on_filter_isi_layer = (field_key, value) => {
    const { layer_id } = this.props.layer;
    const body = {
      geo_layer_id: layer_id,
      field_key,
      value,
    };
    this.props.filter_isi_layer(body);
  };

  on_reset_filter = () => {
    const { layer_id } = this.props.layer;
    const body = { geo_layer_id: layer_id };
    this.props.reset_filter(body);
  };

  render() {
    //local storage

    //local state
    const { modal_view_formula } = this.state;

    //global props
    const { geo_layer_list, layer_id } = this.props.layer;
    const layer = geo_layer_list.find(
      (item) => item.geo_layer._id === layer_id
    )?.geo_layer;

    //result_content
    const insight_object = layer?.insight_object || {};
    const array_group_insight = insight_object?.array_group_insight || [];
    const array_formula_insight = insight_object?.array_formula_insight || [];
    let result_content = <>Belum memilih data</>;
    let chart_content = <></>;

    const modal_view_formula_content = modal_view_formula && (
      <Modal
        modalSize="medium"
        isOpen={modal_view_formula}
        onClose={this.toggle_view_formula}
      >
        <div className="box-body" id="box-body">
          <FORMULA_VIEW
            array_group_insight={array_group_insight}
            array_formula_insight={array_formula_insight}
          />
        </div>
      </Modal>
    );

    if (insight_object?.version === 2) {
      const {
        total_poi_initial,
        grid_mode,
        grid_counts,
        grid_width_meters,
        total_square_meters,
        provinsi,
        kota,
        kecamatan,
        kelurahan,
      } = insight_object;

      let place_mode;
      let place_text;

      if (kelurahan) {
        place_mode = "kelurahan";
        place_text =
          kelurahan + ", " + kecamatan + ", " + kota + ", " + provinsi;
      } else if (kecamatan) {
        place_mode = "kecamatan";
        place_text = kecamatan + ", " + kota + ", " + provinsi;
      } else if (kota) {
        place_mode = "kota";
        place_text = kota + ", " + provinsi;
      }

      result_content = (
        <section className="margin_bottom">
          <div className="badge background_green">
            Version <span className="badge_small background_white">2</span>
          </div>
          <p className="text_bold margin_bottom">
            Rangkuman insight di {place_text} ({place_mode})
          </p>
          <table className="table full_width">
            <tbody>
              <tr>
                <td>Jumlah titik POI termuat</td>
                <td>{total_poi_initial}</td>
              </tr>
              <tr>
                <td>Mode bentuk grid</td>
                <td>{grid_mode}</td>
              </tr>
              <tr>
                <td>Jumlah grid</td>
                <td>{grid_counts}</td>
              </tr>
              <tr>
                <td>Ukuran lebar grid (m)</td>
                <td>{parseInt(grid_width_meters)}</td>
              </tr>
              <tr>
                <td>
                  Total luas area analisis (meter<sup>2</sup>)
                </td>
                <td>{parseInt(total_square_meters)}</td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <button
                    className="button background_blue"
                    onClick={this.toggle_view_formula}
                  >
                    Lihat detail formula
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
      );
    } else if (insight_object?.is_insight) {
      const {
        kota,
        kecamatan,
        kelurahan,
        km_grid,
        total_grid,
        total_kelurahan,
        total_poi,
        demographic_parent,
        demographic_field_list,
      } = insight_object;

      let place;

      if (kelurahan) {
        place = kelurahan;
      } else if (kecamatan) {
        place = kecamatan;
      } else if (kota) {
        place = kota;
      }

      result_content = (
        <section>
          <div className="badge background_green">
            Version <span className="badge_small background_white">1</span>
          </div>
          <section className="margin_bottom">
            <p>
              <i>Insight</i> di{" "}
              <span className="badge background_white">{place}</span> adalah
              sebagai berikut.
            </p>
            <br />
            <p>
              <span className="badge background_white">{total_grid}</span>{" "}
              <i>grid</i> dengan ukuran{" "}
              <span className="badge background_white">{km_grid}</span> km telah
              berhasil di <i>generate</i> secara otomatis dan dilakukan skoring.
            </p>
            <p>
              Skoring dilakukan dengan metode <i>overlay</i> antara grid dengan
              data demografi populasi penduduk pada{" "}
              <span className="badge background_white">{total_kelurahan}</span>{" "}
              wilayah dan{" "}
              <span className="badge background_white">{total_poi}</span> data
              titik <i>point of interest</i> sesuai tema yang kamu pilih.
            </p>
            <table className="table full_width">
              <thead>
                <tr>
                  <th colSpan={2}>Parameter Demografi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tema</td>
                  <td>
                    {demographic_parent?.[0]
                      ? demographic_parent?.[0]
                      : "Belum memilih tema demografi"}
                  </td>
                </tr>
                <tr>
                  <td>Parameter terpilih</td>
                  <td>
                    {demographic_field_list?.map((item, idx) => {
                      return (
                        <p className="text_small margin_bottom" key={idx}>
                          {item}
                        </p>
                      );
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </section>
      );
    }

    //CHART
    if (insight_object?.is_insight) {
      const features = layer?.geojson?.features || [];
      const style_array = layer?.style_array || [];

      let legend_old;

      let chart_js_data;
      let color_array = [];
      let text_array = [];
      let number_array = [0, 0, 0, 0, 0];

      if (style_array.length > 0) {
        const default_style_key = layer?.default_style_key || "";
        const style_object = style_array.find(
          (item) => item.key === default_style_key
        );
        const style_type = style_object?.style_type;
        const paint_object = style_object?.paint_object || {};
        let color_key;
        if (style_type === "circle") {
          color_key = "circle-color";
        } else if (style_type === "line") {
          color_key = "line-color";
        } else if (style_type === "fill") {
          color_key = "fill-color";
        }
        let color_object = paint_object?.[color_key] || [];

        // Loop through color_object, starting from the 3rd element and stopping before the last
        for (let i = 2; i < color_object.length - 1; i += 2) {
          text_array.push(color_object[i]);
          color_array.push(color_object[i + 1]);
        }

        features.forEach((feature) => {
          const value =
            feature?.properties?.HASIL ||
            feature?.properties?.FINAL_STATUS_SCORE;
          const index = text_array.findIndex((item) => item === value);
          number_array[index] = number_array[index] + 1;
        });
      } else {
        if (features?.length > 0) {
          const { valueStyleProps } = layer;
          text_array = valueStyleProps?.range || [];
          color_array = valueStyleProps?.color || [];
          const selected_column = valueStyleProps?.selected_column;

          features.forEach((feature) => {
            const value =
              feature?.properties?.HASIL ||
              feature?.properties?.FINAL_STATUS_SCORE;
            const index = text_array.findIndex((item) => item === value);
            number_array[index] = number_array[index] + 1;
          });

          legend_old = (
            <table>
              <tbody>
                {text_array.map((item, idx) => {
                  return (
                    <tr
                      key={idx}
                      onClick={this.on_filter_isi_layer.bind(
                        this,
                        selected_column,
                        item
                      )}
                    >
                      <td>
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            backgroundColor: color_array[idx],
                          }}
                        />
                      </td>
                      <td>{item}</td>
                      <td>{number_array[idx]}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          );
        }
      }

      chart_js_data = {
        labels: text_array,
        datasets: [
          {
            label: "INSIGHT",
            labels: text_array,
            data: number_array,
            backgroundColor: color_array,
          },
        ],
      };

      chart_content = (
        <main>
          <ChartTemplate
            type="pie"
            width={100}
            height={100}
            style={{ width: "100px", margin: "auto" }}
            options={{
              hoverBackgroundColor: "#f38026",
              animation: false,
              maintainAspectRatio: true,
              responsive: false,
              legend: {
                display: false,
              },
              indexAxis: "x",
              title: {
                display: false,
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
            data={chart_js_data}
          />

          <br />
          <section className="container background_white">
            <table className="full_width">
              <tbody>
                <tr>
                  <td>
                    <p className="text_bold margin_bottom">Legenda</p>
                  </td>
                  <td className="text_right">
                    <img
                      className="cursor_pointer"
                      alt="close"
                      src={close}
                      style={{ height: "20px" }}
                      onClick={this.on_reset_filter}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            {style_array.length > 0 ? <LEGEND_TEXT /> : legend_old}
          </section>
        </main>
      );
    }

    return (
      <main className="container_light outline_transparent background_grey_light margin_bottom_extra">
        {modal_view_formula_content}
        <div className="badge_pill background_white margin_bottom">
          Rangkuman Hasil
        </div>
        <br />
        {result_content}
        {chart_content}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
});

export default connect(mapStateToProps, {
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
})(RESULT_INSIGHT);
