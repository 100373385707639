import React, { Component } from "react";
import { connect } from "react-redux";
import bbox from "@turf/bbox";
import maplibregl from "maplibre-gl";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import get_url_query from "../../App/validation/get_url_query";
import { set_value_properties } from "../../App/actions/propertiesActions";

const geojson_center_3d = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [108.221361, -7.3216],
      },
    },
  ],
};

class LIBRE_GLB_TASIK extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    if (this.props.layer.map_object !== prevProps.layer.map_object) {
      const mode = get_url_query("mode");
      if (mode === "stasiun_tasik") {
        this.on_render_content();
        this.on_fly();
      }
    }
  }

  on_render_content = () => {
    const { map_object } = this.props.layer;
    if (map_object !== null) {
      if (!map_object.isStyleLoaded()) {
        // If the style is not yet loaded, wait until it is
        map_object.once("styledata", () => {
          this.addMapContent(map_object);
        });
      } else {
        // If the style is already loaded, proceed immediately
        this.addMapContent(map_object);
      }
    }
  };

  // Helper function to handle the logic for adding the map content
  addMapContent = (map_object) => {
    // Adding 3D GLTF Model Layer
    this.addGLTFLayer(map_object);
  };

  addGLTFLayer = (map_object) => {
    const basemap_url = process.env.REACT_APP_MAPID_BASEMAP;
    const basemap_key = process.env.REACT_APP_BASEMAP_KEY;
    const style_url = `${basemap_url}/styles/basic/style.json?key=${basemap_key}`;

    if (map_object) {
      map_object.setStyle(style_url);
    }
    setTimeout(() => {
      this.props.set_value_properties({
        key: "basemap_used",
        value: "3d",
      });
    }, 1000);

    const id_3d_model = "3d-model-layer";

    // Check if the 3D model layer already exists
    if (!map_object.getLayer(id_3d_model)) {
      const modelOrigin = geojson_center_3d.features[0].geometry.coordinates;
      const modelAltitude = 0;
      const modelRotate = [Math.PI / 2, -0.8727, 0];

      const modelAsMercatorCoordinate =
        maplibregl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);

      // transformation parameters to position, rotate and scale the 3D model onto the map
      const modelTransform = {
        translateX: modelAsMercatorCoordinate.x,
        translateY: modelAsMercatorCoordinate.y,
        translateZ: modelAsMercatorCoordinate.z,
        rotateX: modelRotate[0],
        rotateY: modelRotate[1],
        rotateZ: modelRotate[2],
        /* Since our 3D model is in real world meters, a scale transform needs to be
         * applied since the CustomLayerInterface expects units in MercatorCoordinates.
         */
        scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
      };

      // configuration of the custom layer for a 3D model per the CustomLayerInterface
      const customLayer = {
        id: id_3d_model,
        type: "custom",
        renderingMode: "3d",
        onAdd(map, gl) {
          this.camera = new THREE.Camera();
          this.scene = new THREE.Scene();

          // create two three.js lights to illuminate the model
          const directionalLight = new THREE.DirectionalLight(0xffffff);
          directionalLight.position.set(0, -70, 100).normalize();
          this.scene.add(directionalLight);

          const directionalLight2 = new THREE.DirectionalLight(0xffffff);
          directionalLight2.position.set(0, 70, 100).normalize();
          this.scene.add(directionalLight2);

          // use the three.js GLTF loader to add the 3D model to the three.js scene
          const loader = new GLTFLoader();
          loader.load(
            "https://maps-icons.s3.ap-southeast-3.amazonaws.com/3d-model/3D+STASIUN+TASIKMALAYA.glb",
            (gltf) => {
              this.scene.add(gltf.scene);
            }
          );
          this.map = map;

          // use the MapLibre GL JS map canvas for three.js
          this.renderer = new THREE.WebGLRenderer({
            canvas: map.getCanvas(),
            context: gl,
            antialias: true,
          });

          this.renderer.autoClear = false;
        },
        render(gl, matrix) {
          const rotationX = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(1, 0, 0),
            modelTransform.rotateX
          );
          const rotationY = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 1, 0),
            modelTransform.rotateY
          );
          const rotationZ = new THREE.Matrix4().makeRotationAxis(
            new THREE.Vector3(0, 0, 1),
            modelTransform.rotateZ
          );

          const m = new THREE.Matrix4().fromArray(matrix);
          const l = new THREE.Matrix4()
            .makeTranslation(
              modelTransform.translateX,
              modelTransform.translateY,
              modelTransform.translateZ
            )
            .scale(
              new THREE.Vector3(
                modelTransform.scale,
                -modelTransform.scale,
                modelTransform.scale
              )
            )
            .multiply(rotationX)
            .multiply(rotationY)
            .multiply(rotationZ);

          this.camera.projectionMatrix = m.multiply(l);
          this.renderer.resetState();
          this.renderer.render(this.scene, this.camera);
          this.map.triggerRepaint();
        },
      };

      map_object.addLayer(customLayer);
    }
  };

  on_fly = () => {
    const { map_object } = this.props.layer;

    if (map_object) {
      const [min_longitude, min_latitude, max_longitude, max_latitude] =
        bbox(geojson_center_3d);

      // Step 1: Delay fitBounds by 3 seconds
      setTimeout(() => {
        map_object.fitBounds(
          [
            [min_longitude, min_latitude],
            [max_longitude, max_latitude],
          ],
          {
            maxZoom: 19.5,
            duration: 17_000,
            pitch: 0,
          }
        );

        // Step 2: Delay pitch change by 2 seconds after fitBounds completes
        map_object.once("moveend", () => {
          setTimeout(() => {
            map_object.easeTo({
              pitch: 67,
              duration: 2000,
            });

            // Step 3: Start continuous rotation after pitch change completes
            map_object.once("moveend", () => {
              this.startRotation(map_object);
            });
          }, 10);
        });
      }, 3000);
    }
  };

  // Continuous rotation function
  startRotation = (map_object) => {
    let rotateAnimation;

    // Start rotation only if it's not already running
    if (!rotateAnimation) {
      const rotate = () => {
        if (!map_object.isMoving()) {
          let bearing = map_object.getBearing();
          bearing += 0.15; // Adjust rotation speed by changing this value
          map_object.setBearing(bearing);
          rotateAnimation = requestAnimationFrame(rotate);
        }
      };
      rotateAnimation = requestAnimationFrame(rotate);
    }

    // Stop rotation when the user interacts with the map
    map_object.on("movestart", () => {
      if (rotateAnimation) {
        cancelAnimationFrame(rotateAnimation);
        rotateAnimation = null; // Clear the animation frame
      }
    });
  };

  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  layer: state.layer,
  properties: state.properties,
});

export default connect(mapStateToProps, { set_value_properties })(
  LIBRE_GLB_TASIK
);
