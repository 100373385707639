import React, { Component } from 'react'
import TextFieldGroup, { SelectField } from '../common_input/TextFieldGroup'

//General Function
import dict from "../../Data/dict.json";
import continents from "../../Data/continents.json"

export default class RegisterStep1 extends Component {
  render() {
    const language = localStorage?.language ? localStorage?.language : "ina";
    const continentLangs = language === "eng" ? continents : continents.map(item => { return { value: item?.value, title: item?.ina } })
    const { organization, continent, onChange, errors } = this.props;
    return (
      <>
        <TextFieldGroup
          Title={dict["Your organization name"][language]}
          placeholder={`${dict["Enter your organization name"][language]}`}
          name="organization"
          value={organization}
          onChange={onChange}
          error={errors?.organization}
        />
        <SelectField
          Title={dict["Where the organization operates"][language]}
          list_array={continentLangs}
          id="continent"
          name="continent"
          value={continent}
          onChange={onChange}
          error={errors?.continent}
          placeholder={`${dict["Choose continent"][language]}`}
        />

      </>
    )
  }
}
