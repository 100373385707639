/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import GRID_SELECT_LAYER from "./GRID_SELECT_LAYER";

/*REDUX*/
import {
  set_value_toolbox,
  status_toolbox,
} from "../../App/actions/toolboxActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class GRID_STEP_1 extends Component {
  state = {
    modal_select: false,
  };

  toggle_select = (event) => {
    const name = event?.target?.name;
    if (name) this.props.set_value_toolbox({ key: "select_mode", value: name });
    this.setState({
      modal_select: !this.state.modal_select,
    });
  };

  render() {
    //local storage

    //local state
    const { modal_select } = this.state;

    //global props
    const {
      //output
      toolbox_grid_object,
    } = this.props.toolbox;

    const {
      //input
      layer_point_id,
      layer_polygon_id,
    } = toolbox_grid_object;

    //content
    const modal_select_content = modal_select && (
      <Modal
        modalSize="medium"
        isOpen={modal_select}
        onClose={this.toggle_select}
      >
        <div className="box-body" id="box-body">
          <GRID_SELECT_LAYER toggle_select={this.toggle_select} />
        </div>
      </Modal>
    );

    return (
      <main>
        {/* step 1 */}
        {modal_select_content}
        <div className="container_light background_grey_light outline_transparent margin_bottom">
          <h2 className="text_bold">Pilih layer titik</h2>
          <p className="text_small">Contoh: titik pengunjung</p>
          <br />
          <p className="text_small">{layer_point_id}</p>
          <br />
          <button
            className="button margin_bottom"
            name="point"
            onClick={this.toggle_select}
          >
            Pilih layer
          </button>
          <h2 className="text_bold">Pilih layer poligon</h2>
          <p className="text_small">Contoh: poligon batas bangunan</p>
          <br />
          <p className="text_small">{layer_polygon_id}</p>
          <br />
          <button
            className="button"
            name="polygon"
            onClick={this.toggle_select}
          >
            Pilih layer
          </button>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  toolbox: state.toolbox,
  map: state.map,
  layer: state.layer,
});

export default connect(mapStateToProps, {
  set_value_toolbox,
  status_toolbox,
})(GRID_STEP_1);
