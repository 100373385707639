/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import {
  set_geojson_filtered,
  set_value_toolbox,
} from "../../App/actions/toolboxActions";

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class TOOLBOX_DETAIL_ISOKRON extends Component {
  state = {
    modal_warn: false,
    modal_warn_no_layer_selected: false,
    distance: false,
  };

  toggle_warn = () => {
    this.setState({ modal_warn: !this.state.modal_warn });
  };

  toggle_warn_no_layer_selected = () => {
    this.setState({
      modal_warn_no_layer_selected: !this.state.modal_warn_no_layer_selected,
    });
  };

  on_reset = () => {
    const { layer_id, geo_layer_list } = this.props.layer;
    const layer_object = geo_layer_list?.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;
    const features_filtered = layer_object.geojson.features;
    const body = {
      geo_layer_id: layer_id,
      features_filtered,
    };
    this.props.set_geojson_filtered(body);
  };

  on_change = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    this.props.set_value_toolbox({ key: name, value: value });
  };

  render() {
    const language = localStorage?.language || "ina";
    let { routing_profile, contour_type, contours_minutes, contours_meters } =
      this.props.toolbox;

    routing_profile = routing_profile || "driving";
    contour_type = contour_type || "minutes";
    contours_minutes = contours_minutes || 5;
    contours_meters = contours_meters || 500;

    /*
      routing_profile
      contours_meters
      contours_minutes

      contour_type
      */

    return (
      <>
        <p>{dict["Transportation mode"][language]}:</p>
        <select
          name="routing_profile"
          value={routing_profile}
          onChange={this.on_change}
          data-mapid="selectRouting"
        >
          <option value="driving">{dict["Driving"][language]}</option>
          <option value="driving-traffic">
            {dict["Driving traffict"][language]}
          </option>
          <option value="walking">{dict["Walking"][language]}</option>
          <option value="cycling">{dict["Cycling"][language]}</option>
        </select>
        <p> {dict["Distance/time parameters"][language]}: </p>
        <select
          name="contour_type"
          value={contour_type}
          onChange={this.on_change}
          data-mapid="selectContour"
        >
          <option value="minutes">minutes</option>
          <option value="meters">meters</option>
        </select>

        {contour_type === "minutes" ? (
          <>
            <p>{dict["Driving time"][language]}</p>
            <input
              name="contours_minutes"
              value={contours_minutes}
              onChange={this.on_change}
              type="range"
              min={1}
              max={100}
              step={1}
            />
            <input
              className="margin_bottom"
              name="contours_minutes"
              value={contours_minutes}
              onChange={this.on_change}
              type="number"
              min={1}
              data-mapid="inputContoursTime"
            />
          </>
        ) : (
          <>
            <p>{dict["Driving distance"][language]}</p>
            <input
              name="contours_meters"
              value={contours_meters}
              onChange={this.on_change}
              type="range"
              min={1000}
              max={10000}
              step={100}
            />
            <input
              className="margin_bottom"
              name="contours_meters"
              value={contours_meters}
              onChange={this.on_change}
              type="number"
              min={1000}
              data-mapid="inputContoursDistance"
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  toolbox: state.toolbox,
});

export default connect(mapStateToProps, {
  set_geojson_filtered,
  set_value_toolbox,
})(TOOLBOX_DETAIL_ISOKRON);
