import React, { Component } from "react";
import { connect } from "react-redux";

import { set_value_genangan } from "../../App/actions/genangan_actions";

class LAYER_TITIK_DEM extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    const genangan_update_before = prevProps.genangan_reducer.genangan_update;
    const genangan_update_after = this.props.genangan_reducer.genangan_update;

    if (genangan_update_before !== genangan_update_after) {
      this.on_render();
    }
  }
  on_render() {
    const { map_object } = this.props.layer;
    if (map_object !== null) {
      this.addMapContent(map_object);
    }
  }
  addMapContent = (map_object) => {
    const {
      geojson_contour_notflooded,
      geojson_contour_flooded,
      is_dem_visible,
    } = this.props.genangan_reducer;

    const id_map_flooded = "geojson_contour_flooded";
    const id_map = "geojson_contour_notflooded";

    // Tambahkan atau perbarui source dan layer untuk flooded
    if (!map_object.getSource(id_map_flooded)) {
      map_object.addSource(id_map_flooded, {
        type: "geojson",
        data: geojson_contour_flooded,
      });
    } else {
      map_object.getSource(id_map_flooded).setData(geojson_contour_flooded);
    }

    if (!map_object.getLayer(id_map_flooded)) {
      map_object.addLayer({
        id: id_map_flooded,
        source: id_map_flooded,
        type: "circle",
        paint: {
          "circle-radius": 3,
          "circle-color": "red",
          "circle-stroke-width": 1,
          "circle-stroke-color": "black",
          "circle-stroke-opacity": 0.3,
          "circle-opacity": 0.3,
        },
        layout: { visibility: "none" }, // Default visibility
      });
    }
    map_object.setLayoutProperty(
      id_map_flooded,
      "visibility",
      is_dem_visible ? "visible" : "none"
    );

    // Tambahkan atau perbarui source dan layer untuk not flooded
    if (!map_object.getSource(id_map)) {
      map_object.addSource(id_map, {
        type: "geojson",
        data: geojson_contour_notflooded,
      });
    } else {
      map_object.getSource(id_map).setData(geojson_contour_notflooded);
    }

    if (!map_object.getLayer(id_map)) {
      map_object.addLayer(
        {
          id: id_map,
          source: id_map,
          type: "circle",
          paint: {
            "circle-radius": 3,
            "circle-color": "green",
            "circle-stroke-width": 1,
            "circle-stroke-color": "black",
            "circle-stroke-opacity": 0.3,
            "circle-opacity": 0.3,
          },
          layout: { visibility: "none" }, // Default visibility
        },
        id_map_flooded
      );
    }
    map_object.setLayoutProperty(
      id_map,
      "visibility",
      is_dem_visible ? "visible" : "none"
    );
  };

  // addMapContent = (map_object) => {
  //   const { geojson_contour_notflooded, genangan_mdpl, geojson_contour_flooded } =
  //     this.props.genangan_reducer;

  //   const id_map_flooded = "geojson_contour_notflooded";
  //   const id_map = "geojson_contour_notflooded";

  //   if (!map_object.getSource(id_map_flooded)) {
  //     map_object.addSource(id_map_flooded, {
  //       type: "geojson",
  //       data: geojson_contour_flooded,
  //     });
  //   } else {
  //     map_object.getSource(id_map_flooded).setData(geojson_contour_flooded);
  //   }

  //   if (!map_object.getLayer(id_map_flooded)) {
  //     map_object.addLayer({
  //       id: id_map_flooded,
  //       source: id_map_flooded,
  //       type: "circle",

  //       paint: {
  //         "circle-radius": 3,
  //         "circle-color": "red",
  //         "circle-stroke-width": 1,
  //         "circle-stroke-color": "black",
  //         "circle-stroke-opacity": 0.3,
  //         "circle-opacity": 1,
  //       },

  //       layout: { visibility: "visible" },
  //     });
  //   }

  //   if (!map_object.getSource(id_map)) {
  //     map_object.addSource(id_map, {
  //       type: "geojson",
  //       data: geojson_contour_notflooded,
  //     });
  //   } else {
  //     map_object.getSource(id_map).setData(geojson_contour_notflooded);
  //   }

  //   if (!map_object.getLayer(id_map)) {
  //     map_object.addLayer(
  //       {
  //         id: id_map,
  //         source: id_map,
  //         type: "circle",

  //         paint: {
  //           "circle-radius": 3,
  //           "circle-color": "green",
  //           "circle-stroke-width": 1,
  //           "circle-stroke-color": "black",
  //           "circle-stroke-opacity": 0.3,
  //           "circle-opacity": 0.3,
  //         },

  //         layout: { visibility: "visible" },
  //       },
  //       id_map_flooded
  //     );
  //   }
  // };
  render() {
    return <main />;
  }
}

const mapStateToProps = (state) => ({
  genangan_reducer: state.genangan_reducer,
  layer: state.layer,
});

export default connect(mapStateToProps, { set_value_genangan })(
  LAYER_TITIK_DEM
);
