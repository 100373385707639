import React, { Component } from 'react'
import { StyledDataGrid } from '../../Style/StyledDataGrid'
// import is_not_empty from '../../App/validation/is_not_empty';

export default class NaNEvidence extends Component {
  // constructor(props){
  //   super(props);
  //   this.state = {

  //   }
  // }


  componentDidMount = () => {
    const { target_data_types, set_nan_evidence } = this.props;
    const file = this.props.file

    let nan_data = {}
    file.data?.features?.forEach((feature, index) => {
      target_data_types?.forEach(item => {
        // console.log(isNaN(feature?.["properties"][item.fields]), item)
        if (isNaN(feature?.["properties"][item.fields]) && item.dataType === "number") {
          if (Array.isArray(nan_data[item.fields])) {
            nan_data[item.fields].push({
              "id": index + 1,
              "no": index + 1,
              "from": feature?.["properties"][item.fields],
              "to": 0,
            })
          } else {
            nan_data[item.fields] = [{
              "id": index + 1,
              "no": index + 1,
              "from": feature?.["properties"][item.fields],
              "to": 0,
            }]
          }
        }
      })
    });

    set_nan_evidence(nan_data)

  }

  get_columns = () => {
    return [
      {
        field: "no",
        headerName: "No.",
        editable: false,
        flex: 1,
        type: "number",
      },
      {
        field: "from",
        headerName: "From",
        editable: false,
        flex: 3,
      },
      {
        field: "to",
        headerName: "To",
        editable: false,
        flex: 2,
      },
    ];
  }


  render() {
    const { nan_evidence } = this.props;
    return (
      <div>
        {
          Object.keys(nan_evidence).map((item, idx) =>
            <section key={idx} className='marginTop_20'>
              <label>{item}</label>
              <div className='h_300'>
                <StyledDataGrid
                  rows={nan_evidence?.[item]}
                  columns={this.get_columns()}
                  getRowHeight={() => 35}
                  rowsPerPageOptions={[25, 50, 100, 500, 1000]}
                  disableColumnMenu
                />
              </div>
            </section>
          )
        }
      </div>
    )
  }
}
