import React from "react";

const ICON_CHECK = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={10.338}
      height={7.469}
      viewBox="0 0 2.735 1.976"
      {...props}
    >
      <path
        d="M2.47.264 1.004 1.712.265.78"
        style={{
          fill: "none",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 0.52918,
          stroke: props.line_color || "#fff",
        }}
      />
    </svg>
  );
};

export default ICON_CHECK;
