import React, { Component, createRef } from 'react'
import { connect } from 'react-redux';
import { cell_ref, get_sheets_by_cell } from '../../App/actions/sheetAction';
import { convertExcelDataToHandsontableFormat, setStyleHandsonTable } from '../../App/validation/convert_data';

import 'handsontable/dist/handsontable.full.min.css';
// import { registerAllModules } from 'handsontable/registry';
import { HotTable } from '@handsontable/react';
import SheetButton from './SheetButton';
import { numberToColumn } from '../../App/validation/calculateExcelFormula';
import { Button } from '@mui/material';


class RefSelection extends Component {
  constructor(props) {
    super(props);
    this.hotTableRef = createRef(null);
    this.state = {
      selected_column: "",
      worksheet: null,
      sheetnames: [],
      selectedSheet: "",
      selectedCell: null,
    }
  }

  componentDidMount = async () => {
    const { geo_layer_id, feature_key, filtered_fields } = this.props;
    const field_key = filtered_fields?.[0]?.key
    this.setState({ selected_column: field_key });
    if (field_key) {
      const body = {
        geo_layer_id,
        feature_key,
        field_key: field_key
      }

      const data = await this.props.get_sheets_by_cell(body);
      const sheetnames = data?.map(item => ({ _id: item?._id, ...item?.metadata }))?.sort(item => item?.order);

      this.setState({
        worksheet: data?.length > 0 ? data : null,
        sheetnames: sheetnames,
        selectedSheet: sheetnames?.[0]?.id_ori || ""
      });

    } else {
      this.setState({
        worksheet: null,
        sheetnames: [],
        selectedSheet: "",
        selectedCell: null
      })
    }
  }

  handle_selected_cell = ({ startRow, startCol }) => {
    const { worksheet, selectedSheet } = this.state;
    const row = startRow;
    const col = startCol;
    const column = numberToColumn(col + 1)
    const cellRef = `${column}${row + 1}`
    const sheet = worksheet?.find(item => item?.metadata?.id_ori === selectedSheet);
    const cell = sheet?.value?.find(item => item?.p === cellRef);
    if (cell) {
      this.setState({
        selectedCell: cell
      });
    }
  }

  handle_selected_column = async (field_key) => {
    const { geo_layer_id, feature_key } = this.props;
    this.setState({ selected_column: field_key });
    if (field_key) {
      const body = {
        geo_layer_id,
        feature_key,
        field_key
      }

      const data = await this.props.get_sheets_by_cell(body);
      const sheetnames = data?.map(item => ({ _id: item?._id, ...item?.metadata }))?.sort(item => item?.order);

      this.setState({
        worksheet: data?.length > 0 ? data : null,
        sheetnames: sheetnames,
        selectedSheet: sheetnames?.[0]?.id_ori || ""
      });

    } else {

      this.setState({
        worksheet: null,
        sheetnames: [],
        selectedSheet: "",
        selectedCell: null
      })
    }

  }

  handle_save = async () => {
    const {
      target_sheet_id,
      target_cell_ref,
      setWorksheet,
      // currentWorksheet

      geo_layer_id,
      feature_key,
      field_key
    } = this.props;

    const {
      selectedCell,
      // selected_column, mnvghv
      worksheet,
      selectedSheet
    } = this.state;


    const ref_sheet = worksheet?.find(item => item?.metadata?.id_ori === selectedSheet);

    const body = {
      target_sheet_id,
      target_cell: target_cell_ref,

      ref_feature_key: ref_sheet?.feature_key,
      ref_field_key: ref_sheet?.field_key,
      ref_geo_layer_id: ref_sheet?.geo_layer_id,
      ref_sheet_id: ref_sheet?._id,
      ref_cell_ref: selectedCell?.p
    }

    const res = await this.props.cell_ref(body);
    if (res?.status === 200) {
      const res_sheet = await this.props.get_sheets_by_cell({
        geo_layer_id,
        feature_key,
        field_key
      })

      if (res_sheet) {
        setWorksheet(res_sheet)
      }
    }
  }

  setSelectedSheet = (value) => {
    this.setState({
      selectedSheet: value
    })
  }

  setSheetnames = (values) => {
    this.setState({
      sheetnames: values
    })
  }

  setWorksheet = (value) => {
    this.setState({
      worksheet: value
    })
  }


  render() {

    const { filtered_fields } = this.props;
    const { worksheet, selectedSheet, sheetnames, selected_column, selectedCell } = this.state;

    const sheet = worksheet?.find(obj => obj.metadata.id_ori === selectedSheet) || {};
    const { data, columns } = convertExcelDataToHandsontableFormat({ sheet, selectedSheet, rawData: worksheet })

    return (
      <div>
        <label className='semi_bold font_30 center_perfect'>Select a cell that you want to reference</label>
        <div className='w_full scroll_x_auto'>
          <div className='w_full flex_row flex align_center no_wrap gap_10 h_50'>
            <label>Fields:</label>
            {
              filtered_fields?.map((field, idx) =>
                <div
                  key={idx}
                  className={`pointer hover_bigger ${selected_column === field?.key ? "underline" : ""}`}
                  onClick={() => {
                    this.handle_selected_column(field?.key)
                  }}>
                  {field?.name}
                </div>)
            }
          </div>
        </div>

        <HotTable
          style={{
            border: "1px solid #AAA",
            borderRadius: "4px"
          }}
          cells={(row, col) => setStyleHandsonTable({ row, col, data, sheet, worksheet, selectedSheet })}
          ref={this.hotTableRef}
          width="100%"
          data={data}
          columns={columns}
          header
          rowHeaders={true}
          colHeaders={true}
          height={(55 / 100) * window.innerHeight}
          licenseKey="non-commercial-and-evaluation" // for non-commercial use only
          settings={{
            minSpareRows: 5,
            minSpareCols: 5, // Tambahkan baris kosong di akhir
            contextMenu: true, // Menampilkan opsi konteks (cut, copy, paste)
            manualColumnResize: true,
            manualRowResize: true,
          }}
          selectionMode='multiple'
          afterSelectionEnd={(startRow, startCol, endRow, endCol) => {
            this.handle_selected_cell({ startRow, startCol, endRow, endCol })
          }}
          afterDeselect={(e) => {
            this.setState({ focused: null })
          }}
          // outsideClickDeselects={(e) => { return (e?.id === "save_button") ? false : true }}
          outsideClickDeselects={(e) => { return false }}
          // afterSelection={(startRow, startCol, endRow, endCol) => {
          //   this.handle_selected_cell({ startRow, startCol, endRow, endCol })
          // }}
          modifyColWidth={(width) => {
            if (width > 300) {
              return 300
            }
          }}
        />

        {worksheet &&
          <div className='w_full scroll_x_auto'>
            <div className='w_full flex_row flex align_center no_wrap gap_10 h_50'>
              <label>Sheet:</label>
              {sheetnames?.map(item => (
                <SheetButton
                  key={item?.id_ori}
                  sheetname={item}
                  selectedSheet={selectedSheet}
                  setSheetnames={this.setSheetnames}
                  sheetnames={sheetnames}
                  setSelectedSheet={this.setSelectedSheet}
                  worksheet={worksheet}
                  setWorksheet={this.setWorksheet}
                />
              ))}
            </div>
          </div>
        }

        {
          selectedCell &&
          <Button
            id="save_button"
            onClick={() => {
              this.handle_save();
            }}
            variant='contained'
            style={{ width: "100%" }}>
            Save
          </Button>
        }

      </div>
    )
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  get_sheets_by_cell,
  cell_ref
})(RefSelection);