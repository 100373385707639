/* eslint-disable */

const HEXAGON_RADIUS = 500 / Math.sqrt(3); // Convert 500m width to radius
const SQRT_3 = Math.sqrt(3);

// Function to convert latitude and longitude to a point in a 2D plane
function lat_lon_to_xy(lat, lon) {
  const R = 6371e3; // Earth's radius in meters
  const x = (R * lon * Math.PI) / 180;
  const y = R * Math.log(Math.tan(Math.PI / 4 + (lat * Math.PI) / 360));
  return { x, y };
}

// Function to convert a point in a 2D plane to latitude and longitude
function xy_to_lat_lon(x, y) {
  const R = 6371e3; // Earth's radius in meters
  const lon = x / ((R * Math.PI) / 180);
  const lat = ((2 * Math.atan(Math.exp(y / R)) - Math.PI / 2) * 180) / Math.PI;
  return { lat, lon };
}

// Function to determine the grid ID for a given point (latitude and longitude)
function get_grid_id(lat, lon) {
  const { x, y } = lat_lon_to_xy(lat, lon);
  const q = Math.round((x * 2) / 3 / HEXAGON_RADIUS);
  const r = Math.round((-x / 3 + (SQRT_3 / 3) * y) / HEXAGON_RADIUS);
  return { q, r };
}

// Function to get the center of a hexagon grid cell
function get_grid_center(grid_id) {
  const x = ((HEXAGON_RADIUS * 3) / 2) * grid_id.q;
  const y = HEXAGON_RADIUS * SQRT_3 * (grid_id.r + grid_id.q / 2);
  return xy_to_lat_lon(x, y);
}

// Function to get the neighboring grids
function get_neighboring_grids(grid_id, ring_level) {
  const directions = [
    [1, 0],
    [1, -1],
    [0, -1],
    [-1, 0],
    [-1, 1],
    [0, 1],
  ];

  const results = [];
  for (let i = 1; i <= ring_level; i++) {
    let q = grid_id.q + directions[4][0] * i;
    let r = grid_id.r + directions[4][1] * i;

    for (let j = 0; j < 6; j++) {
      for (let k = 0; k < i; k++) {
        results.push({ q, r });
        q += directions[j][0];
        r += directions[j][1];
      }
    }
  }
  return results;
}

// Function to get all grid IDs inside a bounding box
function get_grids_in_bounding_box({ min_lat, min_long, max_lat, max_long }) {
  const { x: x1, y: y1 } = lat_lon_to_xy(min_lat, min_long);
  const { x: x2, y: y2 } = lat_lon_to_xy(max_lat, max_long);

  const min_x = Math.min(x1, x2);
  const max_x = Math.max(x1, x2);
  const min_y = Math.min(y1, y2);
  const max_y = Math.max(y1, y2);

  const min_q = Math.floor((min_x * 2) / 3 / HEXAGON_RADIUS);
  const max_q = Math.ceil((max_x * 2) / 3 / HEXAGON_RADIUS);
  const min_r = Math.floor(
    (-max_x / 3 + (SQRT_3 / 3) * min_y) / HEXAGON_RADIUS
  );
  const max_r = Math.ceil((-min_x / 3 + (SQRT_3 / 3) * max_y) / HEXAGON_RADIUS);

  const grid_ids = [];

  for (let q = min_q; q <= max_q; q++) {
    for (let r = min_r; r <= max_r; r++) {
      const center = get_grid_center({ q, r });
      if (
        center.lat >= Math.min(min_lat, max_lat) &&
        center.lat <= Math.max(min_lat, max_lat) &&
        center.lon >= Math.min(min_long, max_long) &&
        center.lon <= Math.max(min_long, max_long)
      ) {
        grid_ids.push({ q, r });
      }
    }
  }
  return grid_ids;
}

// Function to check if a point is inside a polygon
function is_point_in_polygon(point, polygon) {
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].lon,
      yi = polygon[i].lat;
    const xj = polygon[j].lon,
      yj = polygon[j].lat;

    const intersect =
      yi > point.lat !== yj > point.lat &&
      point.lon < ((xj - xi) * (point.lat - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
}

// Function to get all grid IDs within a polygon feature and group them
function get_grids_in_polygon({ polygon, grouping_number }) {
  // Convert polygon coordinates from [lon, lat] to { lat, lon }
  const polygon_points = polygon.map(([lon, lat]) => ({ lat, lon }));

  // Find bounding box of the polygon
  const lats = polygon_points.map((point) => point.lat);
  const lons = polygon_points.map((point) => point.lon);
  const min_lat = Math.min(...lats);
  const max_lat = Math.max(...lats);
  const min_lon = Math.min(...lons);
  const max_lon = Math.max(...lons);

  // Get grid IDs in the bounding box of the polygon
  const grids_in_bbox = get_grids_in_bounding_box({
    min_lat,
    min_long: min_lon,
    max_lat,
    max_long: max_lon,
  });

  // Filter grids that are inside the polygon
  const grids_in_polygon = grids_in_bbox.filter((grid_id) => {
    const center = get_grid_center(grid_id);
    return is_point_in_polygon(center, polygon_points);
  });

  // Group the results based on the grouping_number
  const grouped_result = [];
  for (let i = 0; i < grids_in_polygon.length; i += grouping_number) {
    const group = grids_in_polygon.slice(i, i + grouping_number);
    grouped_result.push(group);
  }

  return grouped_result;
}

const grid_functions = {
  get_grid_id,
  get_neighboring_grids,
  get_grids_in_bounding_box,
  get_grids_in_polygon,
};

export default grid_functions;
