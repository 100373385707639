import axios from "axios";
import bbox from "@turf/bbox";
import points_within_polygon from "@turf/points-within-polygon";
import {
  get_quota_access,
  get_quota_access_sini_ai,
  get_quota_ai_chat,
} from "./authActions";
import aggregate_property_price from "../validation/aggregate_property_price";
import { fly_init_update, fly_init_marker } from "./sini_v2";
import { create_ai_sini_sheet } from "./ai_excel";

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

/**
 * @route : get_sini_data
 * @endpoint : /sini_v2/get_sini_data
 * @methode : get (multiple)
 * @params :
              lat,
              long,
              request_id,
              sini_data_mode,
              show_survey_1,

              is_from_excel,
              geo_layer_id,
              feature_key,
              field_key,
 * @body : -
 */
//get_sini_data
export const get_sini_data = (params) => async (dispatch) => {
  try {
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "sini_marker_active",
        value: params,
      },
    });
    dispatch({
      type: "set_loading_action",
      payload: "sini_load_parent",
    });

    const {
      lat,
      long,
      request_id,
      sini_data_mode,
      show_survey_1,
      is_from_excel,
      geo_layer_id,
      feature_key,
      field_key,
    } = params;
    let { km_rad } = params;

    //atur coordinates_loading
    dispatch({
      type: "set_value_sini",
      payload: {
        key: "coordinates_loading",
        value: [long, lat],
      },
    });

    let action_array = [];
    let geojson_polygon_isochrone = {};

    if (sini_data_mode === "sini_general") {
      action_array = [
        "demography_one",
        "people_spending",
        "landvalue",
        "landzone",
        "poi_property",
        "flood",
        "landslide",
        "tsunami",
        "demography_neighbor",
        "poi",
      ];
      if (show_survey_1) action_array.push("poi_survey_1_features");
    } else {
      action_array = ["demography_one", "rdtr"];
    }

    dispatch({
      type: "set_value_sini",
      payload: {
        key: "total_step",
        value: action_array.length,
      },
    });

    km_rad = km_rad || 1;
    let title_1 = "Untitled";
    let title_2 = "Untitled";

    const sini_init = {
      request_id,
      lat,
      long,
      km_rad,
    };
    //to do: langsung bisa pakai ai_parent_list, ai_parent_detail_list, ai_parent_child_list
    //kemungkinan sini_v2_list sudah tidak dipakai lagi

    dispatch({
      type: "push_value_sini",
      payload: {
        key: "ai_parent_list",
        value: [sini_init],
      },
    });
    dispatch({
      type: "push_value_sini",
      payload: {
        key: "ai_parent_detail_list",
        value: [sini_init],
      },
    });
    dispatch({
      type: "push_value_sini",
      payload: {
        key: "ai_parent_child_list",
        value: [sini_init],
      },
    });

    dispatch({
      type: "set_value_sini",
      payload: {
        key: "request_id_active",
        value: request_id,
      },
    });

    dispatch({
      type: "set_value_sini",
      payload: {
        key: "request_id_loading",
        value: request_id,
      },
    });

    let json_db = {};

    const config = {
      headers: {
        accesstoken: localStorage.token_mapid,
      },
    };

    //untuk kasus POI ambil isokron terlebih dahulu untuk query ke DB,
    //karena POI query polygonnya menggunakan isokron, bukan radius

    const isochrone_mode = "driving"; //Isochrone API Profiles: driving, driving-traffic, walking, cycling
    const contours_minutes = 10;
    const contours_meters = 10;
    const contours_type = "minutes";
    let isochrone_url;
    if (contours_type === "minutes") {
      isochrone_url = `https://api.mapbox.com/isochrone/v1/mapbox/${isochrone_mode}/${long}%2C${lat}?contours_minutes=${contours_minutes}&polygons=true&denoise=1&access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`;
    } else {
      isochrone_url = `https://api.mapbox.com/isochrone/v1/mapbox/${isochrone_mode}/${long}%2C${lat}?contours_meters=${contours_meters}&polygons=true&denoise=1&access_token=${process.env.REACT_APP_MAPBOX_API_KEY}`;
    }
    const res_isokron = await axios.get(isochrone_url);
    geojson_polygon_isochrone = res_isokron?.data || {
      type: "FeatureCollection",
      features: [],
    };

    //geojson_polygon_isochrone
    dispatch({
      type: "edit_inside_array_sini",
      payload: {
        key: "ai_parent_detail_list",
        id_key_parent: "request_id",
        id_value_parent: request_id,
        id_key_child: "geojson_polygon_isochrone",
        value_child: geojson_polygon_isochrone,
      },
    });

    if (res_isokron?.data?.features?.[0]?.properties) {
      geojson_polygon_isochrone.features[0].properties = {
        ...geojson_polygon_isochrone.features[0].properties,
        isochrone_mode,
        contours_minutes,
        contours_meters,
        contours_type,
      };
    }

    json_db.geojson_polygon_isochrone = geojson_polygon_isochrone;

    const [min_long, min_lat, max_long, max_lat] = bbox(
      geojson_polygon_isochrone
    );

    let demography_one_id = "";
    const delay_constant = 0;
    const delay_promise = () =>
      new Promise((res) => setTimeout(res, delay_constant));
    const parent_function = () => {
      return action_array.reduce(
        (last_promise, object, idx) =>
          last_promise.then((result_sum) =>
            child_function(object, idx).then((result_current) => [
              ...result_sum,
              result_current,
            ])
          ),
        Promise.resolve([])
      );
    };
    const child_function = async (item, idx) => {
      return delay_promise().then(() => {
        const core_function = async () => {
          try {
            dispatch({
              type: "set_value_sini",
              payload: {
                key: "current_step",
                value: idx + 1,
              },
            });
            dispatch({
              type: "set_value_sini",
              payload: {
                key: "current_topic_loading",
                value: item,
              },
            });
            dispatch({
              type: "set_loading_action",
              payload: item,
            });

            if (item === "poi") {
              let res, data;
              let poi_features = [];
              let poi_resume_1 = [];
              let poi_resume_2 = [];
              let poi_resume_3 = [];
              /*
                poi:{
                poi_resume_1 : [],
                poi_resume_2 : [],
                poi_features: []
                }
                */
              res = await axios.get(
                bun_server +
                  `/sini_v2/${item}?lat=${lat}&long=${long}&min_long=${min_long}&min_lat=${min_lat}&max_long=${max_long}&max_lat=${max_lat}`,
                config
              );
              data = res?.data || [];
              const geojson_point = {
                type: "FeatureCollection",
                features: data,
              };

              const geojson_filtered = await points_within_polygon(
                geojson_point,
                geojson_polygon_isochrone
              );

              data = geojson_filtered?.features || [];
              data.forEach((feature) => {
                //tipe 1
                const name_tipe_1 = feature?.properties?.["TIPE_1"];
                let number_tipe_1 =
                  poi_resume_1.find((item) => name_tipe_1 === item.name)
                    ?.number || 0;
                number_tipe_1++;
                let index_tipe_1 = poi_resume_1.findIndex(
                  (item) => name_tipe_1 === item.name
                );
                if (index_tipe_1 > -1) {
                  poi_resume_1[index_tipe_1].number = number_tipe_1;
                } else {
                  poi_resume_1.push({
                    name: name_tipe_1,
                    number: number_tipe_1,
                  });
                }
                //tipe 2
                const name_tipe_2 = feature?.properties?.["TIPE_2"];
                let number_tipe_2 =
                  poi_resume_2.find((item) => name_tipe_2 === item.name)
                    ?.number || 0;
                number_tipe_2++;
                let index_tipe_2 = poi_resume_2.findIndex(
                  (item) => name_tipe_2 === item.name
                );
                if (index_tipe_2 > -1) {
                  poi_resume_2[index_tipe_2].number = number_tipe_2;
                } else {
                  poi_resume_2.push({
                    name: name_tipe_2,
                    parent: name_tipe_1,
                    number: number_tipe_2,
                  });
                }
                //tipe 3
                const name_tipe_3 = feature?.properties?.["TIPE_3"];
                let number_tipe_3 =
                  poi_resume_3.find((item) => name_tipe_3 === item.name)
                    ?.number || 0;
                number_tipe_3++;
                let index_tipe_3 = poi_resume_3.findIndex(
                  (item) => name_tipe_3 === item.name
                );
                if (index_tipe_3 > -1) {
                  poi_resume_3[index_tipe_3].number = number_tipe_3;
                } else {
                  poi_resume_3.push({
                    name: name_tipe_3,
                    parent_tipe_1: name_tipe_1,
                    parent_tipe_2: name_tipe_2,
                    number: number_tipe_3,
                  });
                }
              });
              poi_features = [...poi_features, ...data];
              dispatch({
                type: "push_inside_array_sini",
                payload: {
                  key: "ai_parent_detail_list",
                  id_key_parent: "request_id",
                  id_value_parent: request_id,
                  id_key_child: "poi_features",
                  value_array: data,
                },
              });
              dispatch({
                type: "edit_inside_array_sini",
                payload: {
                  key: "ai_parent_detail_list",
                  id_key_parent: "request_id",
                  id_value_parent: request_id,
                  id_key_child: "poi_resume_1",
                  value_child: poi_resume_1,
                },
              });
              dispatch({
                type: "edit_inside_array_sini",
                payload: {
                  key: "ai_parent_detail_list",
                  id_key_parent: "request_id",
                  id_value_parent: request_id,
                  id_key_child: "poi_resume_2",
                  value_child: poi_resume_2,
                },
              });
              dispatch({
                type: "edit_inside_array_sini",
                payload: {
                  key: "ai_parent_detail_list",
                  id_key_parent: "request_id",
                  id_value_parent: request_id,
                  id_key_child: "poi_resume_3",
                  value_child: poi_resume_3,
                },
              });

              dispatch({
                type: "state_update",
              });
              /*POI json_db*/
              const poi_features_id = poi_features.map((item) => item._id);
              json_db.poi_features = poi_features_id;
              json_db.poi_resume_1 = poi_resume_1;
              json_db.poi_resume_2 = poi_resume_2;
              json_db.poi_resume_3 = poi_resume_3;
            } else if (item === "poi_survey_1_features") {
              //get manifest layer
              const res_manifest_survey_1 = await axios.get(
                bun_server + "/sini_v2/get_manifest_survey_1",
                config
              );
              const manifest_survey_1 = res_manifest_survey_1.data;

              const res = await axios.get(
                bun_server +
                  `/sini_v2/${item}?lat=${lat}&long=${long}&min_long=${min_long}&min_lat=${min_lat}&max_long=${max_long}&max_lat=${max_lat}`,
                config
              );
              let data = res?.data || [];
              const geojson_point = {
                type: "FeatureCollection",
                features: data,
              };
              const geojson_filtered = await points_within_polygon(
                geojson_point,
                geojson_polygon_isochrone
              );
              data = geojson_filtered?.features || [];
              let poi_survey_1_features = [];
              let jenis_bangunan_resume = []; //array of object, {name, number}
              let average_sewa = 0;
              let min_sewa = Infinity; //set initial value to a very high number
              let max_sewa = -Infinity; //set initial value to a very low number
              let total_sewa = 0;
              let count_sewa = 0;

              if (data.length > 0) {
                /*
                OLD

                  "key": "b8f5ae86-e29d-4970-883c-f743b5be39fb",
                  "name": "Karakteristik Ekonomi: Harga Sewa/Tahun",
                  
                  "key": "3c76848c-ec41-411e-8fd1-b457dcae5593",
                  "name": "Jenis Bangunan",
  
                  poi_survey_1_features,
                  jenis_bangunan_resume: Array,
                  average_sewa: Number,
                  min_sewa: Number,
                  max_sewa: Number,
                  count_sewa: Number,

                  NEW


                  key: "adaa7d32-31b9-4da8-a4c5-0a59c8b1642e",
                  name: "Karakteristik Ekonomi: Harga Sewa/Tahun",


                  key: "06abb286-5789-4f4b-ae15-0a9419c62a12",
                  name: "Jenis Bangunan",
                  */

                const harga_key = "adaa7d32-31b9-4da8-a4c5-0a59c8b1642e";
                const jenis_bangunan_key =
                  "06abb286-5789-4f4b-ae15-0a9419c62a12";
                data.forEach((feature) => {
                  poi_survey_1_features.push(feature._id);
                  const name_tipe_1 = feature?.properties?.[jenis_bangunan_key];
                  let number_tipe_1 =
                    jenis_bangunan_resume.find(
                      (item) => name_tipe_1 === item.name
                    )?.number || 0;
                  number_tipe_1++;
                  let index_tipe_1 = jenis_bangunan_resume.findIndex(
                    (item) => name_tipe_1 === item.name
                  );
                  if (index_tipe_1 > -1) {
                    jenis_bangunan_resume[index_tipe_1].number = number_tipe_1;
                  } else {
                    jenis_bangunan_resume.push({
                      name: name_tipe_1,
                      number: number_tipe_1,
                    });
                  }
                  const value_sewa = parseFloat(
                    feature?.properties?.[harga_key]
                  );
                  if (!isNaN(value_sewa)) {
                    total_sewa += value_sewa;
                    count_sewa++;
                    if (value_sewa < min_sewa) min_sewa = value_sewa;
                    if (value_sewa > max_sewa) max_sewa = value_sewa;
                  }
                });
                if (count_sewa > 0)
                  average_sewa = parseInt(total_sewa / count_sewa);
              } else {
                min_sewa = 0;
                max_sewa = 0;
              }
              json_db.poi_survey_1_features = poi_survey_1_features;
              json_db.jenis_bangunan_resume = jenis_bangunan_resume;
              json_db.average_sewa = average_sewa;
              json_db.min_sewa = min_sewa;
              json_db.max_sewa = max_sewa;
              json_db.total_sewa = total_sewa;
              json_db.count_sewa = count_sewa;
              dispatch({
                type: "edit_inside_array_sini",
                payload: {
                  key: "ai_parent_detail_list",
                  id_key_parent: "request_id",
                  id_value_parent: request_id,
                  id_key_child: "poi_survey_1_features",
                  value_child: data,
                },
              });
              const object_survey_1 = {
                jenis_bangunan_resume,
                average_sewa,
                min_sewa,
                max_sewa,
                total_sewa,
                count_sewa,
                manifest_survey_1,
              };
              for (const key in object_survey_1) {
                dispatch({
                  type: "edit_inside_array_sini",
                  payload: {
                    key: "ai_parent_detail_list",
                    id_key_parent: "request_id",
                    id_value_parent: request_id,
                    id_key_child: key,
                    value_child: object_survey_1[key],
                  },
                });
              }
            } else {
              let link = item;
              if (item === "demography_one") link = "demography_one_new";
              if (item === "demography_neighbor")
                link = "demography_neighbor_new";
              const res = await axios.get(
                bun_server +
                  `/sini_v2/${link}?lat=${lat}&long=${long}&km_rad=${km_rad}&demography_one_id=${demography_one_id}`,
                config
              );
              const data = res?.data || {};
              if (item === "demography_one") {
                title_1 =
                  data?.demography_one?.properties?.["DESA"] ||
                  data?.demography_one?.properties?.["DESA ATAU KELURAHAN"] ||
                  "";
                title_2 =
                  data?.demography_one?.properties?.["KABKOT"] ||
                  data?.demography_one?.properties?.["KABUPATEN ATAU KOTA"] ||
                  "";

                if (sini_data_mode === "sini_rdtr") {
                  title_1 = `RDTR ${title_1}`;
                }
                if (is_from_excel) {
                  title_1 = `[HBU] ${title_1}`;
                }
                demography_one_id = data?.demography_one?._id;
                dispatch({
                  type: "edit_inside_array_sini",
                  payload: {
                    key: "ai_parent_detail_list",
                    id_key_parent: "request_id",
                    id_value_parent: request_id,
                    id_key_child: "title_1",
                    value_child: title_1,
                  },
                });
                dispatch({
                  type: "edit_inside_array_sini",
                  payload: {
                    key: "ai_parent_detail_list",
                    id_key_parent: "request_id",
                    id_value_parent: request_id,
                    id_key_child: "title_2",
                    value_child: title_2,
                  },
                });
                dispatch({
                  type: "state_update",
                });
              }

              if (item === "poi_property") {
                //aggreate nilai properti ke dalam satu variabel dan simpan ke db
                //supaya tidak berkali-kali mengaggregate ketika get data
                //dan data sudah tersimpan
                const result_property = aggregate_property_price(
                  data.poi_property
                );

                for (const property in result_property) {
                  dispatch({
                    type: "edit_inside_array_sini",
                    payload: {
                      key: "ai_parent_detail_list",
                      id_key_parent: "request_id",
                      id_value_parent: request_id,
                      id_key_child: property,
                      value_child: result_property[property],
                    },
                  });
                }
                json_db = { ...json_db, ...result_property };
              }
              //looping object response
              const item_object = [
                "demography_one",
                "people_spending",
                "landvalue_one",
                "landzone_one",
                "flood_one",
                "landslide_one",
                "tsunami_one",
                "rdtr_one",
              ];
              const item_array = [
                "demography_neighbor",
                "landvalue_neighbor",
                "landzone_neighbor",
                "flood_neighbor",
                "landslide_neighbor",
                "tsunami_neighbor",
                "rdtr_neighbor",
                "poi_property",
              ];
              for (const property in data) {
                dispatch({
                  type: "edit_inside_array_sini",
                  payload: {
                    key: "ai_parent_detail_list",
                    id_key_parent: "request_id",
                    id_value_parent: request_id,
                    id_key_child: property,
                    value_child: data[property],
                  },
                });
                if (item_object.includes(property)) {
                  if (data?.[property]?._id) {
                    json_db[property] = data?.[property]?._id;
                  }
                } else if (item_array.includes(property)) {
                  if (data?.[property]?.length > 0) {
                    const array = data?.[property].map(
                      (element) => element?._id
                    );
                    json_db[property] = array;
                  }
                }
              }
              dispatch({
                type: "state_update",
              });
              if (idx === 0) {
                dispatch(fly_init_update());
              }
              if (idx === 1) {
                dispatch(fly_init_marker());
              }
            }
            dispatch({
              type: "set_value_sini",
              payload: {
                key: "current_loading",
                value: item,
              },
            });
            dispatch({
              type: "clear_loading_action",
              payload: item,
            });
          } catch (error) {}
        };
        return core_function();
      });
    };
    parent_function().then(async () => {
      try {
        const body = {
          request_id: request_id,
          json_db: json_db,
          lat,
          long,
          km_rad,
          title_1,
          title_2,
        };
        await axios.post(bun_server + "/sini_v2/save_sini", body, config);
        dispatch({
          type: "set_value_sini",
          payload: {
            key: "request_id_loading",
            value: "",
          },
        });
        dispatch({
          type: "set_value_sini",
          payload: {
            key: "sini_choose_map_active",
            value: false,
          },
        });
        dispatch({
          type: "clear_loading_action",
          payload: "sini_load_parent",
        });
        dispatch({
          type: "state_update",
        });
        dispatch(get_quota_access());
        dispatch(get_quota_access_sini_ai());
        dispatch(get_quota_ai_chat());
        //reset coordinates_loading
        dispatch({
          type: "set_value_sini",
          payload: {
            key: "coordinates_loading",
            value: null,
          },
        });
        if (is_from_excel) {
          dispatch(
            create_ai_sini_sheet({
              geo_layer_id,
              feature_key,
              field_key,
              request_id,
              is_from_excel,
            })
          );
          dispatch({
            type: "set_value_sini",
            payload: {
              key: "sheet_object_selected",
              value: {
                geo_layer_id,
                feature_key,
                field_key,
                request_id,
                is_from_excel,
              },
            },
          });
        }
      } catch (error) {}
    });
  } catch (error) {}
};
