/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import {
  push_value_insight,
  set_value_insight,
} from "../../App/actions/insight_v2";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class INPUT_TIPE_SHORT extends Component {
  state = {};

  on_toggle_tipe_1 = (item_1) => {
    const { list_tipe_2, list_tipe_3 } = this.props.insight_reducer;
    this.props.set_value_insight({
      key: "tipe_level",
      value: "tipe_1",
    });
    this.props.set_value_insight({
      key: "tipe_1_all",
      value: false,
    });
    const { tipe_1_array, tipe_2_array, tipe_3_array } =
      this.props.insight_reducer;
    const is_active = tipe_1_array.some(
      (element) => element.TIPE_1 === item_1.TIPE_1
    );

    if (!is_active) {
      this.props.push_value_insight({
        key: "tipe_1_array",
        value: item_1,
      });
      const list_tipe_2_by_context = list_tipe_2.filter(
        (item_2) => item_2.TIPE_1 === item_1.TIPE_1
      );
      const tipe_2_array_new = [...tipe_2_array, ...list_tipe_2_by_context];
      this.props.set_value_insight({
        key: "tipe_2_array",
        value: tipe_2_array_new,
      });
      const list_tipe_3_by_context = list_tipe_3.filter(
        (item_3) => item_3.TIPE_1 === item_1.TIPE_1
      );
      const tipe_3_array_new = [...tipe_3_array, ...list_tipe_3_by_context];
      this.props.set_value_insight({
        key: "tipe_3_array",
        value: tipe_3_array_new,
      });
    } else {
      const tipe_1_array_filtered = tipe_1_array.filter(
        (element) => element.TIPE_1 !== item_1.TIPE_1
      );
      const tipe_2_array_filtered = tipe_2_array.filter(
        (item_2) => item_2.TIPE_1 !== item_1.TIPE_1
      );
      const tipe_3_array_filtered = tipe_3_array.filter(
        (item_3) => item_3.TIPE_1 !== item_1.TIPE_1
      );
      this.props.set_value_insight({
        key: "tipe_1_array",
        value: tipe_1_array_filtered,
      });
      this.props.set_value_insight({
        key: "tipe_2_array",
        value: tipe_2_array_filtered,
      });
      this.props.set_value_insight({
        key: "tipe_3_array",
        value: tipe_3_array_filtered,
      });
    }
  };

  toggle_tipe_1_all = () => {
    const { tipe_1_all, list_tipe_1, list_tipe_2, list_tipe_3 } =
      this.props.insight_reducer;

    this.props.set_value_insight({
      key: "tipe_1_all",
      value: !tipe_1_all,
    });
    if (tipe_1_all) {
      this.props.set_value_insight({
        key: "tipe_1_array",
        value: [],
      });
      this.props.set_value_insight({
        key: "tipe_2_array",
        value: [],
      });
      this.props.set_value_insight({
        key: "tipe_3_array",
        value: [],
      });
    } else {
      this.props.set_value_insight({
        key: "tipe_1_array",
        value: list_tipe_1,
      });
      this.props.set_value_insight({
        key: "tipe_2_array",
        value: list_tipe_2,
      });
      this.props.set_value_insight({
        key: "tipe_3_array",
        value: list_tipe_3,
      });
    }
  };

  render() {
    //local storage

    //local state

    //global props
    const { tipe_1_all, tipe_1_array, list_tipe_1 } =
      this.props.insight_reducer;

    //content

    return (
      <section id="poi_insight_child">
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td>
                <button
                  onClick={this.toggle_tipe_1_all}
                  className={tipe_1_all ? "checklist_active" : "checklist_not"}
                  data-mapid="clickAll"
                />
              </td>
              <td onClick={this.toggle_tipe_1_all} className="side">
                ALL
              </td>
            </tr>
            {list_tipe_1.map((item, idx) => {
              const is_active = tipe_1_array.some(
                (element) => element.TIPE_1 === item.TIPE_1
              );
              return (
                <tr key={idx}>
                  <td>
                    <button
                      onClick={this.on_toggle_tipe_1.bind(this, item)}
                      className={
                        is_active ? "checklist_active" : "checklist_not"
                      }
                    />
                  </td>
                  <td
                    onClick={this.on_toggle_tipe_1.bind(this, item?.TIPE_1)}
                    className="side"
                  >
                    {item?.TIPE_1}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  insight_reducer: state.insight_reducer,
});

export default connect(mapStateToProps, {
  push_value_insight,
  set_value_insight,
})(INPUT_TIPE_SHORT);
