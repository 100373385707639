import axios from "axios";
import { get_quota_ai_chat } from "./authActions";
import manifest_demography from "../../Data/manifest_demography.json";
import manifest_people_spending from "../../Data/manifest_people_spending.json";
import { get_ai_parent_status } from "./sini_v2";

// const bun_server = "http://localhost:4004";
const bun_server = "https://geoserver.mapid.io";

/**
 * @route : post_ai_chat
 * @endpoint : /sini_v2/post_ai_chat
 * @methode : post
 * @params : -
 * @body : request_id, user_message, sini_object
 */
export const post_ai_chat = (body) => async (dispatch) => {
  try {
    dispatch({ type: "set_loading_action", payload: "post_ai_chat" });

    const { request_id, user_message, sini_object } = body;
    const { title_1, title_2 } = sini_object;
    const title = `${title_1} ${title_2}`;
    const object_user_chat = {
      user: "",
      request_id,
      role: "user",
      content: user_message,
      date_created: Date.now(),
      date_modified: Date.now(),
    };
    dispatch({
      type: "push_inside_array_sini",
      payload: {
        key: "ai_parent_child_list",
        id_key_parent: "request_id",
        id_value_parent: request_id,
        id_key_child: "ai_child_list",
        value_array: [object_user_chat],
        value_object: { request_id, ai_child_list: [object_user_chat] },
      },
    });

    /*START*/
    let json_ai = {};
    const add_properties = (key, object) => {
      if (object?._id) json_ai[key] = { properties: object.properties || {} };
    };
    const add_default = (key, object, status) => {
      json_ai[key] = {
        properties: object?._id ? object.properties || {} : { status },
      };
    };
    add_properties("demography_one", sini_object?.demography_one);
    add_properties("people_spending", sini_object?.people_spending);
    add_properties("landzone_one", sini_object?.landzone_one);
    add_default(
      "flood_one",
      sini_object?.flood_one,
      "Tidak ada data kerawanan banjir"
    );
    add_default(
      "landslide_one",
      sini_object?.landslide_one,
      "Tidak ada data kerawanan longsor"
    );
    add_default(
      "tsunami_one",
      sini_object?.tsunami_one,
      "Tidak ada data kerawanan tsunami"
    );
    if (sini_object?.rdtr_one?._id) {
      json_ai.rdtr_one = {
        properties: { NAMZON_1: sini_object.rdtr_one.properties?.NAMZON_1 },
      };
    }
    const map_array = (key, array) => {
      json_ai[key] =
        array?.length > 0
          ? array.map((item) => ({ properties: item?.properties || {} }))
          : [];
    };
    map_array("landzone_neighbor", sini_object?.landzone_neighbor);
    map_array("flood_neighbor", sini_object?.flood_neighbor);
    map_array("landslide_neighbor", sini_object?.landslide_neighbor);
    map_array("tsunami_neighbor", sini_object?.tsunami_neighbor);
    add_properties("landvalue_one", sini_object?.landvalue_one);
    map_array("landvalue_neighbor", sini_object?.landvalue_neighbor);
    json_ai.poi_features = [];
    json_ai.poi_resume_1 = sini_object?.poi_resume_1 || [];
    json_ai.poi_resume_2 = sini_object?.poi_resume_2 || [];
    json_ai.poi_resume_3 = sini_object?.poi_resume_3 || [];
    const {
      average_property_rumah_rp,
      average_property_apartemen_rp,
      average_property_ruko_rp,
      min_property_rumah_rp,
      min_property_apartemen_rp,
      min_property_ruko_rp,
      max_property_rumah_rp,
      max_property_apartemen_rp,
      max_property_ruko_rp,
      count_property_rumah,
      count_property_apartemen,
      count_property_ruko,
    } = sini_object;
    json_ai = {
      ...json_ai,
      average_property_rumah_rp,
      average_property_apartemen_rp,
      average_property_ruko_rp,
      min_property_rumah_rp,
      min_property_apartemen_rp,
      min_property_ruko_rp,
      max_property_rumah_rp,
      max_property_apartemen_rp,
      max_property_ruko_rp,
      count_property_rumah,
      count_property_apartemen,
      count_property_ruko,
    };
    if (sini_object?.poi_survey_1_features?.length > 0) {
      const {
        jenis_bangunan_resume,
        average_sewa,
        min_sewa,
        max_sewa,
        count_sewa,
        manifest_survey_1,
      } = sini_object;
      let { poi_survey_1_features } = sini_object;

      //pake fields.name
      const fields_used = [
        "DATA LINGKUNGAN",
        "Karakteristik Lokasi",
        "Karakteristik Kepadatan Pengembangan",
        "Karakteristik Pertumbuhan",
        "Issue Lingkungan",
        "Catatan Issue Lingkungan",
        "Analisis Risiko",
        "Catatan Analisis Risiko",
        "DATA PROPERTI",
        "Tipe Properti",
        "Aksesibilitas",
        "Data Jalan: Jumlah Jalur",
        "Data Jalan: Lebar Jalan",
        "Data Jalan: Jumlah Lajur",
        "Data Jalan: Material",
        "Data Jalan: Drainase",
        "Data Jalan: Penerangan",
        "Data Jalan: Kondisi Fisik",
        "Data Jalan: Intensitas Lalulintas",
        "Status Hunian",
      ];
      const fields = manifest_survey_1?.fields || [];
      poi_survey_1_features = poi_survey_1_features.map((feature) => {
        let properties = {};
        fields_used.forEach((field_name) => {
          const field_key = fields.find(
            (field) => field.name === field_name
          )?.key;
          const value = feature?.properties?.[field_key];
          properties[field_name] = value;
        });
        return properties;
      });
      json_ai = {
        ...json_ai,
        poi_survey_1_features,
        jenis_bangunan_resume,
        average_sewa,
        min_sewa,
        max_sewa,
        count_sewa,
      };
    }

    body.json_ai = json_ai;
    delete body.sini_object;
    /*FINISH*/

    const body_post_ai_chat = {
      request_id,
      user_message,
      json_ai,
      title,
      manifest_demography,
      manifest_people_spending,
    };
    const config = { headers: { accesstoken: localStorage.token_mapid } };
    const res = await axios.post(
      `${bun_server}/sini_v2/post_ai_chat`,
      body_post_ai_chat,
      config
    );
    const { object_assistant_chat } = res.data;
    dispatch({
      type: "push_inside_array_sini",
      payload: {
        key: "ai_parent_child_list",
        id_key_parent: "request_id",
        id_value_parent: request_id,
        id_key_child: "ai_child_list",
        value_array: [object_assistant_chat],
        value_object: {
          request_id,
          ai_child_list: [object_user_chat, object_assistant_chat],
        },
      },
    });
    dispatch({ type: "state_update" });
    dispatch({ type: "clear_loading_action", payload: "post_ai_chat" });
    dispatch(get_quota_ai_chat());
    dispatch(get_ai_parent_status({ request_id }));
  } catch (error) {
    const data = error?.response?.data || {};
    const { is_error_limitation, is_error_bill_ai } = data;
    if (is_error_limitation || is_error_bill_ai) {
      dispatch({
        type: "set_value_sini",
        payload: {
          key: `modal_error_${is_error_limitation ? "limitation" : "bill_ai"}`,
          value: true,
        },
      });
      dispatch({
        type: "set_value_sini",
        payload: { key: "error_limitation", value: data },
      });
    }
    dispatch({ type: "clear_loading_action", payload: "post_ai_chat" });
  }
};
