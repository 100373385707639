/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import INPUT_DATA from "../../Components/insight/INPUT_DATA";
import INPUT_GRID from "../../Components/insight/INPUT_GRID";
import INPUT_FORMULA from "../../Components/insight/INPUT_FORMULA";

/*REDUX*/

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class INPUT_CUSTOM extends Component {
  state = {};

  render() {
    //local storage

    //local state

    //global props

    //content
    return (
      <main>
        <INPUT_DATA />
        <INPUT_GRID />
        <INPUT_FORMULA />
      </main>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(INPUT_CUSTOM);
