/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import LayerListRender from "./LayerListRender";
import EditFolder from "./EditFolder";
import DeleteFolder from "./DeleteFolder";
import EditIcon from "../common_icon/EditIcon";
import DeleteIcon from "../common_icon/DeleteIcon";
import Modal from "../common_modal/Modal";
import KERETA_API_INDONESIA_ICON from "../common_icon/KERETA_API_INDONESIA_ICON";

/*REDUX*/
import {
  setCloseFolder,
  setHeightFolders,
  setOpenAllFolder,
  setCloseAllFolder,
} from "../../App/actions/projectActions";
import {
  move_layers_into_any_folder,
  delete_layers_bulk,
} from "../../App/actions/layerNewActions";
import {
  set_value_properties,
  get_detail_many_layer,
  clear_layer_id,
} from "../../App/actions/propertiesActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/
import dict from "../../Data/dict.json";

/*CONST*/

class LayerListModeExpand extends Component {
  state = {
    modalGeometry: false,
    otherCloseStatus: false,
    otherHeight: 0,
    modalEditFolder: false,
    modalDeleteFolder: false,
    folder: {},
    name_filter: "",
    name_filter_final: "",
    type_filter: "",
    mode_filter: "name",
    is_mode_checklist_layer: false,
    checkedLayers: [],

    isOpen: false,
    modalContent: "",
    folderId: "",
    message: "",
    confirmationText: "",
  };

  on_resume_kai = (folder) => {
    const { geo_layer_list } = this.props.layer;
    const { geo_project } = this.props.project;
    this.props.clear_layer_id();
    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: true,
    });
    this.props.set_value_properties({
      key: "folder_kai_timeseries",
      value: folder,
    });
    if (folder) {
      this.props.set_value_properties({
        key: "sidebar_right_status",
        value: true,
      });
      this.props.set_value_properties({
        key: "folder_kai_timeseries",
        value: folder,
      });
      const layer_list = geo_layer_list
        .filter(
          (element) =>
            element?.folder === folder?._id &&
            !element?.geo_layer?.geojson?.features?.length
        )
        .map((item) => item?.geo_layer?._id);
      if (layer_list.length > 0) {
        const params = {
          layer_list,
          geo_project_id: geo_project._id,
          folder_id: folder._id,
        };
        this.props.get_detail_many_layer(params);
      }
    }
  };

  resetCheckedListAndCancelMode = () => {
    this.setState({ checkedLayers: [] }, () => {
      this.toggleIsModeChecklistLayer(false);
    });
  };

  toggleModalBulkMoveDelete = (modalContent) => {
    this.setState({
      isOpen: modalContent ? true : false,
      modalContent,
      folderId: "",
      message: "",
      confirmationText: "",
    });
  };

  setFolderBulkMoveDelete = (e) => {
    this.setState({
      folderId: e.target.value,
    });
  };

  setConfirmationTextBulkMoveDelete = (e) => {
    this.setState({
      confirmationText: e.target.value,
    });
  };

  handleSaveBulkMove = () => {
    const { folderId = "", checkedLayers = [] } = this.state;

    // menunggu api
    const geo_project_id = this?.props?.project?.geo_project?._id || "";
    if (geo_project_id && checkedLayers.length > 0) {
      const body = {
        geo_project_id: geo_project_id,
        geo_folder_id: folderId,
        geo_layers: checkedLayers,
      };

      const res = this.props.move_layers_into_any_folder(body);
      if (res.status === 200) {
        this.toggleModalBulkMoveDelete();
      }
    }
  };

  handleSaveBulkDelete = async (text, language) => {
    // menunggu api
    if (
      text ===
      `${this.props.auth?.user?.full_name} ${dict["is in charge of removing all of these layers"][language]}`
    ) {
      const { checkedLayers } = this.state;
      const geo_project_id = this.props.project?.geo_project?._id;
      let filtered_checked_layers = {
        geo_layer_attached_ids: [],
        geo_layer_ids: [],
        geo_project_id,
      };

      let { geo_layer_list } = this.props.layer;
      checkedLayers.forEach((layerId) => {
        const current_layer = geo_layer_list.find(
          (layer) => layer.geo_layer._id === layerId
        );
        if (current_layer?.status === "attached") {
          filtered_checked_layers["geo_layer_attached_ids"].push(layerId);
        } else {
          filtered_checked_layers["geo_layer_ids"].push(layerId);
        }
      });

      const res = await this.props.delete_layers_bulk(filtered_checked_layers);
      if (res.status === 200) {
        this.toggleModalBulkMoveDelete();
      }
    } else {
      this.setState({
        message: dict["The sentence doesn't match"][language],
      });
    }
  };

  handleChange = (e) => {
    e.preventDefault();
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  setCheckedLayers = (value) => {
    this.setState(
      {
        checkedLayers: value,
      },
      () => {
        if (value.length === 0) {
          this.toggleIsModeChecklistLayer(false);
        }
      }
    );
  };

  handleSelectLayersInThisFolder = (layers) => {
    const { checkedLayers } = this.state;
    let isCheckedAll = true;
    const layersId = layers.map((layer) => layer.geo_layer._id);
    for (const id of layersId) {
      if (!checkedLayers.includes(id)) {
        isCheckedAll = false;
        break;
      }
    }

    let new_layers = [];
    if (isCheckedAll) {
      new_layers = checkedLayers.filter((id) => !layersId.includes(id));
    } else {
      const filtered_layers = layersId.filter(
        (id) => !checkedLayers.includes(id)
      );
      new_layers = checkedLayers.concat(filtered_layers);
    }
    this.setCheckedLayers(new_layers);
  };

  toggleIsModeChecklistLayer = (value) => {
    const { is_mode_checklist_layer } = this.state;
    this.setState({
      is_mode_checklist_layer: value || !is_mode_checklist_layer,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { name_filter } = this.state;
    this.setState({ name_filter_final: name_filter });
  };

  setOpenAllFolder = () => {
    this.props.setOpenAllFolder();
    const { geo_project } = this.props.project;
    const folders = geo_project?.folders || [];
    folders.map(({ _id, closeStatus, height }, idx) => {
      const docAction = closeStatus
        ? (document.getElementById(_id).style.height = "25px")
        : (document.getElementById(_id).style.height = `100%`);
      return docAction;
    });
    this.setState({ otherCloseStatus: false }, () => {
      document.getElementById("other_folder").style.height = `100%`;
    });
  };

  setCloseAllFolder = () => {
    this.props.setCloseAllFolder();
    const { geo_project } = this.props.project;
    const folders = geo_project?.folders || [];
    folders.map(({ _id, closeStatus, height }, idx) => {
      const docAction = closeStatus
        ? (document.getElementById(_id).style.height = "25px")
        : (document.getElementById(_id).style.height = `100%`);
      return docAction;
    });
    this.setState({ otherCloseStatus: true }, () => {
      document.getElementById("other_folder").style.height = `25px`;
    });
  };

  toggle_edit_folder = (folder) => {
    if (folder) {
      this.setState({ folder });
    }
    this.setState({ modalEditFolder: !this.state.modalEditFolder });
  };

  toggle_delete_folder = (folder) => {
    if (folder) {
      this.setState({ folder });
    }
    this.setState({ modalDeleteFolder: !this.state.modalDeleteFolder });
  };

  onCloseFolder = (folder_id) => {
    this.props.setCloseFolder(folder_id);
    const { geo_project } = this.props.project;
    const folders = geo_project?.folders || [];
    folders.map(({ _id, closeStatus, height }, idx) => {
      const docAction = closeStatus
        ? (document.getElementById(_id).style.height = "25px")
        : (document.getElementById(_id).style.height = `100%`);
      return docAction;
    });
  };

  toggleCloseStatus = () => {
    this.setState({ otherCloseStatus: !this.state.otherCloseStatus }, () => {
      if (!this.state.otherCloseStatus) {
        document.getElementById("other_folder").style.height = `100%`;
      } else {
        document.getElementById("other_folder").style.height = "25px";
      }
    });
  };

  render() {
    //local storage
    const language = localStorage?.language ? localStorage?.language : "ina";

    //local state
    const {
      otherCloseStatus,
      modalEditFolder,
      modalDeleteFolder,
      folder,
      name_filter,
      name_filter_final,
      type_filter,
      mode_filter,
      checkedLayers,
      is_mode_checklist_layer,
      isOpen,
      modalContent,
      message,
      confirmationText,
    } = this.state;

    //global props
    let { loadingList, geo_layer_list, loadingProcess, itemLoading } =
      this.props.layer;
    const { geo_project, loadingDetail } = this.props.project;
    const folders = geo_project?.folders || [];
    const folders_id = folders.map(({ _id }, idx) => {
      return _id;
    });

    //content

    if (name_filter_final && mode_filter === "name") {
      geo_layer_list = geo_layer_list.filter(
        (l) =>
          l.geo_layer.name
            .toLowerCase()
            .includes(name_filter_final.toLowerCase()) ||
          l.geo_layer.description
            .toLowerCase()
            .includes(name_filter_final.toLowerCase())
      );
    }

    if (type_filter && mode_filter === "type") {
      const tipe_detail = {
        point: ["Position", "Point", "MultiPoint"],
        iot: ["IoT"],
        line: ["LineString", "MultiLineString"],
        polygon: ["Polygon", "MultiPolygon", "GeometryCollection"],
      };
      const tipe_array = tipe_detail[type_filter];
      geo_layer_list = geo_layer_list.filter((e) =>
        tipe_array.includes(e?.geo_layer?.type)
      );
    }

    const search_input = (
      <main
        style={{
          width: "100%",
          marginBottom: "5px",
        }}
      >
        <form
          onSubmit={this.handleSubmit.bind(this)}
          style={{
            width: "200px",
            display: "inline-block",
            verticalAlign: "top",
            marginRight: "5px",
          }}
        >
          {mode_filter === "name" ? (
            <input
              type="text"
              name="name_filter"
              id="name_filter"
              className="input_new"
              value={name_filter}
              onChange={this.handleChange}
              placeholder={dict["Filter layer by name"][language]}
              style={{
                width: "195px",
                fontSize: "13px",
              }}
            />
          ) : (
            <select
              name="type_filter"
              id="type_filter"
              className="input_new"
              onChange={this.handleChange}
              style={{
                fontSize: "13px",
              }}
            >
              <option value="">{dict["Select type"][language]}</option>
              <option value="point">{dict["Point"][language]}</option>
              <option value="iot">{dict["IoT"][language]}</option>
              <option value="line">{dict["Line"][language]}</option>
              <option value="polygon">{dict["Polygon"][language]}</option>
            </select>
          )}
        </form>
        <select
          name="mode_filter"
          id="mode_filter"
          onChange={this.handleChange}
          className="input_new"
          style={{
            width: "80px",
            display: "inline-block",
            verticalAlign: "top",
            fontSize: "13px",
          }}
        >
          <option value="name">{dict["Name"][language]}</option>
          <option value="type">{dict["Type"][language]}</option>
        </select>
        {name_filter_final && (
          <section
            className="button_very_small"
            id="grey"
            style={{ marginBottom: "5px" }}
          >
            {mode_filter} : {name_filter_final}
          </section>
        )}
      </main>
    );
    const folder_expand_content = (
      <main className="folder_expand margin_bottom">
        <section
          onClick={this.setOpenAllFolder}
          className="button"
          id="outline_grey"
        >
          {dict["Open all folder"][language]}
        </section>
        <section
          onClick={this.setCloseAllFolder}
          className="button"
          id="outline_grey"
        >
          {dict["Close all folder"][language]}
        </section>
      </main>
    );
    let content =
      loadingList ||
      loadingDetail ||
      (loadingProcess && itemLoading === "get_list_by_link_project") ? (
        <div id="loading" style={{ height: "auto" }}></div>
      ) : (
        <main>
          {folder_expand_content}
          {search_input}
          <hr />
          {is_mode_checklist_layer && (
            <div className="center_perfect h_20 margin_10">
              <div className="three_container">
                <button
                  className="button bg_blue hover_darkBlue"
                  onClick={() => {
                    this.toggleModalBulkMoveDelete("move");
                  }}
                >
                  {dict["Move"][language]}
                </button>
                <button
                  className="button bg_red hover_darkRed"
                  onClick={() => {
                    this.toggleModalBulkMoveDelete("delete");
                  }}
                >
                  {dict["Delete"][language]}
                </button>
                <button
                  className="button bg_red hover_darkRed"
                  onClick={() => {
                    this.resetCheckedListAndCancelMode();
                  }}
                >
                  {dict["Cancel"][language]}
                </button>
              </div>
            </div>
          )}
          <div
            id="layer_list_component"
            style={{
              height: "auto",
              marginTop: "5px",
            }}
          >
            {folders.map((folder, idx) => {
              const { name, _id, closeStatus } = folder;
              const geo_layer_list_render = geo_layer_list.filter(
                (geo_layer) => geo_layer.folder === _id
              );
              return (
                <main key={idx} className="folderBox" id={_id}>
                  <main
                    className="folder_name text_collapse_4 noselect"
                    style={{
                      display: "flex",
                      itemContent: "center",
                      paddingTop: 4,
                    }}
                  >
                    <section
                      onClick={this.onCloseFolder.bind(this, _id)}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="caret-down"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        style={{
                          width: "1.25rem",
                          height: "1.25rem",
                          transition: "transform 0.5s ease 0s",
                          transform: `${
                            !closeStatus ? "rotate(-180deg)" : "rotate(0deg)"
                          }`,
                        }}
                      >
                        <path
                          fill="currentColor"
                          d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                        ></path>
                      </svg>
                    </section>

                    <section
                      onClick={this.onCloseFolder.bind(this, _id)}
                      style={{
                        cursor: "pointer",
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {name}
                    </section>
                    {is_mode_checklist_layer && (
                      <section className="center_perfect">
                        <div
                          onClick={() => {
                            this.handleSelectLayersInThisFolder(
                              geo_layer_list_render
                            );
                          }}
                          className="pointer margin_5 padding_5 text_center bg_blue inline text_white rounded_5 hover_darkBlue"
                        >
                          {dict["All"][language]}
                        </div>
                      </section>
                    )}
                    <section>
                      {folder?.folder_type === "kai_timeseries" && (
                        <div
                          onClick={this.on_resume_kai.bind(this, folder)}
                          style={{ display: "inline-block" }}
                          title="Resume timeseries"
                          className="margin_right"
                        >
                          <KERETA_API_INDONESIA_ICON />
                        </div>
                      )}
                      <div
                        onClick={this.toggle_edit_folder.bind(this, folder)}
                        style={{ display: "inline-block" }}
                        title="Edit"
                      >
                        <EditIcon />
                      </div>
                      <div
                        onClick={this.toggle_delete_folder.bind(this, folder)}
                        style={{ display: "inline-block" }}
                        title="Delete"
                      >
                        <DeleteIcon />
                      </div>
                    </section>
                  </main>
                  <LayerListRender
                    checkedLayers={checkedLayers}
                    setCheckedLayers={this.setCheckedLayers}
                    is_mode_checklist_layer={is_mode_checklist_layer}
                    toggleIsModeChecklistLayer={this.toggleIsModeChecklistLayer}
                    geo_layer_list={geo_layer_list_render}
                  />
                </main>
              );
            })}
            <div className="folderBox" id="other_folder">
              <div
                className="text_collapse noselect folder_name"
                style={{ marginBottom: "15px", display: "flex" }}
              >
                {/* <div>{!otherCloseStatus ? "▼" : "► "}</div> */}
                <div onClick={this.toggleCloseStatus}>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="caret-down"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    style={{
                      width: "1.25rem",
                      height: "1.25rem",
                      transition: "transform 0.5s ease 0s",
                      transform: `${
                        !otherCloseStatus ? "rotate(-180deg)" : "rotate(0deg)"
                      }`,
                    }}
                  >
                    <path
                      fill="currentColor"
                      d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                    ></path>
                  </svg>
                </div>
                <div
                  className="w_full"
                  style={{
                    display: "flex",
                    itemContent: "center",
                    justifyContent: "space-between",
                    paddingTop: "4px",
                  }}
                >
                  <section
                    onClick={this.toggleCloseStatus}
                    className="w_full center_perfect marginRight_20"
                  >
                    {dict["Others"][language]}
                  </section>
                  {is_mode_checklist_layer && (
                    <section className="center_perfect">
                      <div
                        onClick={() => {
                          this.handleSelectLayersInThisFolder(
                            geo_layer_list.filter(
                              (geo_layer) =>
                                !folders_id.includes(geo_layer.folder)
                            )
                          );
                        }}
                        className="pointer margin_5 padding_5 text_center bg_blue inline text_white rounded_5 hover_darkBlue"
                      >
                        {dict["All"][language]}
                      </div>
                    </section>
                  )}
                </div>
              </div>
              <LayerListRender
                checkedLayers={checkedLayers}
                setCheckedLayers={this.setCheckedLayers}
                is_mode_checklist_layer={is_mode_checklist_layer}
                toggleIsModeChecklistLayer={this.toggleIsModeChecklistLayer}
                geo_layer_list={geo_layer_list.filter(
                  (geo_layer) => !folders_id.includes(geo_layer.folder)
                )}
              />
            </div>
          </div>
        </main>
      );

    const modalEditFolderContent = modalEditFolder && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modalEditFolder}
        onClose={this.toggle_edit_folder}
      >
        <div className="box-body">
          <EditFolder
            folder={folder}
            toggle_edit_folder={this.toggle_edit_folder}
          />
        </div>
      </Modal>
    );

    const modalDeleteFolderContent = modalDeleteFolder && (
      <Modal
        modalSize="small"
        id="modal"
        isOpen={modalDeleteFolder}
        onClose={this.toggle_delete_folder}
      >
        <div className="box-body">
          <DeleteFolder
            folder={folder}
            toggle_delete_folder={this.toggle_delete_folder}
          />
        </div>
      </Modal>
    );

    const deleteLayersContent = (
      <div className="w_full">
        <div className="text_center font_20 marginBottom_20">
          {dict["Are you sure to delete?"][language]}
        </div>
        <div>
          <p className="text_center">
            {dict["Retype this sentence"][language]}
          </p>
          <p className="text_center">
            <strong>{`${this.props.auth?.user?.full_name} ${dict["is in charge of removing all of these layers"][language]}`}</strong>
          </p>
          {message && <div className="bg_red text_white">{message}</div>}
          <input
            onChange={this.setConfirmationTextBulkMoveDelete}
            type="text"
            className="w_full"
          />
        </div>
        <div className="center_perfect">
          <button
            onClick={() => {
              this.handleSaveBulkDelete(confirmationText, language);
            }}
            className="button bg_red"
          >
            {dict["Delete"][language]}
          </button>
        </div>
      </div>
    );

    const moveLayersContent = (
      <div className="w_full">
        <div className="text_center font_20 marginBottom_20">
          {dict["Move layers to a folder"][language]}
        </div>
        <div>
          <p className="text_center">{dict["Select a folder"][language]}</p>
          <select
            onChange={this.setFolderBulkMoveDelete}
            type="text"
            className="w_full"
          >
            <option value="">{dict["Outside folders"][language]}</option>
            {folders.map((folder) => (
              <option key={folder._id} value={folder._id}>
                {folder.name}
              </option>
            ))}
          </select>
        </div>
        <div className="center_perfect">
          <button
            onClick={() => {
              this.handleSaveBulkMove();
            }}
            className="button"
          >
            {dict["Save"][language]}
          </button>
        </div>
      </div>
    );

    const modal_bulk_move_delete_content = isOpen && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={isOpen}
        onClose={this.toggleModalBulkMoveDelete}
      >
        <div className="box-body">
          {modalContent === "move" ? (
            <div>{moveLayersContent}</div>
          ) : (
            <div>{deleteLayersContent}</div>
          )}
        </div>
      </Modal>
    );

    return (
      <div>
        {modalEditFolderContent}
        {modalDeleteFolderContent}
        {modal_bulk_move_delete_content}

        {content}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  setCloseFolder,
  setHeightFolders,
  setOpenAllFolder,
  setCloseAllFolder,
  move_layers_into_any_folder,
  delete_layers_bulk,
  set_value_properties,
  get_detail_many_layer,
  clear_layer_id,
})(LayerListModeExpand);
