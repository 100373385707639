/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/
import Modal from "../common_modal/Modal";
import RANGE_NUMBER_STEP from "../layer_style_editor/RANGE_NUMBER_STEP";
import COLOR_SCHEME_EDITOR from "../layer_style_editor/COLOR_SCHEME_EDITOR";

/*REDUX*/
import { importLayer } from "../../App/actions/layerNewActions";
import {
  set_value_toolbox,
  status_toolbox,
} from "../../App/actions/toolboxActions";

/*PICTURE*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class GRID_STEP_3 extends Component {
  state = {
    modal_save: false,
  };

  on_save = () => {
    this.props.set_value_toolbox({
      key: "features_grid_raw",
      value: [],
    });
    this.props.set_value_toolbox({
      key: "features_grid",
      value: [],
    });
    this.props.status_toolbox();

    let { style_parameter, paint_object_edited } = this.props.properties;
    const { toolbox_grid_object, features_grid } = this.props.toolbox;
    const { geo_project } = this.props.project;
    const { domain } = this.props.auth;
    const { geo_layer_list } = this.props.layer;

    const { layer_point_id, layer_polygon_id } = toolbox_grid_object;
    const name_polygon =
      geo_layer_list.find((item) => item?.geo_layer?._id === layer_polygon_id)
        ?.geo_layer?.name || "Polygon";
    const name_point =
      geo_layer_list.find((item) => item?.geo_layer?._id === layer_point_id)
        ?.geo_layer?.name || "Point";
    const field_key = "ALL_POI_COUNT";

    paint_object_edited = {
      ...paint_object_edited,
      //line bawaan terpisah
      "line-color": "#000000",
      "line-width": 1,
      "line-opacity": 1,
      "line-dasharray": [],
      "line-gap-width": 0,
    };
    style_parameter.paint_object = paint_object_edited;
    style_parameter.key = field_key;
    style_parameter.field_key = field_key;
    const fields = [
      {
        uuid: field_key,
        key: field_key,
        name: field_key,
        description: "",
        unit: "",
        type: "number",
        isHighlight: false,
        isRequired: true,
        isHide: false,
        isStyle: false,
      },
    ];
    const geojson = {
      type: "FeatureCollection",
      features: features_grid,
    };
    const style_array = [style_parameter];
    const body = {
      name: `[TOOLBOX GRID] ${name_point} & ${name_polygon}`,
      description: "",
      type: "Polygon",
      user_id: this?.props?.auth?.user?._id,
      folder: "",
      geo_project_id: geo_project?._id,
      fields,
      geojson,
      status: "digitasi",
      domain,
      style_array,
      default_style_key: field_key,
      is_cluster: false,
      is_use_new_style: true,
      is_show_and_fly: false,
      toolbox_grid_object,
    };
    this.props.importLayer(body, false);
  };

  toggle_save = () => {
    this.setState({ modal_save: !this.state.modal_save });
  };

  on_toggle_and_save = () => {
    this.toggle_save();
    this.on_save();
  };

  render() {
    //local storage

    //local state
    const { modal_save } = this.state;

    //global props
    const { toolbox_grid_object } = this.props.toolbox;
    const { loading_item_array } = this.props.loading_reducer;

    //content
    const { grid_counts, grid_width_meters } = toolbox_grid_object;
    const modal_save_content = modal_save && (
      <Modal modalSize="small" isOpen={modal_save} onClose={this.toggle_save}>
        <div className="box-body" id="box-body">
          {loading_item_array.includes("importLayer")
            ? "Menyimpan hasil toolbox..."
            : "Tersimpan"}
        </div>
      </Modal>
    );

    return (
      <main className="container_light background_grey_light outline_transparent margin_bottom">
        {modal_save_content}
        <h2 className="text_bold">Output</h2>
        <br />
        <p className="text_small">Jumlah grid</p>
        <p className="text_bold">{grid_counts}</p>
        <p className="text_small">Ukuran grid (meter)</p>
        <p className="text_bold">{grid_width_meters}</p>
        <section className="container_light outline_transparent background_white">
          <COLOR_SCHEME_EDITOR />
          <hr />
          <RANGE_NUMBER_STEP />
        </section>
        <br />
        <button
          className="button"
          name="point"
          onClick={this.on_toggle_and_save}
        >
          Simpan
        </button>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  toolbox: state.toolbox,
  map: state.map,
  layer: state.layer,
  properties: state.properties,
  loading_reducer: state.loading_reducer,
  project: state.project,
});

export default connect(mapStateToProps, {
  importLayer,
  set_value_toolbox,
  status_toolbox,
})(GRID_STEP_3);
