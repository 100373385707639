import React, { Component } from "react";
import { FileUploader } from "react-drag-drop-files";
import "handsontable/dist/handsontable.full.min.css";
import { registerAllModules } from "handsontable/registry";
import { HotTable } from "@handsontable/react";
import { connect } from "react-redux";

import { snackbar } from "../../App/actions/snackbarActions";
import {
  convertExcelDataToHandsontableFormat,
  setStyleHandsonTable,
} from "../../App/validation/convert_data";
import dict from "../../Data/dict.json";
import SheetButton from "./SheetButton";
import { getGeoLayer } from "../../App/reduxHelper/layer";

import Modal from "../common_modal/Modal";
import EXCEL_AI from "./EXCEL_AI";
import PANGGIL_MENU from "./PANGGIL_MENU";
import domain_list from "../../Data/domain_list";

registerAllModules();

class SelectTableContent extends Component {
  state = {
    modal_ai: false,
  };

  toggle_ai = () => {
    this.setState({
      modal_ai: !this.state.modal_ai,
    });
  };

  componentDidMount() {
    const { hotTableRef, handleKeyDown } = this.props;
    const hotInstance = hotTableRef?.current?.hotInstance;

    // Tambahkan hook `beforeKeyDown` untuk menangkap shortcut
    if (hotInstance) hotInstance?.addHook("beforeKeyDown", handleKeyDown);
  }

  // handleBeforeKeyDown = (event) => {
  //   const { hotTableRef, handleKeyDown } = this.props;
  //   const hotInstance = hotTableRef?.current?.hotInstance;
  //   const activeEditor = hotInstance?.getActiveEditor();

  //   if (activeEditor) {
  //     console.log(activeEditor)
  //     // const editorElement = activeEditor?.getElement();
  //     // const currentValue = editorElement?.value;

  //     // // Manipulasi atau ubah nilai di sini
  //     // if (event.key === "Enter") {
  //     //   // Misalnya, ganti nilai menjadi "Hello World" saat tekan Enter
  //     //   editorElement.value = "Hello World";
  //     // }
  //   }
  // };

  componentWillUnmount() {
    const { hotTableRef, handleKeyDown } = this.props;
    const hotInstance = hotTableRef?.current?.hotInstance;

    // Hapus hook untuk menghindari kebocoran event listener
    if (hotInstance) hotInstance?.removeHook("beforeKeyDown", handleKeyDown);
  }

  render() {
    const language = localStorage?.language || "ina";
    const { modal_ai } = this.state;
    const {
      define_need_to_show,
      selectedCell,
      worksheet,
      uploaded_file,
      focused,
      selectedSheet,
      sheetnames,
      setSheetnames,
      setSelectedSheet,
      setWorksheet,
      handle_selected_cell,
      setFocused,
      handleRefCol,
      handle_edit_cell,
      hotTableRef,
      isDeleting,
    } = this.props;

    const sheet =
      worksheet?.find((obj) => obj.metadata.id_ori === selectedSheet) || {};
    const is_mobile = window.innerWidth < 600;

    const domain = this.props.auth.domain;
    const show_rdtr = domain_list?.[domain]?.show_rdtr;

    let textfield = "";
    if (selectedCell?.f) {
      textfield = `=${selectedCell?.f}`;
    } else if (selectedCell?.R) {
      const { sheet_id, cell_ref } = selectedCell?.R;
      textfield = `@ref?id=${sheet_id}&cell=${cell_ref}`;
    } else {
      textfield = selectedCell?.v || "";
    }

    const { data, columns } = convertExcelDataToHandsontableFormat({
      sheet,
      selectedSheet,
      rawData: worksheet,
    });

    const modal_ai_content = modal_ai && (
      <Modal
        title="AI Excel"
        id="modal_ai_content"
        modalSize="full"
        is_hide="show"
        backgroundColor="rgba(255, 255, 255, 1);"
        use_blur={false}
        blur_value="5px"
        borderRadius="5px"
        isOpen={modal_ai}
        onClose={this.toggle_ai}
      >
        <div className="box-body" id="box-body">
          <EXCEL_AI
            worksheet={worksheet}
            geo_layer_id={this.props.geo_layer_id}
            feature_key={this.props.feature_key}
            field_key={this.props.field_key}
          />
        </div>
      </Modal>
    );

    return (
      <main>
        {modal_ai_content}
        {!worksheet && (
          <div>
            <label>Upload your Sheet (.xlsx)</label>
            <div className="border_dash">
              <FileUploader
                classes="container_upload absolute h_full pointer"
                handleChange={(e) => {
                  define_need_to_show(e);
                }}
                name="file"
                types={["xlsx"]}
                onTypeError={(e) => {
                  this.props.snackbar({
                    is_open: true,
                    status: "error",
                    message: e,
                  });
                }}
                children={
                  <div className="container_upload center_perfect">
                    <div>
                      {uploaded_file?.fileName ||
                        `${dict["Drop a file here"][language]}`}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        )}

        {/* {
                worksheet && <Button onClick={() => this.on_dowload_json()}>
                  Download
                </Button>
              } */}

        {worksheet && (
          <section>
            <div className="flex gap_10">
              <input
                type="text"
                value={textfield}
                disabled
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  padding: "8px",
                  fontSize: "16px",
                  display: "inline-block",
                }}
              />

              <PANGGIL_MENU handleRefCol={handleRefCol} focused={focused} />

              {/* {focused ? (
                <Button
                  id="ref_button"
                  onClick={handleRefCol}
                  style={{ height: "40px" }}
                  variant="outlined"
                >
                  Ref
                </Button>
              ) : (
                <Button
                  id="ref_button"
                  onClick={handleRefCol}
                  style={{ height: "40px" }}
                  variant="outlined"
                  disabled={true}
                >
                  Ref
                </Button> 
              )}*/}
              {show_rdtr && (
                <button
                  id="chat_ai"
                  onClick={this.toggle_ai}
                  style={{ height: "40px", width: "140px" }}
                  variant="outlined"
                  className="button_big background_black"
                >
                  Summary AI
                </button>
              )}
            </div>

            <HotTable
              style={{
                border: "1px solid #AAA",
                borderRadius: "4px",
              }}
              cells={(row, col) =>
                setStyleHandsonTable({
                  row,
                  col,
                  data,
                  sheet,
                  worksheet,
                  selectedSheet,
                })
              }
              ref={hotTableRef}
              width="100%"
              data={data}
              columns={columns}
              header
              rowHeaders={true}
              colHeaders={true}
              height={((is_mobile ? 75 : 80) / 100) * window.innerHeight}
              licenseKey="non-commercial-and-evaluation" // for non-commercial use only
              settings={{
                minSpareRows: 5,
                minSpareCols: 5, // Tambahkan baris kosong di akhir
                contextMenu: true, // Menampilkan opsi konteks (cut, copy, paste)
                manualColumnResize: true,
                manualRowResize: true,
                // beforeKeyDown: this.handleBeforeKeyDown,
              }}
              selectionMode="multiple"
              afterSelectionEnd={(startRow, startCol, endRow, endCol) => {
                handle_selected_cell({
                  startRow,
                  startCol,
                  endRow,
                  endCol,
                });
              }}
              // afterSetCellMeta={(a, b, c, d) => {
              //   console.log(a, b, c, d)
              // }}
              afterBeginEditing={(row, column) => {
                const hotInstance = hotTableRef?.current?.hotInstance;
                const editor = hotInstance.getActiveEditor();
                if (editor) {
                  editor.setValue(textfield); // Set initial value directly in the editor
                }
              }}
              // beforeChange={(changed_data, event)=>{
              //   console.log(changed_data, event)
              // }}
              afterChange={(changed_data, event) => {
                if (isDeleting) {
                  // Abaikan perubahan jika sedang menghapus
                  return;
                }

                if (event === "edit") {
                  handle_edit_cell(changed_data);
                }
              }}
              afterDeselect={(e) => {
                setFocused(null);
              }}
              outsideClickDeselects={(e) => {
                return false;
              }}
              modifyColWidth={(width) => {
                if (width > 300) {
                  return 300;
                }
              }}
            />

            {worksheet && (
              <div className="w_full scroll_x_auto">
                <div className="w_full flex_row flex align_center no_wrap gap_10 h_50">
                  <label>Sheet:</label>
                  {sheetnames?.map((item) => (
                    <SheetButton
                      key={item?.id_ori}
                      sheetname={item}
                      selectedSheet={selectedSheet}
                      sheetnames={sheetnames}
                      setSheetnames={setSheetnames}
                      setSelectedSheet={setSelectedSheet}
                      setWorksheet={setWorksheet}
                      worksheet={worksheet}
                    />
                  ))}
                </div>
              </div>
            )}
          </section>
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  const geo_layer = getGeoLayer(state.layer);
  return {
    geo_layer_id: geo_layer?._id,
    fields: geo_layer?.fields,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, {
  snackbar,
})(SelectTableContent);
