/*LIBRARY MODULE*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*PERSONAL COMPONENT*/

/*REDUX FUNCTION*/
import { set_value_project } from "../../App/actions/projectActions";
import {
  get_folder_list_by_parent_folder_id,
  get_project_list_by_folder_id,
} from "../../App/actions/folderProjectAction";

/*PICTURE ASSET*/

/*GENERAL FUNCTION & DATA*/
import dict from "../../Data/dict.json";

/*NON IMPORT*/

class FOLDER_NAVIGATION extends Component {
  state = {};

  on_home = () => {
    this.props.set_value_project({
      key: "folder_object_selected_list",
      value: [],
    });
    this.props.set_value_project({
      key: "folder_id_selected_list",
      value: [],
    });
    this.props.set_value_project({
      key: "folder_object_selected",
      value: {},
    });
    this.props.set_value_project({
      key: "folder_id_selected",
      value: "",
    });
  };

  on_back_folder = (folder) => {
    let { folder_object_selected_list, folder_id_selected_list } =
      this.props.project;
    const index = folder_id_selected_list.findIndex(
      (item) => item === folder?._id
    );
    folder_id_selected_list = folder_id_selected_list.slice(0, index + 1);
    folder_object_selected_list = folder_object_selected_list.slice(
      0,
      index + 1
    );
    this.props.set_value_project({
      key: "folder_object_selected_list",
      value: folder_object_selected_list,
    });
    this.props.set_value_project({
      key: "folder_id_selected_list",
      value: folder_id_selected_list,
    });
    this.props.set_value_project({
      key: "folder_object_selected",
      value: folder,
    });
    this.props.set_value_project({
      key: "folder_id_selected",
      value: folder?._id,
    });
    this.props.get_folder_list_by_parent_folder_id({
      folder_parent_id: folder?._id,
    });
    this.props.get_project_list_by_folder_id({
      folder_id: folder?._id,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";

    //local state

    //global props
    const { folder_object_selected_list } = this.props.project;

    //content
    return (
      <main className="margin_bottom">
        <button
          className="button_pill background_white margin_right middle"
          onClick={this.on_home}
          data-mapid="clickKembali"
        >
          <span className="badge_circle background_grey margin_right">
            <svg
              className="center_child"
              xmlns="http://www.w3.org/2000/svg"
              width={9.5}
              height={7.5}
              viewBox="0 0 2.514 1.984"
            >
              <path
                d="M.198.992h2.117M.986.198.198.992l.788.794"
                style={{
                  fill: "none",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: 0.39688,
                  stroke: "#000",
                }}
              />
            </svg>
          </span>
          {dict?.["Home"]?.[language]}
        </button>

        <svg
          className="middle margin_right"
          xmlns="http://www.w3.org/2000/svg"
          width={7}
          viewBox="0 0 2.646 5.287"
        >
          <path
            d="M0 0v5.287l2.646-2.644z"
            style={{
              fillRule: "evenodd",
              fill: "#000",
              paintOrder: "stroke markers fill",
            }}
          />
        </svg>
        {folder_object_selected_list.map((folder, idx) => {
          return (
            <div
              key={idx}
              className="middle"
              onClick={this.on_back_folder.bind(this, folder)}
            >
              <button className="button_pill background_white margin_right">
                {folder?.name?.slice(0, 20)}
              </button>
              <svg
                className="middle margin_right"
                xmlns="http://www.w3.org/2000/svg"
                width={7}
                viewBox="0 0 2.646 5.287"
              >
                <path
                  d="M0 0v5.287l2.646-2.644z"
                  style={{
                    fillRule: "evenodd",
                    fill: "#000",
                    paintOrder: "stroke markers fill",
                  }}
                />
              </svg>
            </div>
          );
        })}
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  project: state.project,
});

export default connect(mapStateToProps, {
  set_value_project,
  get_folder_list_by_parent_folder_id,
  get_project_list_by_folder_id,
})(FOLDER_NAVIGATION);
