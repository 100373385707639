const themeColors = [
  "#FFFFFF", // Background 1
  "#000000", // Text 1
  "#44546A", // Text 2
  "#E7E6E6", // Background 2
  "#5B9BD5", // Accent 1
  "#ED7D31", // Accent 2
  "#A5A5A5", // Accent 3
  "#FFC000", // Accent 4
  "#4472C4", // Accent 5
  "#70AD47"  // Accent 6
];

const applyTint = (themeIndex, tint = 0) => {
  // Ambil warna tema berdasarkan indeks
  const baseColor = themeColors[themeIndex];
  if (!baseColor) return null;

  // Konversi warna HEX ke RGB
  const rgb = hexToRgb(baseColor);

  // Terapkan modifikasi tint
  const modifiedRgb = rgb.map((channel) => {
    if (tint > 0) {
      // Mencerahkan warna (menambahkan putih)
      return Math.round(channel + (255 - channel) * tint);
    } else {
      // Menggelapkan warna (menambahkan hitam)
      return Math.round(channel * (1 + tint));
    }
  });

  // Konversi kembali ke HEX
  return rgbToHex(modifiedRgb);
}

/**
 * Konversi HEX ke RGB.
 * @param {string} hex - Warna HEX.
 * @returns {number[]} - Array RGB.
 */
const hexToRgb = (hex) => {
  const bigint = parseInt(hex.slice(1), 16);
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
}

/**
 * Konversi RGB ke HEX.
 * @param {number[]} rgb - Array RGB.
 * @returns {string} - Warna HEX.
 */
const rgbToHex = (rgb) => {
  return `#${rgb.map((channel) => channel.toString(16).padStart(2, "0")).join("")}`;
}

export { applyTint, hexToRgb, rgbToHex }