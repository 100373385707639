import styled from "styled-components";

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  z-index: 3;
  width: ${(props) => {
    switch (props.modalSize) {
      case "large":
        return "calc( 100vw - 100px )";
      case "small":
        return "480px";
      case "medium":
        return "900px";
      case "full":
        return "calc( 100vw - 20px )";
      default:
        return "480px";
    }
  }};
  max-width: ${(props) => {
    switch (props.modalSize) {
      case "full":
        return "calc( 100vw - 20px )";
      default:
        return "calc( 100vw - 100px )";
    }
  }};
  max-height: ${(props) => {
    if (props.modalSize === "full") {
      if (props.is_hide) {
        return "calc( 100vh - 20px )";
      } else {
        return "100vh";
      }
    } else {
      return "calc( 100vh - 150px )";
    }
  }};
  box-sizing: border-box;
  margin: auto;
  &.fade-in {
    opacity: 1;
    -webkit-transition: 0.01s;
    -moz-transition: 0.01s;
    -o-transition: 0.01s;
    transition: 0.01s;
  }
  &.fade-out {
    opacity: 0;
    -webkit-transition: 0.01s;
    -moz-transition: 0.01s;
    -o-transition: 0.01s;
    transition: 0.01s;
  }
  .background {
    background: #1f6fae2a;
    ${(props) => {
      switch (
        false //props.blur
      ) {
        case false:
          return "";
        case true:
          return `-webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
          filter: blur(3px);`;
        default:
          return `-webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
          filter: blur(3px);`;
      }
    }};
    position: fixed;
    z-index: 1040;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    outline: 0;
  }
  .box-header {
    text-align: right;
  }
  .box-dialog {
    border-radius: ${(props) => {
      if (props.borderRadius) {
        return props.borderRadius;
      } else {
        return "7px";
      }
    }};
    z-index: 1050;
    width: 100%;
    background-color: ${(props) => {
      if (props.backgroundColor) {
        return props.backgroundColor;
      } else {
        return "#fff";
      }
    }};
    ${(props) => {
      if (props.use_blur) {
        return `backdrop-filter: blur(${props.blur_value});`;
      } else {
        return "";
      }
    }};
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.071);
    .box-body {
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;
      padding: ${(props) => {
        switch (props.modalSize) {
          case "full":
            return "0px;";
          default:
            return "0px 24px 0px 24px;";
        }
      }};
      max-height: ${(props) => {
        if (props.modalSize === "full") {
          if (props.is_hide) {
            return "calc( 100vh - 20px )";
          } else {
            return "100vh";
          }
        } else {
          return "calc( 100vh - 150px )";
        }
      }};
      height: ${(props) => {
        if (props.modalSize === "full") {
          if (props.is_hide) {
            return "calc( 100vh - 70px )";
          } else {
            return "calc( 100vh - 50px )";
          }
        } else {
          return "auto";
        }
      }};
      padding-bottom: ${(props) => {
        switch (props.modalSize) {
          case "full":
            return "0px;";
          default:
            return "20px;";
        }
      }};
    }
    .x-close {
      font-size: 35px;
      line-height: 35px;
      font-weight: 400;
      text-shadow: none;
      color: black;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
  }
`;

export default Modal;
