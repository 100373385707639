/*LIBRARY*/
import React, { Component } from "react";
import { connect } from "react-redux";

/*COMPONENT*/

/*REDUX*/
import { set_value_properties } from "../../App/actions/propertiesActions";
import { status_action } from "../../App/actions/mapActions";
import {
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
} from "../../App/actions/dataActions";

/*PICTURE SET*/

/*FUNCTION*/

/*DATA*/

/*CONST*/

class LEGEND_NUMBER_STEP extends Component {
  state = {};

  on_filter_by_number = ({ field_key, idx, value_min, value_max }) => {
    console.table({ field_key, value_min, value_max });
  };

  render() {
    //local storage

    //local state

    //global props
    const { geo_layer_list, layer_id } = this.props.layer;

    //content
    const layer = geo_layer_list.find(
      (item) => item?.geo_layer?._id === layer_id
    )?.geo_layer;
    const style_array = layer?.style_array || [];
    const default_style_key = layer?.default_style_key || "";
    const style_object = style_array.find(
      (item) => item.key === default_style_key
    );

    const { style_type, paint_object } = style_object;
    let color_key;
    if (style_type === "circle") {
      color_key = "circle-color";
    } else if (style_type === "line") {
      color_key = "line-color";
    } else if (style_type === "fill") {
      color_key = "fill-color";
    }

    let rows = [];
    let array_number_step = paint_object?.[color_key] || [];

    array_number_step = array_number_step.slice(2, array_number_step.length);

    // const test_1 = " ≤ x < ";
    const test_2 = " - ";

    // const test_mid_1 = "x < ";
    const test_mid_2 = " < ";

    // const test_big_1 = "x ≥ ";
    const test_big_2 = "≥ ";

    array_number_step.forEach((item, idx) => {
      if (idx % 2 === 1) {
        rows.push(
          <tr key={idx}>
            <td className="text_right">
              {array_number_step?.[idx - 2] !== undefined
                ? `${array_number_step?.[idx - 2]}${test_2}`
                : test_mid_2}
              {item}
            </td>
            <td style={{ width: "20px", verticalAlign: "middle" }}>
              <div
                style={{
                  backgroundColor: array_number_step?.[idx - 1], // Color is at the next index
                  width: "20px",
                  height: "20px",
                  border: "1px solid black",
                  margin: "auto",
                }}
              />
            </td>
          </tr>
        );
      }
    });

    rows.push(
      <tr key="last">
        <td className="text_right">
          {test_big_2}
          {array_number_step?.[array_number_step.length - 2]}
        </td>
        <td style={{ width: "20px", verticalAlign: "middle" }}>
          <div
            style={{
              backgroundColor:
                array_number_step?.[array_number_step.length - 1],
              width: "20px",
              height: "20px",
              border: "1px solid black",
              margin: "auto",
            }}
          />
        </td>
      </tr>
    );
    return (
      <main style={{ maxHeight: "250px", overflowY: "scroll" }}>
        <table className="table full_width">
          <thead>
            <tr>
              <th>Range</th>
              <th>Color</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  layer: state.layer,
  properties: state.properties,
  loading_reducer: state.loading_reducer,
});

export default connect(mapStateToProps, {
  set_value_properties,
  status_action,
  filter_isi_layer,
  reset_filter,
  undo_filter_isi_layer,
})(LEGEND_NUMBER_STEP);
