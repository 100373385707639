/*LIBRARY MODULE*/
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { Resizable } from "re-resizable";

/*PERSONAL COMPONENT*/
import { Draggable } from "../reuseable";
import Modal from "../common_modal/Modal";
import MAP_COMPONENT from "../libre_layer/MAP_COMPONENT";
import RIGHT_SIDEBAR from "../sidebar_map_right/RIGHT_SIDEBAR";
import CreateLayer from "../editor_sidebar/CreateLayer";
import ImportGeojson from "../editor_upload_data/ImportGeojson";
import CreateFolder from "../editor_sidebar/CreateFolder";
import JOIN_TABLE from "../editor_search_import/JOIN_TABLE";
import SEARCH_LAYER from "../editor_search_import/SEARCH_LAYER";
import EDIT_PROJECT from "../project_dashboard/EDIT_PROJECT";
import NotificationProject from "../editor_trivial/NotificationProject";
import LayerListModeExpand from "../editor_sidebar/LayerListModeExpand";
import LayerListModeButton from "../editor_sidebar/LayerListModeButton";
import EditorProperties from "../editor_table/EditorProperties";
import TableFix from "../editor_trivial/TableFix";
import FormFeature from "../editor_table/TableEditViaForm";
import ChatLayer from "../editor_trivial/ChatLayer";
import SEARCH_BAR_MAP from "../search_map/SEARCH_BAR_MAP";
import ModalApiInput from "../editor_api/ModalApiInput";
import EDIT_LABEL from "../editor_sidebar/EDIT_LABEL";
import EDIT_LAYER_STYLE from "../layer_style_editor/EDIT_LAYER_STYLE";

/*REDUX FUNCTION*/
import {
  setTabelStatus,
  clearGeolayerList,
  setChatStatus,
  getDetailLayerByLinkWOgeojson,
} from "../../App/actions/layerActions";
import {
  getDetailLayerById,
  removeDuplicateFeatures,
  setMapMode,
  setStatisticMode,
} from "../../App/actions/layerNewActions";
import { set_value_layer } from "../../App/actions/layerActions";
import {
  openModal,
  setModeList,
  setLanguage,
} from "../../App/actions/authActions";
import {
  set_value_properties,
  get_detail_many_layer,
} from "../../App/actions/propertiesActions";
import { getProjectDetailByLink } from "../../App/actions/projectActions";
import { status_action } from "../../App/actions/mapActions";

/*PICTURE ASSET*/

/*GENERAL*/
import dict from "../../Data/dict.json";
import domain_list from "../../Data/domain_list";
import EDIT_SYMBOL_ICON from "../layer_style_editor/EDIT_SYMBOL_ICON";

/*NON IMPORT*/
const batasPixel = 1172;

class MAP_EDITOR extends Component {
  state = {
    //modal
    modalCreateLayer: false,
    modalImportLayer: false,
    modalOverviewLayer: false,
    modalImportCsv: false,
    modalCreateFolder: false,
    modalJoinTable: false,
    modalAttachLayer: false,
    modalApiBridging: false,


    //number
    new_layer_height: 0,
    import_layer_height: 0,
    join_table_layer_height: 0,
    overview_layer_height: 0,
    import_csv_height: 0,
    new_folder_height: 0,
    modalFullscreen: false,
    modal_opsi: false,
    modalSelectMap: false,
    modalEdit: false,
    modalStatistic: false,
    modal_edit_project: false,

    //other
    tableComponent: {
      height: 0,
    },
    width: window.innerWidth,
  };

  componentDidMount() {
    const geo_layer_list = this.props.layer?.geo_layer_list || [];
    const geo_layer_link = this?.props?.geo_layer_link;
    const mode = this?.props?.mode;
    const geo_project_link = this?.props?.geo_project_link;

    this.props.getProjectDetailByLink(geo_project_link);

    let layer = geo_layer_list.filter(
      (e) => e.geo_layer.link === geo_layer_link
    );

    this.props.setStatisticMode("sini");

    this.timer = null;
    if (document.getElementById("new_layer")) {
      document.getElementById("new_layer").style.height = "19px";
      document.getElementById("import_layer").style.height = "19px";
      document.getElementById("new_folder").style.height = "19px";
      document.getElementById("join_table").style.height = "19px";
    }

    if (document.getElementById("mySidebar")) {
      const width = document.getElementById("mySidebar").offsetWidth;
      localStorage.setItem("leftbar_width", width);
    }

    if (document?.getElementById("statistic_resume_box")) {
      localStorage.setItem("rightbar_width", 0);
    }

    if (mode === "view" && layer.length > 0) {
      layer = layer[0].geo_layer;
      const { geo_project } = this.props.project;
      if (layer?._id) {
        const body = {
          geo_layer_id: layer._id,
          geo_project_id: geo_project._id,
          flyStatus: true,
        };
        this.props.getDetailLayerById(body);
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    //Get layer data if layer_link provided on the props params (mode:view)
    const { geo_layer_list } = this.props.layer;
    const geo_layer_list_prev = prevProps.layer.geo_layer_list;
    if (
      geo_layer_list.length !== geo_layer_list_prev.length &&
      geo_layer_list_prev.length === 0
    ) {
      const geo_layer_link = this?.props?.geo_layer_link;
      const mode = this?.props?.mode;
      let layer = geo_layer_list.filter(
        (e) => e.geo_layer.link === geo_layer_link
      );
      if (mode === "view" && layer.length > 0) {
        layer = layer[0].geo_layer;
        const { geo_project } = this.props.project;
        if (layer?._id) {
          const body = {
            geo_layer_id: layer._id,
            geo_project_id: geo_project._id,
            flyStatus: true,
          };
          this.props.getDetailLayerById(body);
        }
      }
      //Get layer data and activate chat if layer_link provided on the props params (mode:chat)
      if (mode === "chat" && layer.length > 0) {
        layer = layer[0];
        const { geo_project } = this.props.project;
        if (layer?._id) {
          const body = {
            geo_layer_id: layer._id,
            geo_project_id: geo_project._id,
            flyStatus: true,
          };
          this.props.getDetailLayerById(body);
          this.props.getDetailLayerByLinkWOgeojson(layer.link);
          this.props.setChatStatus(true);
        }
      }
    }

    if (this.props.layer.tableStatus) {
      if (document.getElementById("tableBar")) {
        const height = document.getElementById("tableBar").offsetHeight;
        localStorage.setItem("table_height", height);
      }
    } else {
      localStorage.setItem("table_height", 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
    clearTimeout(this.timer);
    this.props.setTabelStatus(false);
    this.props.clearGeolayerList();
  }

  on_close_modal_edit_label_config = () => {
    this.props.set_value_properties({
      key: "modal_edit_label_config",
      value: false,
    });
  };

  on_close_modal_edit_layer_style = () => {
    this.props.set_value_properties({
      key: "modal_edit_layer_style",
      value: false,
    });
    this.props.set_value_properties({
      key: "is_preview_on",
      value: false,
    });
    this.props.status_action();
  };

  on_close_modal_edit_symbol_icon = () => {
    this.props.set_value_properties({
      key: "modal_edit_symbol_icon",
      value: false,
    });
    this.props.set_value_properties({
      key: "is_preview_on",
      value: false,
    });
    this.props.status_action();
  };

  toggle_edit_project = () => {
    this.setState({ modal_edit_project: !this.state.modal_edit_project });
  };

  toggle_link_edit = () => {
    this.props.set_value_layer({
      key: "modal_link_edit",
      value: !this.props.layer.modal_link_edit,
    });
  };

  setTableComponent = (d) => {
    const height = document.getElementById("tableBar").offsetHeight;
    this.setState({
      tableComponent: {
        ...this.state.tableComponent,
        height: height,
      },
    });
    localStorage.setItem("table_height", height);
  };

  onSetModeList = (mode) => {
    this.props.setModeList(mode);
    setTimeout(
      function () {
        this.setState({ modal_opsi: false });
      }.bind(this),
      700
    );
  };

  toggleOpsi = () => {
    this.setState({ modal_opsi: !this.state.modal_opsi });
  };

  onOpenModal = (name) => {
    this.props.openModal(name);
  };

  toggleFullscreen = () => {
    this.setState({ modalFullscreen: !this.state.modalFullscreen });
  };

  toggleAttachLayer = () => {
    this.setState({ modalAttachLayer: !this.state.modalAttachLayer });
  };

  toggleCreateLayer = () => {
    this.setState({ modalCreateLayer: !this.state.modalCreateLayer }, () => {
      if (this.state.modalCreateLayer) {
        document.getElementById("new_layer").style.height = "100%";
      } else {
        document.getElementById("new_layer").style.height = "19px";
      }
    });
  };

  toggleImportLayer = () => {
    this.setState({ modalImportLayer: !this.state.modalImportLayer }, () => {
      if (this.state.modalImportLayer) {
        document.getElementById("import_layer").style.height = "100%";
      } else {
        document.getElementById("import_layer").style.height = "19px";
      }
    });
  };

  toggleApiBridging = () => {
    this.setState({ modalApiBridging: !this.state.modalApiBridging }, () => {
      if (this.state.modalApiBridging) {
        document.getElementById("api_bridging").style.height = "100%";
      } else {
        document.getElementById("api_bridging").style.height = "19px";
      }
    });
  };

  toggleJoinTable = () => {
    this.setState({ modalJoinTable: !this.state.modalJoinTable }, () => {
      if (this.state.modalJoinTable) {
        document.getElementById("join_table").style.height = "100%";
      } else {
        document.getElementById("join_table").style.height = "19px";
      }
    });
  };

  toggleCreateFolder = () => {
    this.setState({ modalCreateFolder: !this.state.modalCreateFolder }, () => {
      if (this.state.modalCreateFolder) {
        document.getElementById("new_folder").style.height = "100%";
      } else {
        document.getElementById("new_folder").style.height = "19px";
      }
    });
  };

  /*
  left --> kiri, list layer
  right --> kanan, detail layer, pie chart dsb
  */
  toggle_sidebar_left = () => {
    const { sidebar_left_status } = this.props.properties;
    this.props.set_value_properties({
      key: "sidebar_left_status",
      value: !sidebar_left_status,
    });
    if (!sidebar_left_status) {
      this.openNav();
    } else {
      this.closeNav();
    }
  };

  //Open sidebar
  openNav = () => {
    document.getElementById("mySidebar").style.left = "0";
    document.getElementById("buttonSlider").style.left = "305px";
    if (document.getElementById("tableBar")) {
      document.getElementById("tableBar").style.minWidth =
        "calc((100% - 336px))";
      document.getElementById("tableBar").style.width = "calc((100% - 336px))";
    }
    const width = document.getElementById("mySidebar").offsetWidth;
    localStorage.setItem("leftbar_width", width);
  };

  //Close sidebar
  closeNav = () => {
    document.getElementById("mySidebar").style.left = "-340px";
    document.getElementById("buttonSlider").style.left = "23px";
    if (document.getElementById("tableBar")) {
      document.getElementById("tableBar").style.maxWidth = "100%";
      document.getElementById("tableBar").style.width = "100%";
    }
    localStorage.setItem("leftbar_width", 0);
  };

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth }, () => {
      if (this.state.width < batasPixel) {
        this.closeNav();
      } else {
        this.openNav();
      }
    });
  };

  handleTableComponent = (height, width) => {
    this.setState({
      tableComponent: {
        height: height,
        width: width,
      },
    });
  };

  setSelectedMap = (val) => {
    this.props.setMapMode(val);
  };

  toggleModalSelectMap = () => {
    const { modalSelectMap } = this.state;
    this.setState({
      modalStatistic: false,
      modalSelectMap: !modalSelectMap,
    });
  };

  toggleModalStatisticMode = () => {
    const { modalStatistic } = this.state;
    this.setState({
      modalStatistic: !modalStatistic,
      modalSelectMap: false,
    });
  };


  toggleEdit = (geo_project) => {
    if (geo_project?._id) {
      this.props.setProject(geo_project);
    }
    this.setState({ modalEdit: !this.state.modalEdit });
  };

  setStatisticModeFunc = (mode) => {
    this.props.setStatisticMode(mode);
    this.props.set_value_properties({
      key: "sidebar_right_status",
      value: true,
    });
  };

  render() {
    //local storage
    const language = localStorage?.language || "ina";
    let mode_list = localStorage.mode_list;

    //local state
    const {
      modal_edit_project,
      modalCreateLayer,
      modalImportLayer,
      modalAttachLayer,
      modalJoinTable,
      modalCreateFolder,
      modalApiBridging,
    } = this.state;

    //global props
    const { geo_project, loadingDetail } = this.props.project;
    const { chatStatus, loadingList, loadingProcess, itemLoading } =
      this.props.layer;
    const { geo_project_link } = this.props;
    const domain = this?.props?.auth?.domain;
    const mode = this?.props?.mode;
    const {
      sidebar_right_status,
      modal_edit_label_config,
      modal_edit_layer_style,
      modal_edit_symbol_icon,
      sidebar_left_status,
      is_mobile,
    } = this.props.properties;

    //content
    let box_mobile_height = null;
    if (is_mobile) {
      box_mobile_height = window.innerHeight;
    }

    const itemContent =
      loadingList ||
        loadingDetail ||
        !geo_project?._id ||
        (loadingProcess && itemLoading === "get_list_by_link_project") ? (
        <div className="button_standard">
          {dict["Loading GIS data"][language]}...
        </div>
      ) : geo_project.mode_folder === "button" ? (
        <div>
          <LayerListModeButton />
        </div>
      ) : (
        <div>
          <LayerListModeExpand />
        </div>
      );

    const modalAttachLayerContent = modalAttachLayer && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modalAttachLayer}
        onClose={this.toggleAttachLayer}
      >
        <div className="box-body">
          <SEARCH_LAYER />
        </div>
      </Modal>
    );

    const modalFullscreenContent = this.props.layer.modal_link_edit && (
      <main
        style={{
          zIndex: "99999",
          width: "100vw",
          height: "100vh",
          backgroundColor: "#fff",
          position: "fixed",
          top: "0px",
          left: "0px",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            textAlign: "center",
          }}
        >
          <section
            style={{
              width: "300px",
              height: "70px",
              textAlign: "center",
              margin: "auto",
              backgroundColor: "#fff",
              borderRadius: "20px",
              padding: "5px",
              boxShadow: "0 0 6px #9d9d9d53",
              marginBottom: "10px",
            }}
          >
            <div className="text_bold" style={{ marginTop: "5px" }}>
              {dict["Geometry form"][language]}
              <div
                onClick={this.toggle_link_edit}
                className="button_standard_tight"
              >
                {dict["Close"][language]}
              </div>
            </div>
          </section>
        </div>
        <FormFeature />
      </main>
    );

    const opsi = [
      {
        key: "default",
        name: "Default",
      },
      {
        key: "compact",
        name: "Compact",
      },
      {
        key: "title_only",
        name: "Title only",
      },
    ];

    const option_content = (
      <main className="option_content">
        {opsi.map((e) => {
          let css_id = "outline_white";
          if (mode_list === e.key) {
            css_id = "outline_blue";
          }
          return (
            <button
              key={e.key}
              onClick={this.onSetModeList.bind(this, e.key)}
              className="button_pill_tight_new button_inactive"
              id={css_id}
            >
              {dict?.[e?.name]?.[language]}
            </button>
          );
        })}
      </main>
    );

    let final_content = (
      <main>
        <section
          className="inputBox"
          id="new_layer"
          style={{ marginTop: "5px" }}
        >
          <div
            onClick={this.toggleCreateLayer}
            style={{ display: "flex", paddingBottom: 4, cursor: "pointer" }}
          >
            <div className="noselect" style={{ flex: 1 }}>
              {dict["New Layer"][language]}
            </div>
            <div>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="caret-down"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  transition: "transform 0.5s ease 0s",
                  transform: `${modalCreateLayer ? "rotate(180deg)" : "rotate(0deg)"
                    }`,
                }}
              >
                <path
                  fill="currentColor"
                  d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                ></path>
              </svg>
            </div>
          </div>
          <CreateLayer />
        </section>
        <section className="inputBox" id="import_layer">
          <div
            onClick={this.toggleImportLayer}
            style={{ display: "flex", paddingBottom: 4, cursor: "pointer" }}
          >
            <div className="noselect" style={{ flex: 1 }}>
              {dict["Upload File"][language]}
            </div>
            <div>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="caret-down"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  transition: "transform 0.5s ease 0s",
                  transform: `${modalImportLayer ? "rotate(180deg)" : "rotate(0deg)"
                    }`,
                }}
              >
                <path
                  fill="currentColor"
                  d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                ></path>
              </svg>
            </div>
          </div>
          <ImportGeojson />
        </section>

        <section className="inputBox" id="api_bridging">
          <div
            onClick={this.toggleApiBridging}
            style={{ display: "flex", paddingBottom: 4, cursor: "pointer" }}
          >
            <div className="noselect" style={{ flex: 1 }}>
              {dict["Connect to API"][language]}
            </div>
            <div>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="caret-down"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  transition: "transform 0.5s ease 0s",
                  transform: `${modalApiBridging ? "rotate(180deg)" : "rotate(0deg)"
                    }`,
                }}
              >
                <path
                  fill="currentColor"
                  d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                ></path>
              </svg>
            </div>
          </div>
          <ModalApiInput />
        </section>

        <section className="inputBox" id="join_table">
          <div
            onClick={this.toggleJoinTable}
            style={{ display: "flex", paddingBottom: 4, cursor: "pointer" }}
          >
            <div className="noselect" style={{ flex: 1 }}>
              {dict["Join Table"][language]}
            </div>
            <div>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="caret-down"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  transition: "transform 0.5s ease 0s",
                  transform: `${modalJoinTable ? "rotate(180deg)" : "rotate(0deg)"
                    }`,
                }}
              >
                <path
                  fill="currentColor"
                  d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                ></path>
              </svg>
            </div>
          </div>
          <JOIN_TABLE />
        </section>
        <section
          className="inputBox"
          id="attach_layer"
          style={{ height: "20px" }}
        >
          <main
            className="text_collapse noselect"
            style={{ marginBottom: "10px" }}
            onClick={this.toggleAttachLayer}
          >
            <section style={{ display: "flex" }}>
              <div className="noselect" style={{ whiteSpace: "nowrap" }}>
                {dict["Attach Layer"][language]}
              </div>
            </section>
          </main>
        </section>

        <section className="inputBox" id="new_folder">
          <div
            onClick={this.toggleCreateFolder}
            style={{ display: "flex", paddingBottom: 4, cursor: "pointer" }}
          >
            <div className="noselect" style={{ flex: 1 }}>
              {dict["New Folder Layer"][language]}
            </div>
            <div>
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="caret-down"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  transition: "transform 0.5s ease 0s",
                  transform: `${modalCreateFolder ? "rotate(180deg)" : "rotate(0deg)"
                    }`,
                }}
              >
                <path
                  fill="currentColor"
                  d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                ></path>
              </svg>
            </div>
          </div>
          <CreateFolder />
        </section>


        <Link
          className="button_standard noselect"
          id="blue"
          to={`/editor/${geo_project_link}/no_layer/notif`}
          style={{ marginBottom: "10px", display: "block", textAlign: "left" }}
        >
          {dict?.["View Activities"]?.[language]}
        </Link>
        <hr />
        {option_content}
        {itemContent}
      </main>
    );

    if (mode === "notif") {
      final_content = (
        <NotificationProject geo_project_link={geo_project_link} />
      );
    }

    let table_content = (
      <EditorProperties
        layer={this.props.layer.geo_layer}
        onOpenModalTutorial={this.onOpenModal}
      />
    );

    if (
      this.props?.layer?.geo_layer?.status === "attached" ||
      this.props?.layer?.geo_layer?.status === "storage_json"
    ) {
      <TableFix layer={this.props.layer.geo_layer} />;
    }

    const modalEditContent = modal_edit_project && (
      <Modal
        modalSize="medium"
        id="modal"
        isOpen={modal_edit_project}
        onClose={this.toggle_edit_project}
      >
        <div className="box-body">
          <EDIT_PROJECT
            toggle_edit_project={this.toggle_edit_project}
            mode="editor"
          />
        </div>
      </Modal>
    );

    return (
      <main>
        {modalFullscreenContent}
        {modalAttachLayerContent}
        {modalEditContent}
        {chatStatus && <ChatLayer />}

        <section
          className="openbtn"
          id="buttonSlider"
          onClick={this.toggle_sidebar_left}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            style={{
              width: "1.25rem",
              height: "1.25rem",
              transition: "transform 0.5s ease 0s",
              transform: `${sidebar_left_status ? "rotate(0deg)" : "rotate(180deg)"
                }`,
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
            />
          </svg>
        </section>

        <MAP_COMPONENT is_editable={true} />
        <SEARCH_BAR_MAP />
        <section id="mySidebar" className="sidebar">
          <Link to={"/dashboard"}>
            <img
              src={domain_list?.[domain]?.logo}
              height="30px"
              alt={domain_list?.[domain]?.short_title}
            />
          </Link>
          <div className="text_bold" title={geo_project.name}>
            {geo_project?.name?.length > 52
              ? `${geo_project.name.slice(0, 48)}...`
              : geo_project.name}{" "}
            <div
              className="button_small"
              id="blue"
              onClick={this.toggle_edit_project}
            >
              Edit
            </div>
          </div>
          <hr />
          {final_content}
        </section>
        {this.props.layer.tableStatus && !this.props.properties.is_mobile && (
          <Resizable
            id="tableBar"
            boundsByDirection={true}
            defaultSize={{
              width: "100%",
              height: 300,
            }}
            onResizeStop={(e, direction, ref, d) => {
              this.setTableComponent(d);
            }}
            maxHeight="100vh"
            minHeight="75px"
            minWidth="calc((100% - 336px))"
            maxWidth="calc((100% - 336px))"
            style={{
              position: "fixed",
              bottom: 0,
              right: 0,
              maxHeight: window.innerHeight - 5,
              minHeight: "30px",
              zIndex: 3,
              backgroundColor: "#ffffff",
              color: "#1e5e96ff",
            }}
          >
            {table_content}
          </Resizable>
        )}

        {modal_edit_label_config && (
          <Draggable
            title={dict?.["Map labeling"]?.[language]}
            toggle_close={this.on_close_modal_edit_label_config}
            style={{
              background: "white",
              width: "310px",
              zIndex: 5,
            }}
            initialPosition={{
              x: window.innerWidth - 310 - 50,
              y: 5,
            }}
          >
            <EDIT_LABEL />
          </Draggable>
        )}

        {modal_edit_layer_style && (
          <Draggable
            title="Layer style"
            toggle_close={this.on_close_modal_edit_layer_style}
            style={{
              background: "white",
              width: "310px",
              zIndex: 5,
            }}
            initialPosition={{
              x: window.innerWidth - 310 - 50,
              y: 5,
            }}
          >
            <EDIT_LAYER_STYLE />
          </Draggable>
        )}

        {modal_edit_symbol_icon && (
          <Draggable
            title="Edit Icon POI"
            toggle_close={this.on_close_modal_edit_symbol_icon}
            style={{
              background: "white",
              width: "310px",
              zIndex: 5,
            }}
            initialPosition={{
              x: window.innerWidth - 310 - 50,
              y: 5,
            }}
          >
            <EDIT_SYMBOL_ICON />
          </Draggable>
        )}

        {is_mobile ? (
          <motion.section
            className="box_mobile"
            id="box_mobile"
            drag="y"
            dragConstraints={{
              left: 0,
              right: 0,
              top: -(box_mobile_height - 250),
              bottom: 100,
            }}
            dragElastic={0.3}
            dragMomentum={false}
          >
            <div className="button_mobile">
              <div className="button_show_mobile" />
            </div>
            <div className="mobile_scroll">
              {this.props.layer.tableStatus ? (
                <div
                  style={{
                    overflowX: "auto",
                    height: "100%",
                  }}
                >
                  <button
                    className="button"
                    id="delete"
                    onClick={() => this.props.setTabelStatus(false)}
                  >
                    Close Table
                  </button>
                  <EditorProperties />
                </div>
              ) : (
                <RIGHT_SIDEBAR
                  loadingList={loadingList}
                  loadingDetail={loadingDetail}
                  geo_project={geo_project}
                  language={language}
                  tableComponent={this.state.tableComponent}
                  extraHeight={500}
                />
              )}
            </div>
          </motion.section>
        ) : (
          sidebar_right_status && (
            <RIGHT_SIDEBAR
              loadingList={loadingList}
              loadingDetail={loadingDetail}
              geo_project={geo_project}
              language={language}
              tableComponent={this.state.tableComponent}
            />
          )
        )}

        <Helmet>
          <title>{`${geo_project?._id ? "EDITOR" : geo_project.name}`}</title>
          <meta
            name="description"
            content={`${geo_project?._id ? "EDITOR" : geo_project.description}`}
          />
        </Helmet>

        <style>{`
      body{
        padding-top: 0px;
        overflow-y: hidden;
      }
      #navbarDefault{ visibility: hidden;}
      .top_nav{ visibility: hidden;}
      .sidebar_home{ visibility: hidden;}
      .nav_bottom{ visibility: hidden;}
   `}</style>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  project: state.project,
  layer: state.layer,
  auth: state.auth,
  payment: state.payment,
  properties: state.properties,
});

export default connect(mapStateToProps, {
  setTabelStatus,
  clearGeolayerList,
  openModal,
  setModeList,
  getDetailLayerById,
  setChatStatus,
  getDetailLayerByLinkWOgeojson,
  setLanguage,
  removeDuplicateFeatures,
  setMapMode,
  setStatisticMode,
  set_value_layer,
  set_value_properties,
  get_detail_many_layer,
  getProjectDetailByLink,
  status_action,
})(MAP_EDITOR);
